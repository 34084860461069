import { setMessage } from '../message/message';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../services/userService';

const initialState: any = {
  TemplateData: [],
  challengesList: [],
  isLoading: false
}

export const sendNotification = createAsyncThunk(
  'notifications/sendNotification',
  async (params: any, thunkAPI) => {
      try {
          const response = await userService.sendNotification(params);      
          return response.data;
      } catch (error: any) {
          if (error.response && error.response.status === 401) {
              await thunkAPI.dispatch(setMessage(error.response));
              const response = await userService.sendNotification(params);      
              return response.data;
          } 
          else {
            thunkAPI.dispatch(setMessage(error.response));
          }
      }
  }
)

export const getAllTemplates = createAsyncThunk(
  'notifications/getAllTemplates',
  async (params, thunkAPI: any) => {
      try {
          const response = await userService.getAllTemplates();      
          return response.data.details;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          await thunkAPI.dispatch(setMessage(error.response));
          const response = await userService.getAllTemplates();
          return response.data.details;
        }
        else {
          thunkAPI.dispatch(setMessage(error.response));
        }
      }
  }
)

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendNotification.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(sendNotification.fulfilled, (state, action) => {
      state.activatedQRCount = action.payload;
      state.isLoading = false;
    })
    builder.addCase(sendNotification.rejected, (state, action) => {
      state.isLoading = false;
      console.log("Error Sending Notification: ", action.payload);
    })
    builder.addCase(getAllTemplates.fulfilled, (state, action) => {
      state.TemplateData = action.payload;
    })
    builder.addCase(getAllTemplates.rejected, (state, action) => {
      console.log("Error fetching templates: ", action.payload);
    })    
  },
})

export default notificationsSlice.reducer; 
