import { useState, useContext, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useLocation } from 'react-router-dom';
import ChallengeDetails from './challengeDetails/challengeDetails';
import MilestoneDetails from './milestoneDetails/milestoneDetails';
import ReleaseDetails from './releaseDetails/releaseDetails';
import MultiDetails from './multiDetails/multiDetails';
import './createChallenges.css';
import { Col, Row, Typography } from 'antd';
import { challengeContext } from '../../../App';
import ButtonComponent from '../../../components/button/button';
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner';

const { Title } = Typography;

function CreateChallenges(props: any) {   
    const [activeScreen, setActiveScreen] = useState("challengeDetails");
    const { challengeData, setChallengeData } = useContext(challengeContext);
    const { isLoading } = useAppSelector((state) => state.challenges);
    const location = useLocation();

    let handleNextScreen = (pageName: string) => {
        setActiveScreen(pageName);
    };

    useEffect(() => {
        if(location.state)
        {
            const {archivedData} = (location.state as any);
            setChallengeData({...archivedData, isArchiveEditMode: true});
        }
    }, []);

    return (
        <>
            {
                isLoading && <LoadingSpinner />
            }

            <div className="create-challenge-container">
                {
                    activeScreen === "challengeDetails" && <ChallengeDetails moveToNextScreen={handleNextScreen} />
                }
                {
                    activeScreen === "milestoneDetails" && <MilestoneDetails moveToNextScreen={handleNextScreen} />
                }
                {
                    activeScreen === "multiDetails" && <MultiDetails moveToNextScreen={handleNextScreen} />
                }
                {
                    activeScreen === "releaseDetails" && <ReleaseDetails moveToNextScreen={handleNextScreen} />
                }
            </div>
        </>
    )
};

export default CreateChallenges;