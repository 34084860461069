import './loginScreen.css';
import { Button, Card, Form, Typography, message } from 'antd';
import InputField from '../../components/inputField/inputField';
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { login, getCurrentUser } from '../../features/authentication/authentication';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import Notification from '../../components/notification/notification';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';

const { Title } = Typography;

function LoginScreen() {
    const [formData, setFormData] = useState({username: "", password:""});
    const [formErrors, setFormErrors] = useState({username: "", password: ""});
    const dispatch = useAppDispatch();
    const { status, responseMessage } = useAppSelector((state) => state.message);
    const { isLoading } = useAppSelector((state) => state.authentication);
    const [open, setOpen] = useState(false);

    useEffect(() => {      
      if(status === "error")
        setOpen(true);

      // eslint-disable-next-line
    }, [status, responseMessage]);

    let schema = yup.object().shape({
      username: yup.string().email().required(),
      password: yup.string().min(4).max(18).required(),  
    });

    let emailSchema = yup.object().shape({
      username: yup.string().email().required()  
    });

    let passwordSchema = yup.object().shape({
      password: yup.string().min(4).max(18).required(),  
    });

    let ValidateFormData = (FormData: any) => {
      schema.validate(formData, {abortEarly: false}).then(response => {
        setFormErrors({username: "", password: ""});
      }).catch((error: any) => {
        const errors = error.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          }
        }, {})
        setFormErrors(errors);
      })
    }

    let ValidateEmail = (event: any) => {
      setFormData({...formData, [event.target.name]: event.target.value});

      emailSchema.validate({username: formData.username}, {abortEarly: false}).then(response => {
        setFormErrors({...formErrors, username: ""});
      }).catch((error: any) => {
        const errors = error.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          }
        }, {})
        setFormErrors({...formErrors, ...errors});
      })
    }

    let ValidatePassword = (event: any) => {
      setFormData({...formData, [event.target.name]: event.target.value});

      passwordSchema.validate({password: formData.password}, {abortEarly: false}).then(response => {
        setFormErrors({...formErrors, password: ""});
      }).catch((error: any) => {
        const errors = error.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          }
        }, {})
        setFormErrors({...formErrors, ...errors});
      })
    }

    let handleFormData = () => {
      schema.isValid(formData, {abortEarly: false}).then(isFormDataValid => {
        if(isFormDataValid)
        {
          setFormErrors({username: "", password: ""});
          dispatch(login({email: formData.username, password: formData.password})).then(response => {
            
            if(response.payload)
            {
              dispatch(getCurrentUser());
            }
          })
        }
        else
        {
          ValidateFormData(formData);
        }
      });
    };

    return (
      <>
        {
          isLoading && <LoadingSpinner />
        }
        <div className="form-container">
          <h1 className="login-form-title">GetUpp</h1>
          <Card className='login-form'>
            <Form
              layout="vertical"
              name="basic"
              initialValues={{ remember: true }}
              autoComplete="off"
            >
                <Form.Item label="User Name">
                <InputField placeholder="Enter Username" name="username" type="username" onBlur={ValidateEmail} onKeyUp={ValidateEmail} autoComplete="new-email"/>
                  {formErrors.username ? <Title level={5} className="error-label" type="danger">{formErrors.username}</Title> : ''}
                </Form.Item>
                
                <Form.Item
                  label="Password"
                  name="password"
                >
                  <InputField placeholder="Enter Password" name="password" type="password" onBlur={ValidatePassword} onKeyUp={ValidatePassword} autoComplete="new-password"/>
                  {formErrors.password ? <Title level={5} className="error-label" type="danger">{formErrors.password}</Title> : ''}
                </Form.Item>
                <Form.Item style={{textAlign:'center'}}>
                  <Button className="submit-btn" htmlType="submit" onClick={handleFormData}>
                    Submit
                  </Button>
                </Form.Item>
                
            </Form>          
          </Card>
          <Notification open={open} setOpen={setOpen} title={status === "success" ? "Success" : "Error"} message={responseMessage} severity={status}/>
        </div>
      </>
    );
}

export default LoginScreen;