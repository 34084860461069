import { Button, Upload } from 'antd';
import { FC, useState, memo, useEffect } from 'react';
import { GoogleMapProps } from '../../models/componentTypes/types';
import ButtonComponent from '../button/button';
import { UploadOutlined } from '@ant-design/icons';
import './googleMaps.css';
import { GoogleMap, useLoadScript, Marker, Polyline, KmlLayer, DrawingManager, LoadScript, DistanceMatrixService } from '@react-google-maps/api';

const libs:any = ['drawing'];

const GoogleMapsComponent: FC<GoogleMapProps> = memo(({
  className,
  APIKey,
  KMLUrl,
  mapCenter,
  handlePolyData,
  handleMarkerData,
  handleMapUpload,
  handleRemoveMap,
  handleOverlayComplete,
  zoomLevel
}) => {

  const { isLoaded } = useLoadScript({googleMapsApiKey: APIKey ? APIKey : '', libraries: libs});
  const [zoom, setZoom] = useState(zoomLevel ? zoomLevel : 4);
  const [map, setMap] = useState<any>(null);
  let shape: any = [];

//   const handlePan = () => {
//     if(map)
//     {
//         map.panTo({lat: 23.0424576, lng: 72.5090304});
//     }
//   }

  return (
    <div className={className}>
        {/* <button onClick={handlePan}>Pan to Location</button> */}
        <Upload accept='.kml' beforeUpload={handleMapUpload} onRemove={handleRemoveMap} maxCount={1}>
            <ButtonComponent icon={<UploadOutlined />} className="upload-map-btn" title="Upload Map"/>
        </Upload>
        {
            isLoaded && 
            <GoogleMap 
                zoom={zoom} center={mapCenter} 
                mapContainerClassName="google-map-container"
                onLoad={(mapData) => setMap(mapData)}
            >
                {/* <DrawingManager
                    onOverlayComplete={handleOverlayComplete}
                    onPolylineComplete={handlePolyData}
                    onMarkerComplete={handleMarkerData}
                    drawingMode={google.maps.drawing.OverlayType.POLYLINE}
                    options={{
                        drawingControlOptions:{
                        drawingModes: [google.maps.drawing.OverlayType.POLYLINE, google.maps.drawing.OverlayType.MARKER]
                        },
                        polylineOptions: {
                        strokeColor: '#4287f5',
                        strokeWeight: 5
                        }
                    }}
                /> */}
                <KmlLayer
                    url={KMLUrl}
                    options={{ preserveViewport: true }}
                />
            </GoogleMap>
        }
    </div>
  )
});

export default GoogleMapsComponent;
