import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import React, { FC } from 'react';
import { TableProps } from '../../models/componentTypes/types';

const { Column } = Table;

const TableComponent: FC<TableProps>  = ({
    columns,
    Data,
    className,
    rowSelection
}) => {
    return (
        <Table scroll={{x: 800}} columns={columns} dataSource={Data} className={className} rowSelection={rowSelection} />
    )
};

export default TableComponent;