import {
  AxiosRequestHeaders,
} from 'axios';

export default function authHeader(): AxiosRequestHeaders {
  const auth = localStorage.getItem("auth");

  if (auth) {
    // For Spring Boot back-end
    // return { Authorization: "Bearer " + user.accessToken };
    // for Node.js Express back-end
    return { "Authorization": `Bearer ${auth}` };
  } else {
    return {};
  }
}
