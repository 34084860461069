import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loginStatus } from '../../models/message/message';
import authService from '../../services/authService';

const initialState: loginStatus = {
  status: "",
  responseMessage: "",
  token: ""
}

export const setMessage = createAsyncThunk(
    'message/setMessage',
    async (queryParams: any, thunkAPI) => {
      if(queryParams.status === 401 && queryParams.statusText === "Unauthorized")
      {
        try {
          const user = JSON.parse(localStorage.getItem("user") || '{}');
          const response = await authService.refreshToken(user.refresh_token);
          
          if (response.data) {
            localStorage.setItem("auth", response.data.access_token);
            localStorage.setItem("user", JSON.stringify(response.data));
            console.log("Refresh Response: ",response);
            return { token: response.data.refresh_token };
          }
          
        } catch (error: any) {
          return thunkAPI.rejectWithValue(error.response.data);
        }
      }
      else
      {
        return queryParams;
      }
    }
)

export const clearMessage = createAsyncThunk(
  'message/clearMessage',
  async (thunkAPI) => {
      return;
  }
)

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setMessage.fulfilled, (state, action) => {
      if(action.payload && action.payload.token)
        state.token = action.payload.token;
      
      if(action.payload && action.payload.status)
          state.status = action.payload.status;
        
      if(action.payload && action.payload.responseMessage)
        state.responseMessage = action.payload.responseMessage;
    })
    builder.addCase(setMessage.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
    })
    builder.addCase(clearMessage.fulfilled, (state, action) => {
      state.status = "";
      state.responseMessage = "";
  })
  builder.addCase(clearMessage.rejected, (state, action) => {
    console.log("Error Occurred: ",action.payload);
  })
  },
})

export default messageSlice.reducer; 