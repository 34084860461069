import { FC } from 'react';
import { Col, Row, Typography } from 'antd';
import { useAppSelector } from '../../../app/hooks';
import moment from 'moment';
import EditIcon from '../../../assets/icons/edit.svg';
import ButtonComponent from '../../../components/button/button';
const { Title } = Typography;

const NotificationOverview: FC<any> = ({
  hasMultipleNotifications,
  data,
  handleFlowEdit,
  handleSaveFlow
}) => {
    const { challengesList } = useAppSelector((state) => state.common);

    return (
        <>
            {
                !hasMultipleNotifications &&
                <Row>
                    {
                        data.message &&
                        <>
                            <Title level={3} className="overview-header">Notification Overview</Title>
                            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                                <Row className="overview-container">
                                    { data.message && <Title level={3} className="overview-text">Message: {data.message}</Title> }
                                    { !data.isAppScreen && data.url && <Title level={3} className="overview-text">URL: {data.url}</Title> }
                                    { data.isAppScreen && data.challengeId && <Title level={3} className="overview-text">Challenge Name: {challengesList?.find((challenge: any) => challenge.id===data.challengeId)?.name}</Title> }
                                    { data.deliveryType >= 0 && <Title level={3} className="overview-text">Scheduled Notification: {data.deliveryType === 1 ? 'Yes' : 'No'}</Title> }
                                    { data.scheduledDate && <Title level={3} className="overview-text">Schedule Date: {data.scheduledDate}</Title> }
                                    { data.scheduledTime && <Title level={3} className="overview-text">Schedule Time: {moment(data.scheduledDate+data.scheduledTime)?.format('HH:mm:ss')}</Title> }
                                </Row>
                            </Col>
                        </>
                    }
                </Row>
            }

            {
                hasMultipleNotifications &&
                <Row>
                    { data.Templates.length > 0 && <Title level={3} className="overview-header">Notifications Overview</Title> }
                    <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                    {
                            data.Templates.map((notification: any, index: number) => {
                                const language = notification.Language ?? notification.language;
                                let scheduledTime = {};
                                let deliveryType = "";
                                
                                if(notification.sendToSpecificUsers || notification.SendToSpecificUsers) {
                                    deliveryType = "Send to specific users";
                                }

                                if(notification.sendToAllSa || notification.SendToAllSa) {
                                    deliveryType = "Send to all SA";
                                }

                                if(notification.sendToAllUsers || notification.SendToAllUsers) {
                                    deliveryType = "Send to all users";
                                }

                                if (notification.sendToOfficefitUsers || notification.SendToOfficefitUsers) {
                                    deliveryType = "Send to Officefit users";
                                }

                                if (notification.sendToSpecificCompanyPlusDepartment || notification.SendToSpecificCompanyPlusDepartment) {
                                    deliveryType = "Send to specific company plus department";
                                }
                                
                                if (notification.sendToSpecificSa || notification.SendToSpecificSa) {
                                    deliveryType = "Send to specific SA";
                                }
                                
                                if(data.ConnectToQrCode && (notification.Id || notification.id)) {
                                    scheduledTime =notification.scheduleDateTime ?? notification.ScheduleDateTime;
                                }
                                else {
                                    scheduledTime = notification.scheduleTime ?? notification.ScheduleDateTime ?? notification.scheduleDateTime;
                                }

                                const message = notification.Message ?? notification.message;
                                const templateName = data.TemplateName ?? data.templateName;
                                const connectToQrCode = data.ConnectToQrCode ?? data.connectToQrCode;

                                const notificationDeliveryType = notification.DeliveryType ?? (notification.isScheduledNotification ? 1 : 0);
                                const membership = notification.Membership ?? notification.membership;

                                console.log("notification",notification);
                                return (
                                    <Row className="overview-container">
                                        <Title level={3} className="overview-text">Notification No: {notification.sequenceNumber}</Title>
                                        <Title level={3} className="overview-text">Template Name: {templateName}</Title>
                                        { message && <Title level={3} className="overview-text">Message: {message}</Title> }
                                        <Title level={3} className="overview-text">Is QR Connected: {connectToQrCode ? "Yes" : "No"}</Title>
                                        { connectToQrCode && <Title level={3} className="overview-text">Days: {notification.Days ?? notification.days}</Title> }
                                        { connectToQrCode && <Title level={3} className="overview-text">Schedule Time: {moment(scheduledTime)?.utc().format('HH:mm:ss') }</Title> }
                                        { !notification.isAppScreen && notification.url && <Title level={3} className="overview-text">URL: {notification.url}</Title> }
                                        { notification.isAppScreen && notification.screenName.Id && <Title level={3} className="overview-text">Challenge Name: {challengesList?.find((challenge: any) => challenge.id===notification.screenName.Id)?.name}</Title> }
                                        { notificationDeliveryType >= 0 && <Title level={3} className="overview-text">Scheduled Notification: {notificationDeliveryType === 1 ? 'Yes' : 'No'}</Title> }
                                        { (!connectToQrCode && scheduledTime && notificationDeliveryType === 1) && <Title level={3} className="overview-text">Schedule Date: {moment(scheduledTime)?.utc().format('YYYY-MM-DD')}</Title> }
                                        { (!connectToQrCode && scheduledTime && notificationDeliveryType === 1) && <Title level={3} className="overview-text">Schedule Time: {moment(scheduledTime)?.utc().format('HH:mm:ss')}</Title> }
                                        { language && <Title level={3} className="overview-text">Language: {language}</Title> }
                                        { deliveryType && <Title level={3} className="overview-text">Delivery Type: {deliveryType}</Title> }
                                        { membership && <Title level={3} className="overview-text">Membership: {membership}</Title> }
                                        { <Col><button type="button" onClick={() => handleFlowEdit(notification.sequenceNumber)}><img src={EditIcon} alt='Edit' /></button></Col> }
                                    </Row>
                                )
                            })
                        }
                        
                    </Col>
                </Row>
            }
            
            {
                data?.Templates?.length > 0 &&<ButtonComponent title="Save Flow" className="save-flow-btn" onClick={handleSaveFlow} />
            }
            
        </>
    )
};

export default NotificationOverview;
