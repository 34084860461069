import { Col, Row } from 'antd';
import VideoCard from '../../components/cards/videoCard';
import './popularVideos.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { getPopularVideos } from '../../features/common/common';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';

function PopularVideos() {
    const { videos, page, isLoading } = useAppSelector((state) => state.common);
    const dispatch = useAppDispatch();
    let [loading, setLoading] = useState(false);

    useEffect(() => {
      dispatch(getPopularVideos(page));
    }, []);
    
    useEffect(() => {
      if(loading)
      {
        dispatch(getPopularVideos(page));
        setLoading(false);
      }
    }, [loading]);

    window.addEventListener("scroll", function() {
      if((window.innerHeight + this.scrollY) >= document.body.scrollHeight)
        setLoading(true);
    });

    return (
      <div className="popular-videos">
        { isLoading && <LoadingSpinner /> }          
        <Row gutter={[10, 24]} className="videos-container">
          {
            videos && videos.map((item: any) => {
              return (
                <Col xs={24} sm={12} md={10} lg={8} xl={5}>
                  <VideoCard title={item.videoType} instructor={item.firstName + " " + item.lastName} videoURL={item.iframeUrl} imageURL={item.thumbnailUrl}/>
                </Col> 
              )
            })
          } 
                 
        </Row>
      </div>
    )
  };
  
  export default PopularVideos;