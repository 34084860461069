import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../services/userService';
import { setMessage } from '../message/message';

const initialState: any = {
  challenges: [],
  archivedChallenges: [],
  isLoading: false
};

export const getAllChallenges = createAsyncThunk(
  'challenges/getAllChallenges',
  async (params, thunkAPI) => {
    try {
      const response = await userService.getAllChallenges();    
      return response.data.details;
    } catch (error: any) {
      debugger;
      console.log('error.response.status', error);
      if (error.response && error.response.status === 401) {
        // Refresh the token
        await thunkAPI.dispatch(setMessage(error.response));
        // Retry the request
        const response = await userService.getAllChallenges();    
        return response.data.details;
      } else {
        // If the error is not 401, just dispatch the error message
        thunkAPI.dispatch(setMessage(error.response));
      }
    }  
  }
);

export const getAllArchivedChallenges = createAsyncThunk(
  'challenges/getAllArchivedChallenges',
  async (params, thunkAPI) => {
      try {
          const response = await userService.getAllArchivedChallenges();   
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }  
  }
);

export const createNewChallenge = createAsyncThunk(
  'challenges/createNewChallenge',
  async (params: any, thunkAPI) => {
    try {
      const response = await userService.createNewChallenge(params);      
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        // Refresh the token
        await thunkAPI.dispatch(setMessage(error.response));
        // Retry the request
        const response = await userService.createNewChallenge(params);      
        return response.data;
      } else {
        // If the error is not 401, just dispatch the error message
        thunkAPI.dispatch(setMessage(error.response));
      }
    }  
  }
);

export const updateChallenge = createAsyncThunk(
  'challenges/updateChallenge',
  async (params: any, thunkAPI) => {
    try {
      const response = await userService.updateChallenge(params);      
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        // Refresh the token
        await thunkAPI.dispatch(setMessage(error.response));
        // Retry the request
        const response = await userService.updateChallenge(params);      
        return response.data;
      } else {
        // If the error is not 401, just dispatch the error message
        thunkAPI.dispatch(setMessage(error.response));
      }
    }  
  }
);

export const createNewArchiveChallenge = createAsyncThunk(
  'challenges/createNewArchiveChallenge',
  async (params: any, thunkAPI) => {
    try {
      const response = await userService.createNewArchiveChallenge(params);      
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        // Refresh the token
        await thunkAPI.dispatch(setMessage(error.response));
        // Retry the request
        const response = await userService.createNewArchiveChallenge(params);      
        return response.data;
      } else {
        // If the error is not 401, just dispatch the error message
        thunkAPI.dispatch(setMessage(error.response));
      }
    }  
  }
);

export const updateArchiveChallenge = createAsyncThunk(
  'challenges/updateArchiveChallenge',
  async (params: any, thunkAPI) => {
      try {
          const response = await userService.updateArchiveChallenge(params.ArchiveChallengeId, params);      
          return response.data;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }  
  }
);

export const getArchivedChallengeData = createAsyncThunk(
  'challenges/getArchivedChallengeData',
  async (params: any, thunkAPI) => {
      try {
          const response = await userService.getArchivedChallengeData(params);      
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }  
  }
);

export const getChallengeDetailsById = createAsyncThunk(
  'challenges/getChallengeDetailsById',
  async (params: any, thunkAPI) => {
    try {
      const response = await userService.getChallengeDetailsById(params);      
      return response.data.details;
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        // Refresh the token
        await thunkAPI.dispatch(setMessage(error.response));
        // Retry the request
        const response = await userService.getChallengeDetailsById(params);      
        return response.data.details;
      } else {
        // If the error is not 401, just dispatch the error message
        thunkAPI.dispatch(setMessage(error.response));
      }
    }  
  }
);

export const unpublishChallenge = createAsyncThunk(
  'challenges/unpublishChallenge',
  async (params: any, thunkAPI) => {
      try {
          const response = await userService.unpublishChallenge(params);      
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }  
  }
);

const challengeSlice = createSlice({
  name: 'challenges',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllChallenges.fulfilled, (state, action) => {
      state.challenges = action.payload;
    })
    builder.addCase(getAllChallenges.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
    })
    builder.addCase(getAllArchivedChallenges.fulfilled, (state, action) => {
      state.archivedChallenges = action.payload;
    })
    builder.addCase(getAllArchivedChallenges.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
    })
    builder.addCase(createNewChallenge.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(createNewChallenge.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(createNewChallenge.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
    builder.addCase(updateChallenge.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(updateChallenge.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(updateChallenge.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
    builder.addCase(createNewArchiveChallenge.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(createNewArchiveChallenge.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(createNewArchiveChallenge.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
    builder.addCase(updateArchiveChallenge.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(updateArchiveChallenge.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(updateArchiveChallenge.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
    builder.addCase(getArchivedChallengeData.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getArchivedChallengeData.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(getArchivedChallengeData.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
    builder.addCase(getChallengeDetailsById.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getChallengeDetailsById.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(getChallengeDetailsById.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
    builder.addCase(unpublishChallenge.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(unpublishChallenge.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(unpublishChallenge.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
  },
})

export default challengeSlice.reducer; 