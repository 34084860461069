import {Routes, Route, Navigate} from 'react-router-dom';
import Dashboard from '../views/dashboard/dashboard';
import QRCodes from '../views/QRCodes/QRCodes';
import PopularVideos from '../views/popularVideos/popularVideos';
import NotificationConsole from '../views/notificationConsole/notificationConsole';
import CreateNotificationTemplate from '../views/createNotificationTemplate/createNotificationTemplate';
import Challenges from '../views/challenges/challenges';
import ArchivedChallenges from '../views/challenges/archivedChallenges/archivedChallenges';
import CreateChallenges from '../views/challenges/createChallenges/createChallenges';
import CompanyDetails from '../views/QRCodes/companyDetails/companyDetails';
import UserDetailsForm from '../views/QRCodes/companyDetails/userDetailsForm/userDetailsForm';
import NotificationTemplateList from '../views/notificationTemplate/notificationTemplateList';
import UserList from '../views/users/UserList';
import NotificationDictionary from '../views/notificationDictionary/NotificationDictionary';

function PrivateRoutes() {
    return (
        <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/qr-codes" element={<QRCodes />} />
            <Route path="/popular-videos" element={<PopularVideos />} />
            <Route path="/notification-console" element={<NotificationConsole/>} />
            <Route path="/notification-template/:id?" element={<CreateNotificationTemplate />} />
            <Route path="/notification-templates" element={<NotificationTemplateList />} />
            <Route path="/challenges" element={<Challenges />} />
            <Route path="/archived-challenges" element={<ArchivedChallenges />} />
            <Route path="/create-challenge" element={<CreateChallenges />} />
            <Route path="/company" element={<CompanyDetails />} />
            <Route path="/company/:id" element={<UserDetailsForm />} />
            <Route path="/users" element={<UserList />} />
            <Route path="/notification-dictionary" element={<NotificationDictionary />} />
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
    );
}

export default PrivateRoutes;