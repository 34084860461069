import { Button } from 'antd';
import { FC } from 'react';
import { ButtonProps } from '../../models/componentTypes/types';

const ButtonComponent: FC<ButtonProps> = ({
  title,
  type,
  icon,
  onClick,
  className,
  disabled
}) => {
  return (
      <Button type={type} icon={icon} onClick={onClick} className={className} disabled={disabled}>{title}</Button>
  )
};

export default ButtonComponent;
