import { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './companyDetails.css';
import { useAppDispatch } from '../../../app/hooks';
import { getCompanyUsers, getDepartmentsByCompanyId, deleteUser } from '../../../features/QRCodes/QRCodes';
import TableComponent from '../../../components/table/table';
import { useAppSelector } from '../../../app/hooks';
import { ColumnsType } from "antd/lib/table";
import EditIcon from '../../../assets/icons/edit.svg';
import ExtractIcon from '../../../assets/icons/extract.svg';
import { companyUsersType } from '../../../models/componentTypes/types';
import ButtonComponent from '../../../components/button/button';
import { CSVLink } from "react-csv";
import { DeleteOutlined } from "@ant-design/icons";
import Notification from '../../../components/notification/notification';
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner';

const { Title } = Typography;

const CompanyDetails: FC<any> = ({
  
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const companyUsers:companyUsersType[] = useAppSelector((state) => state.QRCodes.companyUsers);
    const { isLoading } = useAppSelector((state) => state.QRCodes);
    const {token} = useAppSelector((state) => state.message);
    const [companyId, setCompanyId] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [departmentId, setDepartmentId] = useState(null);
    const [departmentName, setDepartmentName] = useState(null);
    const [membership, setMembership] = useState(null);
    const [qrCode, setQRCode] = useState(null);
    const [exportedUsers, setExportedUsers] = useState([]);
    const [uploadResponse, setUploadResponse] = useState({status:'', successMessage:'', errors:[]});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(location.state)
        {    
            const {companyDetails} = (location.state as any);
            setCompanyId(companyDetails.companyId);
            setCompanyName(companyDetails.companyName);
            setDepartmentId(companyDetails.departmentId);
            setDepartmentName(companyDetails.department);
            setMembership(companyDetails.membership);
            setQRCode(companyDetails.qrCode);
            dispatch(getCompanyUsers({companyId: companyDetails.companyId, departmentId: companyDetails.departmentId}));
        }
    }, [token]);

    const columns: ColumnsType<companyUsersType> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: 'Nickname',
            dataIndex: 'nickName',
            key: 'nickName'
        },
        {
            title: 'E-Mail',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'OfficeFit/GetUpp',
            dataIndex: 'userType',
            key: 'userType',
            filters: [
                {
                  text: 'GetUpp',
                  value: 'GetUpp',
                },
                {
                  text: 'OfficeFit',
                  value: 'OfficeFit',
                }
              ],
              filterMode: 'tree',
              onFilter: (value: any, record: any) => record.userType.toLowerCase().startsWith(value.toLowerCase())
        },
        {
            title: 'Company Name',
            dataIndex: 'companyName',
            key: 'companyName'
        },
        {
            title: 'Department Name',
            dataIndex: 'departmentName',
            key: 'departmentName'
        },
        {
            title: 'Membership',
            dataIndex: 'membership',
            key: 'membership'
        },
        {
            title: 'Last Login',
            dataIndex: 'userLastLogin',
            key: 'userLastLogin'
        },
        {
            title: '',
            key: 'edit',
            dataIndex: 'edit',
            render: (text, record, index) => {
                return (
                <div className="icons-container">
                    <img src={EditIcon} alt="Edit Icon" className="edit-icon" onClick={() => handleEditUser(record)}/>
                    <DeleteOutlined className="delete-icon" onClick={() => handleDeleteUser(record)} />
                </div>
                )
            }
        },        
    ];

    const handleCreateUser = () => {
        dispatch(getDepartmentsByCompanyId(companyId)).then(res => {
            navigate('/company/new', { state: {userDetails: {companyId: companyId, companyName: companyName, departmentId: departmentId, departmentName: departmentName, membership: membership, qrCode: qrCode}}});
        }).catch(error => {
            dispatch(getDepartmentsByCompanyId(companyId)).then(res => {
                navigate('/company/new', { state: {userDetails: {companyId: companyId, companyName: companyName, departmentId: departmentId, departmentName: departmentName, membership: membership, qrCode: qrCode}}});
            })  
        });
    };

    const handleEditUser = (record: any) => {
        dispatch(getDepartmentsByCompanyId(companyId)).then(res => {
            navigate(`/company/${companyId}`, { state: {userDetails: {companyId: companyId, departmentId: departmentId, ...record}}});
        }).catch(error => {
            dispatch(getDepartmentsByCompanyId(companyId)).then(res => {
                navigate(`/company/${companyId}`, { state: {userDetails: {companyId: companyId, departmentId: departmentId, ...record}}});
            })  
        });
    };

    const handleDeleteUser = (record: any) => {
        dispatch(deleteUser(record.userId)).then(res => {
            setUploadResponse(res.payload);
            setOpen(true);
            dispatch(getCompanyUsers({companyId: companyId, departmentId: departmentId}));
        });
    };

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            let finalData = selectedRows.map(({userId, key, name, ...data}) => {
                return {
                    'First Name': data.firstName,
                    'Last Name': data.lastName,
                    'E-Mail': data.email,
                    'Nick Name': data.nickName,
                    'User Type': data.userType,
                    'Membership': data.membership,
                    'Company Name': data.companyName,
                    'Department Name': data.departmentName,
                    'Last Login': data.userLastLogin,
                }
            });
            setExportedUsers(finalData);
        }
    };

    return (
        <>
            { isLoading && <LoadingSpinner /> }
            
            <div className="company-details-container">
                <Row className="header">
                    <Title level={2} className="title">Company Details</Title>
                    <div className="buttons-container">
                        <CSVLink data={exportedUsers} filename="Company-Users.csv">
                            <img src={ExtractIcon} className="extract-icon" />
                        </CSVLink>
                        <ButtonComponent title="Add New User" className="create-user-btn" onClick={handleCreateUser} />
                    </div>
                </Row>

                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:'right'}}>
                        <TableComponent 
                            Data={companyUsers} 
                            columns={columns}
                            className="users-table"
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection
                            }}
                        />
                    </Col>
                </Row>

                <Notification 
                open={open} 
                setOpen={setOpen} 
                title={uploadResponse?.status === "SUCCESS" ? "Success" : "Error"} 
                message={uploadResponse?.successMessage ? uploadResponse.successMessage : uploadResponse?.errors ? uploadResponse.errors[0] : "Something went wrong, Please try again."} 
                severity={uploadResponse?.status === "SUCCESS" ? "success" : "error"} 
                />
            </div>
        </>
    );
};

export default CompanyDetails;
