import { Select, Checkbox, Input } from 'antd';
import { FC } from 'react';
import './select.css';
import { SelectProps } from '../../models/componentTypes/types';

const { Option, OptGroup } = Select;

const SelectComponent: FC<SelectProps> = ({
  className,
  list, 
  selectedList,
  placeholder,
  onChange,
  selectAll,
  onSelectAll,
  mode,
  allChecked,
  isOptionGroup,
  defaultValue,
  disabled,
  hasSingleSelection,
  value,
  allowClear
}) => {
  return (
      <Select 
        mode={mode ? mode : undefined} 
        placeholder={placeholder} 
        onChange={onChange} 
        value={selectedList ? selectedList : value || value===0 ? value : undefined}
        disabled={disabled}
        size="large" 
        defaultValue={allChecked ? selectedList: defaultValue || defaultValue === 0 ? defaultValue : undefined}
        className={className}
        maxTagCount="responsive"
        optionLabelProp="title"
        allowClear={allowClear}
        filterSort={(optionA, optionB) => {
          if(optionA.title === optionB.title)
            return 0;
          else if(optionA.title < optionB.title)
            return -1;
          else
            return 1;
        }}
        filterOption={(input, option) => (option!.title as unknown as string).toLowerCase().includes(input.toLowerCase())}
        getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
        dropdownRender={menu => (
          <>
            <div>
              { mode === "multiple" && selectAll ? 
                  <Checkbox 
                    style={{margin:'0em 0em 0.3em 0.9em'}} 
                    checked={allChecked}
                    onChange={onSelectAll}
                  > Select All </Checkbox>: null
              }
            </div>
            {menu}
          </>
        )}
      >
        {
          list && list.map((item:any, index: number) => {
            return (
              isOptionGroup === true ? 
              <OptGroup label={item.name}>
                {
                  item.departments.map((subitem: any) => {
                    return (
                      <Option value={subitem.id}>
                        <Checkbox 
                          style={{margin:'0em 0.3em 0em 0em'}} 
                          checked={selectedList ? selectedList.includes(subitem.id) : allChecked ? true : false}
                        />
                        {subitem.name}
                      </Option>
                    )
                  })
                }
              </OptGroup>
              :
              <Option value={mode === "multiple" || hasSingleSelection ? item.id : index} title={mode === "multiple" || hasSingleSelection ? item.name : item}>
                  { mode === "multiple" && !hasSingleSelection ? 
                    <Checkbox 
                      style={{margin:'0em 0.3em 0em 0em'}} 
                      checked={selectedList ? selectedList.includes(item.id) : allChecked ? true : false}
                    /> : null
                  }
                  { mode === "multiple" || hasSingleSelection ? item.name : item }
              </Option>
            )
          })
        }
      </Select>
  )
};

export default SelectComponent;