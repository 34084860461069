import { useState, FC } from 'react';
import { Col, Row, Typography, Upload } from 'antd';
import * as Icons from "@ant-design/icons";
import filestorage from '../../assets/icons/filestorage.png';
import closeIcon from '../../assets/icons/closeIcon.svg';
import { uploadCardProps } from '../../models/componentTypes/types';
import ImgCrop from 'antd-img-crop';

const { Title } = Typography;

const UploadCard: FC<uploadCardProps> = ({
  className,
  fileList,
  previewImage,
  onChange,
  onClose,
  name,
  isQRUploaded,
  errorMessage
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div>
        <Row className={className} justify="center">
            <Col xs={24} className={isQRUploaded ? "upload-card-filled" : "upload-card-empty"} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
              
              { fileList.length === 0 && !isQRUploaded && 
                <ImgCrop onModalOk={onChange} minZoom={1} aspect={1/1}>
                  <Upload
                    maxCount={1}
                    listType="picture"
                    fileList={fileList}                
                  >
                    {
                      isHovering ? <img className="file-icon" alt="file icon not found" src={filestorage} /> : <Icons.CloudUploadOutlined className="upload-icon"/>
                    }
                    {
                      isHovering ? <div><Title className="upload-pictures-label">Drop files here</Title></div> : 
                      <div>
                        <Title className="upload-pictures-label">Upload Pictures</Title>
                        <Title className="size-limit-label">(upto 1 MB)</Title>
                        <Title className="select-file-label" underline>Select File</Title>
                      </div>
                    }
                  </Upload>
                </ImgCrop>
              }
              {
                isQRUploaded &&
                <div className="selected-file-container">
                  <div className="seleted-file-details">
                    <img className="remove-file-icon" alt="remove file icon not found" src={closeIcon} onClick={onClose}/>
                    <img className="selected-file-preview" alt="preview not found" src={previewImage} />
                  </div>
                </div>
              }
              
            </Col>
            {errorMessage ? <Title level={5} className="error-label" style={{display:'block', width:'100%'}} type="danger">{errorMessage}</Title> : ''}
        </Row>
    </div>
  )
};

export default UploadCard;
