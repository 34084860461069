import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../services/userService';
import { setMessage } from '../message/message';

const initialState: any = {
  QRDetails: [],
  searchData: [],
  companyUsers: [],
  departmentsList: [],
  nickNamesList: [],
  isLoading: false
}

export const getQRDetails = createAsyncThunk(
    'QRCodes/getQRDetails',
    async (params, thunkAPI) => {
        try {
            const response = await userService.getQRDetails();      
            return response.data.details;
        } catch (error: any) {
            thunkAPI.dispatch(setMessage(error.response));
        }  
    }
)

export const saveQRDetails = createAsyncThunk(
  'QRCodes/saveQRDetails',
  async (params: any, thunkAPI) => {
      try {
          const response = await userService.saveQRDetails(params);   
          return response.data;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }  
  }
)

export const updateQRDetails = createAsyncThunk(
  'QRCodes/updateQRDetails',
  async (params: any, thunkAPI) => {
      try {
          const response = await userService.updateQRDetails(params);   
          return response.data;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }  
  }
)

export const searchCompanyName = createAsyncThunk(
  'QRCodes/searchCompanyName',
  async (params: any, thunkAPI) => {
      try {
          if(params)
          {
            const response = await userService.searchCompanyName(params); 
            return response.data.details;
          }
          else
            return;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const getCompanyUsers = createAsyncThunk(
  'QRCodes/getCompanyUsers',
  async (params: any, thunkAPI) => {
      try {
        const response = await userService.getCompanyUsers(params); 
        return response.data.details;
      } catch (error: any) {
        console.log("Error Occurred: ", error);
        thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const getDepartmentsByCompanyId = createAsyncThunk(
  'QRCodes/getDepartmentsByCompanyId',
  async (params: any, thunkAPI) => {
      try {
        const response = await userService.getDepartmentsByCompanyId(params); 
        return response.data.details;
      } catch (error: any) {
        console.log("Error Occurred: ", error);
        thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const getAllNicknames = createAsyncThunk(
  'QRCodes/getAllNicknames',
  async (params, thunkAPI) => {
      try {
        const response = await userService.getAllNicknames();
        return response.data.details;
      } catch (error: any) {
        console.log("Error Occurred: ", error);
        thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const createNewUser = createAsyncThunk(
  'QRCodes/createNewUser',
  async (params: any, thunkAPI) => {
      try {
        const response = await userService.createNewUser(params); 
        return response.data;
      } catch (error: any) {
        console.log("Error Occurred: ", error);
        thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const updateUserData = createAsyncThunk(
  'QRCodes/updateUserData',
  async (params: any, thunkAPI) => {
      try {
        const response = await userService.updateUserData(params); 
        return response.data;
      } catch (error: any) {
        console.log("Error Occurred: ", error);
        thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const deleteUser = createAsyncThunk(
  'QRCodes/deleteUser',
  async (params: any, thunkAPI) => {
      try {
        const response = await userService.deleteUser(params); 
        return response.data;
      } catch (error: any) {
        console.log("Error Occurred: ", error);
        thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const deleteQrCode = createAsyncThunk(
  'QRCodes/deleteQrCode',
  async (params: any, thunkAPI) => {
      try {
        const response = await userService.deleteQrCode(params.qrCodeId, params.cascadeDelete); 
        return response.data;
      } catch (error: any) {
        console.log("Error Occurred: ", error);
        thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

const QRCodesSlice = createSlice({
  name: 'QRCodes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQRDetails.fulfilled, (state, action) => {
      state.QRDetails = action.payload;
      state.isLoading = false;
    })
    builder.addCase(getQRDetails.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
    builder.addCase(getQRDetails.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(saveQRDetails.fulfilled, (state, action) => {
      
    })
    builder.addCase(saveQRDetails.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
    })
    builder.addCase(searchCompanyName.fulfilled, (state, action) => {
      state.searchData = [];
      
      if(action.payload)
      {
        let newData = action.payload.map((item: any) => {
          return {id: item.departments[0].id, name: item.departments[0].name}
        });
        state.searchData = newData;
      }
    })
    builder.addCase(searchCompanyName.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
    })
    builder.addCase(getCompanyUsers.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getCompanyUsers.fulfilled, (state, action) => {
      let newData = action.payload?.map((user: any) => {
        return {
          ...user,
          key: user.userId,
          userLastLogin: user.userLastLogin ? user.userLastLogin : '-',
          name: user.firstName + " " + user.lastName
        }
      })
      state.companyUsers = newData;
      state.isLoading = false;
    })
    builder.addCase(getCompanyUsers.rejected, (state, action) => {
      console.log("Error Occurred: ", action.payload);
      state.isLoading = false;
    })
    builder.addCase(getDepartmentsByCompanyId.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getDepartmentsByCompanyId.fulfilled, (state, action) => {
      state.departmentsList = action.payload.map((department:any) => {
        return {
          id: department.departmentId,
          name: department.departmentName
        }
      });
      state.isLoading = false;
    })
    builder.addCase(getDepartmentsByCompanyId.rejected, (state, action) => {
      console.log("Error Occurred: ", action.payload);
      state.isLoading = false;
    })
    builder.addCase(getAllNicknames.fulfilled, (state, action) => {
      state.nickNamesList = action.payload.map(nickname => nickname.nickName.trim());
    })
    builder.addCase(getAllNicknames.rejected, (state, action) => {
      console.log("Error Occurred: ", action.payload);
    })
    builder.addCase(createNewUser.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(createNewUser.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(createNewUser.rejected, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(updateUserData.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(updateUserData.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(updateUserData.rejected, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(deleteUser.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(deleteQrCode.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(deleteQrCode.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(deleteQrCode.rejected, (state, action) => {
      state.isLoading = false;
    })
  },
})

export default QRCodesSlice.reducer;