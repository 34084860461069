import { AutoComplete } from 'antd';
import { FC } from 'react';
import { AutoCompleteProps } from '../../models/componentTypes/types';

const AutoCompleteComponent: FC<AutoCompleteProps> = ({
  options,
  className,
  placeholder,
  onSelect,
  onChange,
  value,
  disabled
}) => {
  return (
    <AutoComplete 
        className={className} 
        options={options} 
        placeholder={placeholder}
        value={value}
        filterOption={(inputValue, option: any) =>
            option!.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
        }
        onSelect={onSelect}
        onChange={onChange}
        disabled={disabled}
    />
  )
};

export default AutoCompleteComponent;
