import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getNotificationDictionary,
  saveNotificationDictionary,
} from "../../features/notificationDictionary/notificationDictionary";
import {
  Button,
  Col,
  Input,
  Row,
  Tabs,
  TabsProps,
  Typography,
  notification,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import "./notificationDictionary.css";
import ButtonComponent from "../../components/button/button";
import { DictionaryColumnTypes } from "../../models/componentTypes/types";
import TableComponent from "../../components/table/table";
import ModalComponent from "../../components/modal/modal";
import InputField from "../../components/inputField/inputField";
import * as yup from "yup";
import TextArea from "antd/lib/input/TextArea";
import { abort } from "process";
import Notification from "../../components/notification/notification";

const { Title } = Typography;
const Search = Input.Search;

const NotificationDictionary = () => {
  const dispatch = useAppDispatch();
  const { notificationDictionary, isLoading } = useAppSelector(
    (state) => state.notificationDictionary
  );
  const { token } = useAppSelector((state) => state.message);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState<DictionaryFormDataType>({
    id: "",
    key: "",
    dictionaryDetails: [],
  });
  const [formError, setFormError] = useState({});
  const [response, setResponse] = useState({
    status: "",
    successMessage: "",
    errors: [],
  });
  const [open, setOpen] = useState(false);

  const language = ["da", "en"];

  const handleLanguageChange = (e: any) => {
    const { name, value } = e.target;
    var languages = [...formData.dictionaryDetails];
    var index = languages.findIndex((x) => x.language === name);
    if (index > -1) {
      let language = { ...languages[index] };
      language.value = value;
      languages[index] = language;
    } else {
      languages.push({ id: "", language: name, value: value });
    }
    setFormData({ ...formData, dictionaryDetails: languages });
  };

  const languageTabItems: TabsProps["items"] = language.map((item) => {
    return {
      key: item,
      label: item.toUpperCase(),
      children: (
        <Row>
          <Col span={24}>
            <Title level={5} className="input-label">
              {item.toUpperCase()}
            </Title>
            <TextArea
              style={{ width: `100%` }}
              rows={4}
              name={item}
              value={
                formData.dictionaryDetails.find((dd) => dd.language === item)
                  ?.value
              }
              onChange={handleLanguageChange}
            />
            {formError[item] && (
              <Title level={5} className="error-label" type="danger">
                {formError[item]}
              </Title>
            )}
          </Col>
        </Row>
      ),
    };
  });

  let schema = yup.object().shape({
    id: yup.string(),
    key: yup
      .string()
      .matches(
        /^[a-zA-Z0-9_]*$/,
        "Key must be alphanumeric, spaces, and underscores only"
      )
      .required("Key is required"),
    dictionaryDetails: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.string(),
          language: yup.string().required("Language is required"),
          value: yup.string().required("Value is required"),
        })
      )
      .min(1, "At least one language is required"),
  });

  useEffect(() => {
    dispatch(getNotificationDictionary());
  }, [token]);

  var columns: ColumnsType<DictionaryColumnTypes> = [
    {
      title: "Key",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <ButtonComponent
            title="Edit"
            onClick={() => {
              console.log(record);
              handleEditDictionary(record);
            }}
          />
        </div>
      ),
    },
  ];

  const handleEditDictionary = (record: any) => {
    setFormData({
      id: record.id,
      key: record.key,
      dictionaryDetails: record.dictionaryDetails,
    });
    setIsModalOpen(true);
  };

  const handleNewDictionary = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    //reset formData and formError
    setFormData({
      id: "",
      key: "",
      dictionaryDetails: [],
    });
    setFormError({});
  };

  const handleSaveDictionary = async () => {
    await schema
      .validate(formData, { abortEarly: false })
      .then((valid) => {
        console.log(valid);
        //add Guid to formData.Id and formData.DictionaryDetails.Id if empty
        if (formData.id === "") {
          formData.id = "00000000-0000-0000-0000-000000000000";
        }
        formData.dictionaryDetails.forEach((dd) => {
          if (dd.id === "") {
            dd.id = "00000000-0000-0000-0000-000000000000";
          }
        });

        //save dictionary using dictionaryService and dispatch
        dispatch(saveNotificationDictionary(formData)).then((res: any) => {
          setResponse(res.payload);
          getNotificationDictionary();
          setOpen(true);
        });
        //close modal
        setIsModalOpen(false);
        //reset formData and formError
        setFormData({
          id: "",
          key: "",
          dictionaryDetails: [],
        });
        setFormError({});
      })
      .catch((err) => {
        const errors = err.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          };
        }, {});
        console.log(errors);
        setFormError(errors);
      });

    console.log(formData);
  };

  return (
    <div className="notification-dictionary-container">
      <Row className="notification-dictionary-header">
        <div>
          <Title level={2}>Notification Dictionary</Title>
        </div>
        <div style={{ display: `flex` }}>
          <ButtonComponent
            title="Add new dictionary"
            onClick={handleNewDictionary}
          />
          <Search style={{ marginLeft: `0.5rem` }} placeholder="Search" />
        </div>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: "right" }}>
          <TableComponent
            columns={columns}
            Data={notificationDictionary}
            className="dictionary-table"
          />
        </Col>
      </Row>

      <ModalComponent
        title="Notification Dictionary"
        visible={isModalOpen}
        closable={false}
        footer={[
          <Button onClick={handleModalClose}>Cancel</Button>,
          <Button onClick={handleSaveDictionary}>Save</Button>,
        ]}
      >
        <Row>
          <Col span={24} style={{ position: "relative" }}>
            <Title level={5} className="input-label">
              Key (Identifier)
            </Title>
            <InputField value={formData["id"]} type="hidden" name="id" />
            <InputField
              placeholder="Enter key"
              onChange={(e) =>
                setFormData({ ...formData, key: e.target.value })
              }
              value={formData["key"]}
              type="text"
              name="key"
              className={
                formError["key"] ? "input-field nospace" : "input-field"
              }
            />
            {formError["key"] && (
              <Title level={5} className="error-label" type="danger">
                {formError["key"]}
              </Title>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Tabs items={languageTabItems} />
            {formError["dictionaryDetails"] && (
              <Title level={5} className="error-label" type="danger">
                {formError["dictionaryDetails"]}
              </Title>
            )}
          </Col>
        </Row>
      </ModalComponent>
      <Notification
        open={open}
        setOpen={setOpen}
        title={response?.status === "SUCCESS" ? "Success" : "Error"}
        message={
          response?.successMessage
            ? response.successMessage
            : response?.errors
            ? response.errors[0]
            : "Something went wrong, Please try again."
        }
        severity={response?.status === "SUCCESS" ? "success" : "error"}
      />
    </div>
  );
};

export default NotificationDictionary;

interface DictionaryFormDataType {
  id: string;
  key: string;
  dictionaryDetails: DictionaryDetailsType[];
}

interface DictionaryDetailsType {
  id: string;
  language: string;
  value: string;
}
