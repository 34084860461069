import { Radio, Space } from 'antd';
import { FC } from 'react';
import './radioGroup.css';
import { RadioGroupProps } from '../../models/componentTypes/types';

const RadioGroup : FC<RadioGroupProps> = ({
    values,
    selectedValue,
    onChange,
    direction,
    hasChild,
    childIndex, 
    childComponent,
    className,
    name
  }) => {
  return (
    <div>
      <Radio.Group value={selectedValue} onChange={onChange} className={className}>
        <Space direction={direction}>
          {
            values.map((item: string, index: number) => {
              return (
                <>
                  <Radio value={index} className="radio-option" name={name}>
                    {item} 
                    {hasChild && childIndex === selectedValue && index === selectedValue ? childComponent : ''}
                  </Radio>
                </>
              )
            })
          }
        </Space>
      </Radio.Group>
    </div>
  )
};

export default RadioGroup;
