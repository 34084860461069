import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { userType, userDetails } from '../../models/login/login';
import authService from '../../services/authService';
import userService from '../../services/userService';
import { setMessage } from '../message/message';
import { LOGIN_SUCCESS, INVALID_USER_ACCOUNT } from '../../constants';

const initialState: userDetails | any = {
  userData: {},
  isLoading: false
}

export const login = createAsyncThunk(
    'authentication/login',
    async (loginDetails: userType, thunkAPI) => {
      try {
        const response = await authService.login(loginDetails);

        if (response.data.access_token) {
          localStorage.setItem("auth", response.data.access_token);
        }
        if (response.data) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        
        return response.data;
      } catch (error: any) {
        thunkAPI.dispatch(setMessage({status:"error", responseMessage: error.response.data.error_description}));
      }
    }  
)

export const getCurrentUser = createAsyncThunk(
  'authentication/getCurrentUser',
  async (params, thunkAPI) => {
    try {
      const response = await userService.getCurrentUser();
      
      if (response.data.details) {
        if(response.data.details.userType === "Admin")
        {
          localStorage.setItem("role", response.data.details.userType);
          thunkAPI.dispatch(setMessage({status:"success", responseMessage: LOGIN_SUCCESS}));
        }
        else
        {
          thunkAPI.dispatch(setMessage({status:"error", responseMessage: INVALID_USER_ACCOUNT}));
        }
      }
      return response.data.details;
    } catch (error) {
    }
  }  
)

export const logout = createAsyncThunk("authentication/logout", async (params, thunkAPI) => {
  localStorage.removeItem("auth");
  localStorage.removeItem("role");
  return;
});

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      console.log("Error Occurred: ",action.payload);
    })
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.userData = action.payload;
    })
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
    })
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isLoggedIn = false;
      state.userData = null;
    })
  },
})

export default authenticationSlice.reducer; 