import { FC } from 'react';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import './loadingSpinner.css';

const LoadingSpinner: FC<any> = ({
  
}) => {
    const antIcon = <Loading3QuartersOutlined spin className="loading-icon" />;

    return (
        <div className="spinner-container">
            <Spin spinning={true} indicator={antIcon} />
        </div>
    )
};

export default LoadingSpinner;
