import {Routes, Route, Navigate} from 'react-router-dom';
import LoginScreen from '../views/loginScreen/loginScreen';

function PublicRoutes() {
    return (
        <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
    );
}

export default PublicRoutes;