import { Modal } from 'antd';
import { FC } from 'react';
import { ModalProps } from '../../models/componentTypes/types';
import './modal.css';

const ModalComponent: FC<ModalProps> = ({
    title,
    width,
    visible, 
    onOk,
    onCancel,
    children,
    footer,
    className,
    closable
}) => {
  
  return (
    <div>
      <Modal title={title} maskClosable={false} closable={closable} width={width} visible={visible} onOk={onOk} onCancel={onCancel} className={className} footer={footer}>
        {children}
      </Modal>
    </div>
  )
};

export default ModalComponent;
