import { useEffect, useRef, useState, useContext, useCallback, memo, FC } from 'react';
import ModalComponent from '../../../../../components/modal/modal';
import UploadCard from '../../../../../components/cards/uploadCard';
import { Col, Row, Typography, DatePicker, TimePicker, InputNumber, Select, Button, Steps, Radio, Upload, Tabs } from 'antd';
import InputField from '../../../../../components/inputField/inputField';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile } from 'antd/es/upload';
import SelectComponent from '../../../../../components/select/select';
import moment from 'moment';
import * as yup from 'yup';
import './milestoneModal.css';
import { TabsProps } from 'antd';

const { Step } = Steps;
const { Title } = Typography;
const { Option } = Select;

const MilestoneModal: FC<any> = ({
    isMilestoneFormVisible,
    setMilestoneFormVisible,
    milestoneData,
    setMilestoneData,
    isEditMode,
    editIndex,
    setEditMode,
    totalMilestone,
    handleSaveMilestones
  }) => {
    const [answerData, setAnswerData] = useState<any>({answerClassByLang: []});
    const [formErrors, setFormErrors] = useState({
        milestoneBase64Image: '',
        milestoneName: '',
        milestoneDescription: '',
        progressPercentage: '',
        hasQuestions: '',
        questionTitle: '',
        answerTime: '',
        answersList: '',
        answer1: '',
        answer2: '',
        answer3: '',
        answer4: '',
        correctAnswer: '',
        milestoneByLangList: [] as any[],
        answersListByLang: [] as any[]
    });
    
    // Upload Card Variables
    const [fileList, setFileList] = useState<any>([]);
    const [milestoneImage, setMilestoneImage] = useState('');
    const [isImageUploaded, setImageUploaded] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const languages = ["en","da"];

    const detailsTabs : TabsProps["items"] = languages
    .map((lang:string, index: number) => {
        
        var milestoneTranslation = milestoneData[currentIndex]?.milestoneByLangList?.find((item: any) => item.language.toLowerCase() === lang.toLowerCase());
        var hasValidationErrorByLang = formErrors[`milestoneByLangList[${index}].milestoneName`] 
            || formErrors[`milestoneByLangList[${index}].milestoneDescription`];

        
        return {
            label: <span className={(hasValidationErrorByLang ? `ant-typography ant-typography-danger error-label`: ``)}>{lang.toUpperCase()}</span>,
            key: lang,
            title: lang.toUpperCase(),
            children: (
                <>
                    <Row gutter={[20, 0]} >
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Title level={5} className="input-label">Milestone Name</Title>
                            <InputField placeholder="Milstone Name" value={milestoneTranslation?.milestoneName ? milestoneTranslation?.milestoneName : null} name="milestoneName" type="text" className={formErrors.milestoneName ? 'input-field nospace' : 'input-field'} onChange={(e) => handleMilestonesDataByLang("milestoneName", e.target.value, lang)} />
                            {formErrors[`milestoneByLangList[${index}].milestoneName`] ? <Title level={5} className="error-label" type="danger">{formErrors[`milestoneByLangList[${index}].milestoneName`]}</Title> : ''}
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Title level={5} className="input-label">Milestone Description</Title>
                            <InputField placeholder="Milestone Description" value={milestoneTranslation?.milestoneDescription ? milestoneTranslation?.milestoneDescription : null} name="milestoneDescription" type="text" className={formErrors.milestoneDescription ? 'input-field nospace' : 'input-field'} onChange={(e) => handleMilestonesDataByLang("milestoneDescription", e.target.value, lang)} />
                            {formErrors[`milestoneByLangList[${index}].milestoneDescription`] ? <Title level={5} className="error-label" type="danger">{formErrors[`milestoneByLangList[${index}].milestoneDescription`]}</Title> : ''}
                        </Col>
                    </Row>
                </>
            )
        }
    });

    const questionsAndAswersTab : TabsProps["items"] = languages
    .map((lang:string, index: number) => {

        var milestoneTranslation = milestoneData[currentIndex]?.milestoneByLangList.find((item: any) => item.language.toLowerCase() === lang.toLowerCase());

        var answerDataTranslation = answerData?.answerClassByLang?.find((item: any) => item.language.toLowerCase() === lang.toLowerCase());

        var hasValidationErrorByLang = formErrors[`milestoneByLangList[${index}].questionTitle`] 
            || formErrors[`milestoneByLangList[${index}].answerTime`] 
            || formErrors[`answersListByLang[${index}].answer1`] 
            || formErrors[`answersListByLang[${index}].answer2`] 
            || formErrors[`answersListByLang[${index}].answer3`] 
            || formErrors[`answersListByLang[${index}].answer4`] 
            || formErrors[`answersListByLang[${index}].correctAnswer`];

        return {
            label: <span className={(hasValidationErrorByLang ? `ant-typography ant-typography-danger error-label`: ``)}>{lang.toUpperCase()}</span>,
            key: lang,
            title: lang.toUpperCase(),
            children: (
                <>
                    <Row gutter={[20, 0]} >
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Title level={5} className="input-label">Question</Title>
                            <InputField placeholder="Question" value={milestoneTranslation?.questionTitle ? milestoneTranslation?.questionTitle : null} name="questionTitle" type="text" className={formErrors.questionTitle ? 'input-field nospace' : 'input-field'} onChange={(e) => handleMilestonesDataByLang("questionTitle", e.target.value, lang)} />
                            {formErrors[`milestoneByLangList[${index}].questionTitle`] ? <Title level={5} className="error-label" type="danger">{formErrors[`milestoneByLangList[${index}].questionTitle`]}</Title> : ''}
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Title level={5} className="input-label">Answer Time</Title>
                            <TimePicker className={formErrors[`milestoneByLangList[${index}].answerTime`] ? "input-field nospace" : "input-field"} format="ss" value={milestoneTranslation?.answerTime ? moment(milestoneTranslation?.answerTime) : null} onChange={(e) => handleMilestonesDataByLang("answerTime", e?.toISOString(), lang)} />
                            {formErrors[`milestoneByLangList[${index}].answerTime`] ? <Title level={5} className="error-label" type="danger">{formErrors[`milestoneByLangList[${index}].answerTime`]}</Title> : ''}
                        </Col>
                    </Row>
                    <Row gutter={[34, 0]} className="answers-container">  
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{position: 'relative'}}>
                            <Title level={5} className="input-label">Answer Options 1</Title>
                            <InputField placeholder="Enter" value={answerDataTranslation?.answer1 ? answerDataTranslation.answer1 : null} name="answer1" onChange={(e) => handleAnswerDataByLang("answer1", e.target.value, lang)} type="text" className={'input-field nospace'} />
                            {formErrors[`answersListByLang[${index}].answer1`] ? <Title level={5} className="error-label nospace" type="danger">{formErrors[`answersListByLang[${index}].answer1`]}</Title> : ''}
                            <Title level={5} className="input-label"><input type="radio" name={`correct-answer-radio-${lang}`} value="1" onChange={(e) => handleCorrectAnswerByLang(e.target.value, lang)} checked={answerDataTranslation?.correctAnswer == 1 ? true : false} /> Correct Answer</Title>

                            <Title level={5} className="input-label">Answer Options 3</Title>
                            <InputField placeholder="Enter" value={answerDataTranslation?.answer3 ? answerDataTranslation.answer3 : null} name="answer3" onChange={(e) => handleAnswerDataByLang("answer3", e.target.value, lang)} type="text" className={'input-field nospace'} />
                            {formErrors[`answersListByLang[${index}].answer3`] ? <Title level={5} className="error-label nospace" type="danger">{formErrors[`answersListByLang[${index}].answer3`]}</Title> : ''}
                            <Title level={5} className="input-label"><input type="radio" name={`correct-answer-radio-${lang}`} value="3" onChange={(e) => handleCorrectAnswerByLang(e.target.value, lang)} checked={answerDataTranslation?.correctAnswer == 3 ? true : false} /> Correct Answer</Title>
                            {formErrors[`answersListByLang[${index}].correctAnswer`] ? <Title level={5} className="error-label nospace" type="danger">{formErrors[`answersListByLang[${index}].correctAnswer`]}</Title> : ''}
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Title level={5} className="input-label">Answer Options 2</Title>
                            <InputField placeholder="Enter" value={answerDataTranslation?.answer2 ? answerDataTranslation.answer2 : null} name="answer2" onChange={(e) => handleAnswerDataByLang("answer2", e.target.value, lang)} type="text " className={'input-field nospace'} />
                            {formErrors[`answersListByLang[${index}].answer2`] ? <Title level={5} className="error-label nospace" type="danger">{formErrors[`answersListByLang[${index}].answer2`]}</Title> : ''}
                            <Title level={5} className="input-label"><input type="radio" name={`correct-answer-radio-${lang}`} value="2" onChange={(e) => handleCorrectAnswerByLang(e.target.value, lang)} checked={answerDataTranslation?.correctAnswer == 2 ? true : false} /> Correct Answer</Title>

                            <Title level={5} className="input-label">Answer Options 4</Title>
                            <InputField placeholder="Enter" value={answerDataTranslation?.answer4 ? answerDataTranslation.answer4 : null} name="answer4" onChange={(e) => handleAnswerDataByLang("answer4", e.target.value, lang)} type="text" className={'input-field nospace'} />
                            {formErrors[`answersListByLang[${index}].answer4`] ? <Title level={5} className="error-label nospace" type="danger">{formErrors[`answersListByLang[${index}].answer4`]}</Title> : ''}
                            <Title level={5} className="input-label"><input type="radio" name={`correct-answer-radio-${lang}`} value="4" onChange={(e) => handleCorrectAnswerByLang(e.target.value, lang)} checked={answerDataTranslation?.correctAnswer == 4 ? true : false} /> Correct Answer</Title>
                        </Col>


                    </Row>
                </>
            )
        }
    });


    useEffect(() => {
        if(isEditMode)
        {
            setCurrentIndex(editIndex);
        }
    });

    useEffect(() => {
        if(isEditMode)
        {
            setAnswerData(milestoneData[editIndex].answersList);
            
            if(milestoneData[editIndex].milestoneBase64Image) {
                fetch(milestoneData[editIndex].milestoneBase64Image, { mode: 'no-cors' })
                    .then(res => res.blob())
                    .then(blob => {
                        const type = milestoneData[editIndex].milestoneBase64Image.split(';')[0].split('/')[1];
                        const file = new File([blob], "File name", { type: type });
                        setFileList([file]);                
                        setMilestoneImage(milestoneData[editIndex].milestoneBase64Image);
                        setImageUploaded(true);
                    });
            }

            
        }
    }, [isEditMode]);

    const getSteps = (totalMilestone: number) => {
        const steps:any = [];
        for(let i=0; i<totalMilestone; i++)
            steps.push(<Step className='step' />);
        return steps;
    };

    const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
    
    const handleAnswerData = (name: string, value: any) => {
        setAnswerData({...answerData, [name]: value});
        validateField(answerSchema, name, value);
    };

    const handleAnswerDataByLang = (name: string, value: any, lang: string) => {
        let currentAnswerData = {...answerData};
        let answerByLangList = currentAnswerData.answerClassByLang || [];
        answerByLangList.sort((a,b) => {
            let enIndex = languages.indexOf(a.language.toLowerCase());
            let daIndex = languages.indexOf(b.language.toLowerCase());
            return enIndex - daIndex;
        });

        let index = answerByLangList.findIndex((item: any) => item.language.toLowerCase() === lang.toLowerCase());
        if (index === -1) {
            // If the index was not found, add a new element to the array
            answerByLangList.push({language: lang, [name]: value});
        } else {
            // If the index was found, update the existing element
            answerByLangList[index] = {...answerByLangList[index], [name]: value};
        }
        currentAnswerData.answerClassByLang = answerByLangList;
        setAnswerData(currentAnswerData);
    }

    const handleCorrectAnswer = (answerIndex: any) => {
        setAnswerData({...answerData, correctAnswer: answerIndex});
        validateField(answerSchema, 'correctAnswer', answerIndex);
    };

    const handleCorrectAnswerByLang = (answerIndex: any, lang: string) => {
        let currentAnswerData = {...answerData};
        let answerByLangList = currentAnswerData.answerClassByLang;
        let index = answerByLangList.findIndex((item: any) => item.language.toLowerCase() === lang.toLowerCase());

        if (index === -1) {
            // If the index was not found, add a new element to the array
            answerByLangList.push({language: lang, correctAnswer: answerIndex});
        }
        else {
            // If the index was found, update the existing element
            answerByLangList[index] = {...answerByLangList[index], correctAnswer: answerIndex};
        }
        
        currentAnswerData.answerClassByLang = answerByLangList;
        setAnswerData(currentAnswerData);
    }

    const handleMilestonesData = (name: string, value: any) => {
        let currentMilestoneData = [...milestoneData];
        let data = {...currentMilestoneData[currentIndex]};
        
        if(Object.keys(data).length === 0) {
            data = {milestoneName: '', milestoneDescription: '', progressPercentage: '', hasQuestions: '', milestoneByLangList: []};
        }

        data = {...data, [name]: value};

        currentMilestoneData[currentIndex] = data;
        setMilestoneData(currentMilestoneData);
        validateField(milestoneSchema, name, value);
    };

    const handleMilestonesDataByLang = (name: string, value: any, lang: string) => {
        let currentMilestoneData = [...milestoneData];
        let data = {...currentMilestoneData[currentIndex]};
        let milestoneByLangList = data.milestoneByLangList || [];
        let index = milestoneByLangList.findIndex((item: any) => item.language.toLowerCase() === lang.toLowerCase());

        if(index === -1) {
            milestoneByLangList.push({...milestoneByLangList[index], [name]: value, language: lang});
        }
        else {
            milestoneByLangList[index] = {...milestoneByLangList[index], [name]: value};
        }

        
        data = {...data, milestoneByLangList: milestoneByLangList};
        currentMilestoneData[currentIndex] = data;
        setMilestoneData(currentMilestoneData);
    }

    const handleEditMilestone = (currentEditIndex: number) => {
        setCurrentIndex(currentEditIndex);
        setAnswerData(milestoneData[currentEditIndex].answersList);
        setEditMode(true);
        setMilestoneFormVisible(true);
    };

    const saveAnswerData = () => {
        let newData = [...milestoneData];
        newData[currentIndex].answersList = answerData;
        setMilestoneData(newData);
    };

    const handleSaveMilestone = () => {        
        
        var data = {
            ...milestoneData[currentIndex],
            answersListByLang: answerData.answerClassByLang
        };
        milestoneSchema.validate(data, {abortEarly: false, context: data}).then(res => {
            saveAnswerData();
            setFileList([]);
            setImageUploaded(false);
            setMilestoneImage('');
            setAnswerData({});
            setFormErrors({
                milestoneBase64Image: '',
                milestoneName: '',
                milestoneDescription: '',
                progressPercentage: '',
                hasQuestions: '',
                questionTitle: '',
                answerTime: '',
                answersList: '',
                answer1: '',
                answer2: '',
                answer3: '',
                answer4: '',
                correctAnswer: '',
                milestoneByLangList: [],
                answersListByLang: []
            });
    
            if(currentIndex < totalMilestone-1 && !isEditMode)
            {    
                setCurrentIndex(currentIndex + 1);
            }
            else
            {
                handleSaveMilestones(milestoneData);
                setEditMode(false);
                setMilestoneFormVisible(false);      
            }
        }).catch(error => {
            
            const errors = error.inner.reduce((acc: any, error: any) => {
                return {
                    ...acc,
                    [error.path]: error.errors[0],
                };
            }, {});
            setFormErrors({...errors});
        });
    };

    const handleMilestoneFormCancel = () => {
        setMilestoneFormVisible(false);
        setMilestoneData([]);
        setAnswerData({});
        setCurrentIndex(0);
        setFormErrors({
            milestoneBase64Image: '',
            milestoneName: '',
            milestoneDescription: '',
            progressPercentage: '',
            hasQuestions: '',
            questionTitle: '',
            answerTime: '',
            answersList: '',
            answer1: '',
            answer2: '',
            answer3: '',
            answer4: '',
            correctAnswer: '',
            milestoneByLangList: [],
            answersListByLang: []
        });
    };

    const handleUploadChange = async (filesData: any) => {
        setFileList(filesData);
        
        let base64URL = await getBase64(filesData as RcFile);
        setMilestoneImage(base64URL);
        handleMilestonesData("milestoneBase64Image", base64URL);
        setImageUploaded(true);
    };

    const onFileRemove = () => {
        setFileList([]);
        setImageUploaded(false);
        setMilestoneImage('');
        let currentMilestonesData = [...milestoneData];
        currentMilestonesData[currentIndex].milestoneBase64Image = "";
        setMilestoneData(currentMilestonesData);
    };

    let milestoneSchema = yup.object().shape({
        milestoneBase64Image: yup.string().required("Please upload milestone image"),
        progressPercentage: yup.number().required("Progress percentage is required").max(100, "Percentage value cannot be greater than 100"),
        hasQuestions: yup.boolean().required("Please select the option"),
        // answersList: yup.object().when('hasQuestions', {
        //     is: true,
        //     then: (schema) => schema.shape({
        //         answer1: yup.string().required("Answer 1 is required field"),
        //         answer2: yup.string().required("Answer 2 is required field"),
        //         answer3: yup.string().required("Answer 3 is required field"),
        //         answer4: yup.string().required("Answer 4 is required field"),
        //         correctAnswer: yup.string().required("Please select correct answer")
        //     }),
        //     otherwise: (schema) => schema.notRequired()
        // }),
        milestoneByLangList: yup.array().of(yup.object().shape({
            language: yup.string().required("Language is required"),
            milestoneName: yup.string().required("Milestone name is required"),
            milestoneDescription: yup.string().required("Milestone description is required"),
            questionTitle: yup.string().when('$hasQuestions', {
                is: (hasQuestions: boolean) => hasQuestions === true,
                then: (schema) => schema.required("Question title is required"),
                otherwise: (schema) => schema.notRequired(),
            }),
            answerTime: yup.string().nullable().when('$hasQuestions', {
                is: (hasQuestions: boolean) => hasQuestions === true,
                then: (schema) => schema.required("Answer time is required"),
                otherwise: (schema) => schema.notRequired(),
            }),
        })).min(2, "Please add milestone details for all languages"),
        answersListByLang : yup.array().of(yup.object().shape({
            language: yup.string().required("Language is required"),
            answer1: yup.string().when('$hasQuestions', {
                is: ($hasQuestions: boolean) => { console.log("hasQuestions",$hasQuestions); return $hasQuestions === true},
                then: (schema) => schema.required("Answer 1 is required field"),
                otherwise: (schema) => schema.notRequired(),
            }),
            answer2: yup.string().when('$hasQuestions', {
                is: (hasQuestions: boolean) => hasQuestions === true,
                then: (schema) => schema.required("Answer 2 is required field"),
                otherwise: (schema) => schema.notRequired(),
            }),
            answer3: yup.string().when('$hasQuestions', {
                is: (hasQuestions: boolean) => hasQuestions === true,
                then: (schema) => schema.required("Answer 3 is required field"),
                otherwise: (schema) => schema.notRequired(),
            }),
            answer4: yup.string().when('$hasQuestions', {
                is: (hasQuestions: boolean) => hasQuestions === true,
                then: (schema) => schema.required("Answer 4 is required field"),
                otherwise: (schema) => schema.notRequired(),
            }),
            correctAnswer: yup.string().when('$hasQuestions', {
                is: (hasQuestions: boolean) => hasQuestions === true,
                then: (schema) => schema.required("Please select correct answer"),
                otherwise: (schema) => schema.notRequired(),
            })
        }))
    });

    let answerSchema = yup.object().shape({
        answer1: yup.string().required("Answer 1 is required field"),
        answer2: yup.string().required("Answer 2 is required field"),
        answer3: yup.string().required("Answer 3 is required field"),
        answer4: yup.string().required("Answer 4 is required field"),
        correctAnswer: yup.string().required("Please select correct answer")
    });

    let validateField = (schema: any, fieldName: string, value: any) => {
        yup.reach(schema, fieldName).validate(value)
        .then((res: any) => setFormErrors({...formErrors, [fieldName]: ''}))
        .catch((error: any) => setFormErrors({...formErrors, [fieldName]: error.message}));
    };

    return (
        <ModalComponent 
            title={"Design Milestones"} 
            width={700} 
            closable={false}
            visible={isMilestoneFormVisible} 
            onOk={(e) => console.log("Form Submitted: ",e)} 
            //onCancel={handleMilestoneFormCancel}
            className="milestone-modal-container"
            footer={[
            <Button key="submit" type="primary" style={{width:'18em'}} onClick={handleSaveMilestone}>
                {isEditMode ? "Save Milestone Details" : "Save & Add For Next Milestone"}
            </Button>,
            !isEditMode && 
            <Button key="back" onClick={handleMilestoneFormCancel}>
            Cancel
            </Button>
            ]}
        >
            <Row>
                <Col xl={24}>
                    <Steps current={currentIndex} className="steps-container">
                        {
                            getSteps(totalMilestone)
                        }
                    </Steps>
                </Col>
            </Row>

            <Row align='middle' style={{display:'flex', justifyContent:'center'}}>
                <UploadCard className="milestone-upload-card" errorMessage={formErrors.milestoneBase64Image} onChange={handleUploadChange} onClose={onFileRemove} fileList={fileList} isQRUploaded={isImageUploaded} previewImage={milestoneImage}/>
            </Row>

            <Title className='input-label' style={{marginTop:'1em'}}>Milestone {currentIndex + 1} Details</Title>

            <Row gutter={[34,0]} style={{marginTop:'1em'}}>
                {/* <Row gutter={[20, 0]} className="row-container">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Title level={5} className="input-label">Milestone Name</Title>
                        <InputField placeholder="Milstone Name" value={milestoneData[currentIndex]?.milestoneName ? milestoneData[currentIndex].milestoneName : null} name="milestoneName" type="text" className={formErrors.milestoneName ? 'input-field nospace' : 'input-field'} onChange={(e) => handleMilestonesData("milestoneName", e.target.value)} />
                        {formErrors.milestoneName ? <Title level={5} className="error-label" type="danger">{formErrors.milestoneName}</Title> : ''}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Title level={5} className="input-label">Milestone Description</Title>
                        <InputField placeholder="Milestone Description" value={milestoneData[currentIndex]?.milestoneDescription ? milestoneData[currentIndex].milestoneDescription : null} name="milestoneDescription" type="text" className={formErrors.milestoneDescription ? 'input-field nospace' : 'input-field'} onChange={(e) => handleMilestonesData("milestoneDescription", e.target.value)} />
                        {formErrors.milestoneDescription ? <Title level={5} className="error-label" type="danger">{formErrors.milestoneDescription}</Title> : ''}
                    </Col>
                </Row> */}
                <Row gutter={[20, 0]} className="row-container">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Tabs items={detailsTabs} defaultActiveKey="en" />
                    </Col>  
                </Row>
                

                <Row gutter={[20, 0]} className="row-container">
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Title level={5} className="input-label">Progress Percentage</Title>
                        <InputField placeholder="Enter Progress Percentage" name="progressPercentage" type="number" minValue={2} value={milestoneData[currentIndex]?.progressPercentage ? milestoneData[currentIndex].progressPercentage : null} onChange={(e) => handleMilestonesData("progressPercentage", e.target.value)} autoComplete="false" className={formErrors.progressPercentage ? "input-field nospace" : "input-field"}/>
                        {formErrors.progressPercentage ? <Title level={5} className="error-label" type="danger">{formErrors.progressPercentage}</Title> : ''}
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Title level={5} className="input-label">Add Milestone Question</Title>
                        <SelectComponent className={formErrors.hasQuestions ? 'input-select nospace' : 'input-select'} value={milestoneData[currentIndex] ? milestoneData[currentIndex].hasQuestions===true ? 'Yes' : milestoneData[currentIndex].hasQuestions===false ? 'No' : undefined : undefined} selectAll={false} list={[{id: true, name: 'Yes'}, {id: false, name: 'No'}]} onChange={(e) => handleMilestonesData("hasQuestions", e)} placeholder="Select option" hasSingleSelection={true}  />
                        {formErrors.hasQuestions ? <Title level={5} className="error-label" type="danger">{formErrors.hasQuestions}</Title> : ''}
                    </Col>
                </Row>

                {
                    milestoneData[currentIndex]?.hasQuestions &&
                    <Row gutter={[20, 0]} className="row-container">
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Tabs items={questionsAndAswersTab} defaultActiveKey="en" />
                        </Col>  
                    </Row>
                }
            </Row>

            {
            // milestoneData[currentIndex]?.hasQuestions &&
            // <>
            //     <Row gutter={[34, 0]} className="answers-container">  
            //         <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{position: 'relative'}}>
            //             <Title level={5} className="input-label">Answer Options 1</Title>
            //             <InputField placeholder="Enter" value={answerData?.answer1 ? answerData.answer1 : null} name="answer1" onChange={(e) => handleAnswerData("answer1", e.target.value)} type="text" className={'input-field nospace'} />
            //             {formErrors.answer1 ? <Title level={5} className="error-label nospace" type="danger">{formErrors.answer1}</Title> : ''}
            //             <Title level={5} className="input-label"><input type="radio" name="correct-answer-radio" value="1" onChange={(e) => handleCorrectAnswer(e.target.value)} checked={answerData?.correctAnswer == 1 ? true : false} /> Correct Answer</Title>

            //             <Title level={5} className="input-label">Answer Options 3</Title>
            //             <InputField placeholder="Enter" value={answerData?.answer3 ? answerData.answer3 : null} name="answer3" onChange={(e) => handleAnswerData("answer3", e.target.value)} type="text" className={'input-field nospace'} />
            //             {formErrors.answer3 ? <Title level={5} className="error-label nospace" type="danger">{formErrors.answer3}</Title> : ''}
            //             <Title level={5} className="input-label"><input type="radio" name="correct-answer-radio" value="3" onChange={(e) => handleCorrectAnswer(e.target.value)} checked={answerData?.correctAnswer == 3 ? true : false} /> Correct Answer</Title>
            //             {formErrors.correctAnswer ? <Title level={5} className="error-label nospace" type="danger">{formErrors.correctAnswer}</Title> : ''}
            //         </Col>
            //         <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            //             <Title level={5} className="input-label">Answer Options 2</Title>
            //             <InputField placeholder="Enter" value={answerData?.answer2 ? answerData.answer2 : null} name="answer2" onChange={(e) => handleAnswerData("answer2", e.target.value)} type="text" className={'input-field nospace'} />
            //             {formErrors.answer2 ? <Title level={5} className="error-label nospace" type="danger">{formErrors.answer2}</Title> : ''}                        
            //             <Title level={5} className="input-label"><input type="radio" name="correct-answer-radio" value="2" onChange={(e) => handleCorrectAnswer(e.target.value)} checked={answerData?.correctAnswer == 2 ? true : false} /> Correct Answer</Title>

            //             <Title level={5} className="input-label">Answer Options 4</Title>
            //             <InputField placeholder="Enter" value={answerData?.answer4 ? answerData.answer4 : null} name="answer4" onChange={(e) => handleAnswerData("answer4", e.target.value)} type="text" className={'input-field nospace'} />
            //             {formErrors.answer4 ? <Title level={5} className="error-label nospace" type="danger">{formErrors.answer4}</Title> : ''}
            //             <Title level={5} className="input-label"><input type="radio" name="correct-answer-radio" value="4" onChange={(e) => handleCorrectAnswer(e.target.value)} checked={answerData?.correctAnswer == 4 ? true : false} /> Correct Answer</Title>
            //         </Col>
                    
            //     </Row>
            // </>
            }

        </ModalComponent>
    )
};

export default MilestoneModal;