import { useEffect, useState, useContext } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Col, Row, Typography, DatePicker, TimePicker, Button, Steps, InputNumber, Select } from 'antd';
import InputField from '../../../../components/inputField/inputField';
import ButtonComponent from '../../../../components/button/button';
import SelectComponent from '../../../../components/select/select';
import UploadCard from '../../../../components/cards/uploadCard';
import TextAreaComponent from '../../../../components/textArea/textArea';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile } from 'antd/es/upload';
import './multiDetails.css';
import ModalComponent from '../../../../components/modal/modal';
import GoogleMapsComponent from '../../../../components/googleMaps/googleMaps';
import { challengeContext } from '../../../../App';
import * as yup from 'yup';
import RadioGroup from '../../../../components/radioGroup/radioGroup';
import StagesModal from './stagesModal/stagesModal';
import { useNavigate } from 'react-router-dom';
import { createNewArchiveChallenge, updateArchiveChallenge } from '../../../../features/challenges/challenges';
import Notification from '../../../../components/notification/notification';
import EditIcon from '../../../../assets/icons/edit.svg';
import AlbumIcon from '../../../../assets/icons/albumIcon.svg';
import ChallengeHeader from '../challengeHeader/challengeHeader';

const { Title } = Typography;
const { Step } = Steps;
const { Option } = Select;

function MultiDetails({moveToNextScreen}: any) {
    const periodList = [
        {
            id: 5,
            name: '1 Weeks'    
        },
        {
            id: 10,
            name: '2 Weeks'    
        },
        {
            id: 15,
            name: '3 Weeks'    
        },
        {
            id: 20,
            name: '4 Weeks'    
        },
    ];
    const [fileList, setFileList] = useState<any>([]);
    const [isImageUploaded, setImageUploaded] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isStageFormVisible, setStageFormVisible] = useState(false);
    const [formData, setFormData] = useState<any>({
        totalStages: 0,
        multiChallengeIntroduction: '',
        isTimeCycleForStages: false,
        timeCycleMode: "Days",
        DaysCycleForStages: 0,
        MultiChallengeBase64Image: '',
        stagesData: [],
        weeksCount: ""
    });
    const { challengeData, setChallengeData } = useContext(challengeContext);
    const [stageFileList, setStageFileList] = useState<any>([]);
    const [stageImage, setStageImage] = useState('');
    const [formErrors, setFormErrors] = useState<any>({});
    const [isStageImageUploaded, setStageImageUploaded] = useState(false);
    const [isEditMode, setEditMode] = useState(false);
    const [isMilestoneFormVisible, setMilestoneFormVisible] = useState(false);
    const [milestoneList, setMilstoneList] = useState<UploadFile[]>([]);
    const [milestoneImage, setMilestoneImage] = useState('');
    const [isMilestoneUploaded, setMilestoneUploaded] = useState(false);
    const [milestoneData, setMilestoneData] = useState<any>([]);
    const [answerData, setAnswerData] = useState({});
    const [hasMilestoneQuestions, setMilestoneQuestion] = useState(false);
    const [isSaveAndExit, setSaveAndExit] = useState(false);
    const [uploadResponse, setUploadResponse] = useState({status:'', successMessage:'', errors:[]});
    const [open, setOpen] = useState(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const selectBefore = (
        <Select defaultValue="No" onChange={(e) => handleModalData("milestonesEnabled", e === 'Yes' ? true : false)} style={{ width: 90 }}>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
        </Select>
    );

    // useEffect(() => {
    //     let currentMilestoneIndex = formData.stagesData[currentIndex]?.currentMilestoneIndex;
    //     let hasQuestion = milestoneData[currentMilestoneIndex]?.addMilestoneQuestion;
        
    //     if(hasQuestion === 0)
    //         setMilestoneQuestion(true);
    //     else
    //         setMilestoneQuestion(false);
    // }, [milestoneData]);

    useEffect(() => {
        if(challengeData.isSeriesChallenge)
        {
            let currentChallengeIndex = challengeData.currentChallengeCount-1;
            let multiData = (challengeData.challengeItemList[currentChallengeIndex] as any).multiChallenge;
            setFormData({...formData, ...multiData, stagesData: multiData?.multiChallengeStages});

            if(multiData && multiData.MultiChallengeBase64Image)
            {
                fetch(multiData.MultiChallengeBase64Image, { mode: 'no-cors' })
                .then(res => res.blob())
                .then(blob => {
                    const type = multiData.MultiChallengeBase64Image.split(';')[0].split('/')[1];
                    const file = new File([blob], "File name", { type: type });
                    setFileList([file]);                
                    setImageUploaded(true);
                });
            }
        }
        else
        {
            let multiData = (challengeData.challengeItemDto as any).multiChallenge;
            setFormData({...formData, ...multiData, stagesData: multiData?.multiChallengeStages});

            if(multiData && multiData.MultiChallengeBase64Image)
            {
                fetch(multiData.MultiChallengeBase64Image, { mode: 'no-cors' })
                .then(res => res.blob())
                .then(blob => {
                    const type = (challengeData.challengeItemDto as any).challengeBase64Image.split(';')[0].split('/')[1];
                    const file = new File([blob], "File name", { type: type });
                    setFileList([file]);                
                    setImageUploaded(true);
                });
            }
        }
    }, [challengeData]);

    const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });
    
    const handleUploadChange = async (filesData: any) => {
        setFileList(filesData);

        let base64URL = await getBase64(filesData as RcFile);
        handleInputChange("MultiChallengeBase64Image", base64URL);
        setImageUploaded(true);
    };

    const onFileRemove = () => {
        setFileList([]);
        handleInputChange("MultiChallengeBase64Image", '');
        setImageUploaded(false);
    };

    const handleNextClick = () => {
        schema.validate(formData, {abortEarly: false}).then(res => {
            // setFormData({...formData, stagesData: []});
            // setCurrentIndex(0);
            // setStageFormVisible(true);

            let stageData:any = [...formData.stagesData];
            delete formData.stagesData;
            let finalData: any = {};

            if(challengeData.isSeriesChallenge)
            {
                let currentChallengeIndex = challengeData.currentChallengeCount-1;
                let currentChallengeList: any = challengeData.challengeItemList;
                currentChallengeList[currentChallengeIndex] = {...currentChallengeList[currentChallengeIndex], multiChallenge: {...formData, multiChallengeStages: stageData}};

                finalData = {...challengeData, challengeItemList: currentChallengeList};
            }
            else
            {
                finalData = {
                    ...challengeData, 
                    challengeItemDto: {...challengeData.challengeItemDto, multiChallenge: {...formData, multiChallengeStages: stageData}}
                };
            }

            let {ArchiveChallengeId} = (challengeData as any);
            if(ArchiveChallengeId)
            {
                dispatch(updateArchiveChallenge(finalData)).then(response => {
                    setUploadResponse(response.payload);
                    setOpen(true);
                    setChallengeData(finalData);
                    moveToNextScreen("releaseDetails");
                });
            }
            else
            {
                dispatch(createNewArchiveChallenge(finalData)).then(response => {
                    setUploadResponse(response.payload);
                    setOpen(true);
                    setChallengeData(finalData);
                    moveToNextScreen("releaseDetails");
                });
            }
        }).catch(error => {
            const errors = error.inner.reduce((acc: any, error: any) => {
                return {
                ...acc,
                [error.path]: error.errors[0],
                }
            }, {});
            setFormErrors({...formErrors, ...errors});
        });
    };

    const handleSavePage = () => {
        schema.validate(formData, {abortEarly: false}).then(res => {
            let stageData:any = [...formData.stagesData];
            delete formData.stagesData;
            let finalData: any = {};

            if(challengeData.isSeriesChallenge)
            {
                let currentChallengeIndex = challengeData.currentChallengeCount-1;
                let currentChallengeList: any = challengeData.challengeItemList;
                currentChallengeList[currentChallengeIndex] = {...currentChallengeList[currentChallengeIndex], multiChallenge: {...formData, multiChallengeStages: stageData}};

                finalData = {...challengeData, challengeItemList: currentChallengeList};
            }
            else
            {
                finalData = {
                    ...challengeData, 
                    challengeItemDto: {...challengeData.challengeItemDto, multiChallenge: {...formData, multiChallengeStages: stageData}}
                };
            }
            setChallengeData(finalData);

            let {ArchiveChallengeId} = (challengeData as any);
            if(ArchiveChallengeId)
            {
                dispatch(updateArchiveChallenge(finalData)).then(response => {
                    setUploadResponse(response.payload);
                    setOpen(true);
                    setTimeout(() => {
                        navigate("/archived-challenges");
                    }, 500);
                });
            }
            else
            {
                dispatch(createNewArchiveChallenge(finalData)).then(response => {
                    setUploadResponse(response.payload);
                    setOpen(true);
                    setTimeout(() => {
                        navigate("/archived-challenges");
                    }, 500);
                });
            }
        }).catch(error => {
            const errors = error.inner.reduce((acc: any, error: any) => {
                return {
                ...acc,
                [error.path]: error.errors[0],
                }
            }, {});
            setFormErrors({...formErrors, ...errors});
        });
    };

    const handleEditStage = (index: number) => {
        if(formData.stagesData[index].MultiChallengeStageBase64Image)
        {
            fetch(formData.stagesData[index].MultiChallengeStageBase64Image, { mode: 'no-cors' })
            .then(res => res.blob())
            .then(blob => {
                const type = formData.stagesData[index].MultiChallengeStageBase64Image.split(';')[0].split('/')[1];
                const file = new File([blob], "File name", { type: type });
                
                setStageFileList([file]);
                setStageImageUploaded(true);
                setStageImage(formData.stagesData[index].MultiChallengeStageBase64Image);
                
            });
        }
        setStageFormVisible(true);
        setCurrentIndex(index);
        setEditMode(true);
    };

    const handleStageFormCancel = () => {
        setStageFormVisible(false);
        setSaveAndExit(false);
        setEditMode(false);
    };

    const handleAddStages = () => {
        if(formData.totalStages > 0)
        {
            setFormData({...formData, stagesData: []});
            setCurrentIndex(0);
            setStageFormVisible(true);
        }
    };

    const handleSaveStage = () => {
        stageSchema.validate(formData.stagesData[currentIndex], {abortEarly: false}).then(res => {
            setFormErrors({});
            if(currentIndex === formData.totalStages-1 || isEditMode)
            {
                // let stageData:any = [...formData.stagesData];
                // delete formData.stagesData;
                // let finalData: any = {};

                // if(challengeData.isSeriesChallenge)
                // {
                //     let currentChallengeIndex = challengeData.currentChallengeCount-1;
                //     let currentChallengeList: any = challengeData.challengeItemList;
                //     currentChallengeList[currentChallengeIndex] = {...currentChallengeList[currentChallengeIndex], multiChallenge: {...formData, multiChallengeStages: stageData}};

                //     finalData = {...challengeData, challengeItemList: currentChallengeList};
                // }
                // else
                // {
                //     finalData = {
                //         ...challengeData, 
                //         challengeItemDto: {...challengeData.challengeItemDto, multiChallenge: {...formData, multiChallengeStages: stageData}}
                //     };
                // }
                // setChallengeData(finalData);
                
                setStageFormVisible(false);

                // let {ArchiveChallengeId} = (challengeData as any);
                // if(ArchiveChallengeId && isSaveAndExit)
                // {
                //     dispatch(updateArchiveChallenge(finalData)).then(response => {
                //         setUploadResponse(response.payload);
                //         setOpen(true);
                //         setTimeout(() => {
                //             navigate("/archived-challenges");
                //         }, 500);
                //     });
                // }
                // else if(!ArchiveChallengeId && isSaveAndExit)
                // {
                //     dispatch(createNewArchiveChallenge(finalData)).then(response => {
                //         setUploadResponse(response.payload);
                //         setOpen(true);
                //         setTimeout(() => {
                //             navigate("/archived-challenges");
                //         }, 500);
                //     });
                // }
                // else
                // {
                //     setChallengeData(finalData);
                //     moveToNextScreen("releaseDetails");
                // }
            }
            else
            {
                setCurrentIndex(currentIndex + 1);
                setMilestoneData([]);
                setStageFileList([]);
                setStageImageUploaded(false);
                setStageImage('');
            }
        }).catch(error => {
            const errors = error.inner.reduce((acc: any, error: any) => {
                return {
                ...acc,
                [error.path]: error.errors[0],
                }
            }, {});
            setFormErrors(errors);
        });
    };

    const onStageFileRemove = () => {
        setStageFileList([]);
        setStageImageUploaded(false);
        setStageImage('');
        handleModalData("MultiChallengeStageBase64Image", '');
    };

    const handleInputChange = (name:string, value: any) => {
        switch(name)
        {
            case 'timeCycleMode': 
                setFormData({...formData, [name]: value, DaysCycleForStages: undefined, weeksCount: undefined});
                break;
            case 'DaysCycleForStages': 
                if(formData.timeCycleMode==="Weeks")
                    setFormData({...formData, [name]: value, weeksCount: Math.round(value/5) + " Weeks"});
                else
                    setFormData({...formData, [name]: value, weeksCount: undefined});               
                break;
            default:
                setFormData({...formData, [name]: value});
                break;
        }
        validateField(name, value);
    };

    const handleModalData = (name: string, value: any) => {
        let stagesData = [...formData.stagesData];
        let newData = {};

        switch(name)
        {
            case "category":
                newData = {[name]: value};
                onStageFileRemove();
                break;
            case "milestonesEnabled":
                newData = {...stagesData[currentIndex], [name]: value, milestonesCount: 0, milestonesData: [], currentMilestoneIndex: 0};
                setMilestoneData([]);
                break;
            case 'milestonesCount':
                newData = {...stagesData[currentIndex], [name]: value, milestonesData: [], currentMilestoneIndex: 0};
                setMilestoneData([]);
                break;
            default: 
                newData = {...stagesData[currentIndex], [name]: value};
                break;
        }
        newData = {...newData, stageSequenceNumber: currentIndex+1};
        stagesData[currentIndex] = newData;
        setFormData({...formData, stagesData: stagesData});

        yup.reach(stageSchema, name).validate(value)
        .then((res: any) => setFormErrors({...formErrors, [name]: ''}))
        .catch((error: any) => setFormErrors({...formErrors, [name]: error.message}));
    };

    const handleMilestonesData = (name: string, value: any) => {        
        let currentMilestoneIndex = formData.stagesData[currentIndex].currentMilestoneIndex;
        let newData = [...milestoneData];
        newData[currentMilestoneIndex] = {...newData[currentMilestoneIndex], [name]: value};
        setMilestoneData(newData);
    };

    const handleAnswerData = (name: string, value: any) => {
        setAnswerData({...answerData, [name]: value});
    };

    const handleCorrectAnswer = (answerIndex: any) => {
        setAnswerData({...answerData, correctAnswer: answerIndex});
    };

    const handleMilestoneUploadChange = () => {

    };

    const onMilestoneImageRemove = () => {

    };

    const handleAddMilestones = () => {
        setMilestoneFormVisible(true);
        let newFormData = {...formData};
        newFormData.stagesData[currentIndex].milestonesData = [];
        newFormData.stagesData[currentIndex].currentMilestoneIndex = 0;
        setFormData(newFormData);
    };
    
    const handleSaveMilestone = () => {
        let currentStageData = [...formData.stagesData];
        let currentMilestoneIndex = currentStageData[currentIndex].currentMilestoneIndex;

        if(currentStageData[currentIndex].currentMilestoneIndex < currentStageData[currentIndex].milestonesCount-1)
        {
            currentStageData[currentIndex].milestonesData[currentMilestoneIndex] = {...milestoneData[currentMilestoneIndex], answerData: answerData};
            currentStageData[currentIndex].currentMilestoneIndex = currentStageData[currentIndex].currentMilestoneIndex + 1;
            setFormData({...formData, stagesData: currentStageData});
        }
        else
        {
            currentStageData[currentIndex].milestonesData[currentMilestoneIndex] = {...milestoneData[currentMilestoneIndex], answerData: answerData};
            setFormData({...formData, stagesData: currentStageData});
            setMilestoneFormVisible(false);
        }
    };

    const handleMilestoneFormCancel = () => {
        setMilestoneFormVisible(false);
    };

    

    let schema = yup.object().shape({
        totalStages: yup.number().integer("Please enter a valid integer value").required("Please enter total number of stages").moreThan(1, "Stages count must be greater than 1").typeError("Please enter total number of stages"),
        multiChallengeIntroduction: yup.string().required("Multi challenge introduction is required"), 
        stagesData: yup.array().required("Stages data is required").min(2, "Please enter atleast two stages data"),
        isTimeCycleForStages: yup.boolean(),
        timeCycleMode: yup.string().when('isTimeCycleForStages', {
            is: true,
            then : (schema) => schema.required("Select time cycle type"),
            otherwise: (schema) => schema.notRequired(),
        }),
        DaysCycleForStages: yup.number().typeError("Days count is required").when('isTimeCycleForStages', {
            is: true,
            then : (schema) => schema.required("Days count is required").moreThan(0, "Days count must be greater than 0"),
            otherwise: (schema) => schema.notRequired(),
        }),
        MultiChallengeBase64Image: yup.string().required("Please upload multi challenge image"),
    });

    let stageSchema = yup.object().shape({
        category: yup.string().required("Please select category"),
        distanceUnit: yup.string().when('category', {
            is: "Distance",
            then : (schema) => schema.required("Distance unit is required"),
            otherwise: (schema) => schema.notRequired(),
        }),
        distance: yup.number().typeError("Distance value is required").when('category', {
            is: "Distance",
            then : (schema) => schema.required("Distance value is required"),
            otherwise: (schema) => schema.notRequired(),
        }),
        challengeForm: yup.string().when('category', {
            is: (category: string) => {
                return (category==="Distance" || category==="Calorie" || category==="Time");
            },
            then : (schema) => schema.required("Please select challenge form"),
            otherwise: (schema) => schema.notRequired(),
        }),
        CaloriesToBurn: yup.number().typeError("Calories to burn value is required").when('category', {
            is: "Calorie",
            then : (schema) => schema.required("Calories to burn value is required"),
            otherwise: (schema) => schema.notRequired(),
        }),
        MultiChallengeStageBase64Image: yup.string().when('category', {
            is: (category: string) => {
                return (category==="Calorie" || category==="Time" || category==="Repetition")
            },
            then : (schema) => schema.required("Please upload image"),
            otherwise: (schema) => schema.notRequired(),
        }),
        TimeToCount: yup.string().when('category', {
            is: "Time",
            then : (schema) => schema.required("Please enter time value"),
            otherwise: (schema) => schema.notRequired(),
        }),
        RepetitionsToCount: yup.number().typeError("Repetitions count is required").when('category', {
            is: "Repetition",
            then : (schema) => schema.required("Repetitions count is required"),
            otherwise: (schema) => schema.notRequired(),
        }),
    });

    let validateField = (fieldName: string, value: any) => {
        schema.validateAt(fieldName, {[fieldName]: value}, {abortEarly: false})
        .then(res => setFormErrors({...formErrors, [fieldName]: ''}))
        .catch((error: any) => {
            const errors = error.inner.reduce((acc: any, error: any) => {
                return {
                ...acc,
                [error.path]: error.errors[0],
                }
            }, {});
            setFormErrors({...formErrors, ...errors});
        });
    };

    const handleSkipChallenge = () => {
        schema.validate(formData, {abortEarly: false}).then(res => {
            let stageData:any = [...formData.stagesData];
            delete formData.stagesData;
            let finalData: any = {};

            if(challengeData.isSeriesChallenge)
            {
                let currentChallengeIndex = challengeData.currentChallengeCount-1;
                let currentChallengeList: any = challengeData.challengeItemList;
                currentChallengeList[currentChallengeIndex] = {...currentChallengeList[currentChallengeIndex], multiChallenge: {...formData, multiChallengeStages: stageData}};

                finalData = {...challengeData, challengeItemList: currentChallengeList};
            }

            let {ArchiveChallengeId} = (challengeData as any);
            if(ArchiveChallengeId && challengeData.isArchiveEditMode)
            {
                dispatch(updateArchiveChallenge(finalData)).then(response => {
                    setUploadResponse(response.payload);
                    setOpen(true);
                    if(challengeData.currentChallengeCount < challengeData.totalChallengeCount)
                    {
                        moveToNextScreen("challengeDetails");
                        setChallengeData({...finalData, currentChallengeCount: challengeData.currentChallengeCount + 1});
                    }
                    else
                    {
                        setTimeout(() => {
                            navigate('/archived-challenges');
                        }, 500);
                    }
                });
            }
        }).catch(error => {
            const errors = error.inner.reduce((acc: any, error: any) => {
                return {
                ...acc,
                [error.path]: error.errors[0],
                }
            }, {});
            setFormErrors({...formErrors, ...errors});
        });
    };

    return (
        <div className="multi-details-container">
            <ChallengeHeader handleSkipChallenge={handleSkipChallenge} />
            <Row>
                <Col xs={24} sm={24} md={12} lg={10} xl={8} style={{marginTop:'1em'}}>
                    <Row gutter={[10, 0]}>
                        <Col xs={24} sm={24} md={6} lg={8} xl={18}>
                            <Title level={5} className="input-label">Stages</Title>
                            <InputField placeholder="Enter Number of Stages" name="totalStages" type="number" value={formData.totalStages ? formData.totalStages : null} minValue={2} onChange={(e) => handleInputChange("totalStages", e.target.value)} autoComplete="false" className={formErrors.totalStages || formErrors.stagesData ? "input-field full-width nospace" : "input-field full-width"}/>
                            {formErrors.totalStages || formErrors.stagesData ? <Title level={5} className="error-label" type="danger">{formErrors.totalStages ? formErrors.totalStages : formErrors.stagesData ? formErrors.stagesData : ''}</Title> : ''}
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={8} xl={6} style={{alignSelf:'center'}}>
                            <ButtonComponent title="Add Stages" className={formErrors.totalStages || formErrors.stagesData ? "stages-btn" : "stages-btn mt"} onClick={handleAddStages} />
                        </Col>  
                    </Row>

                    <Title level={5} className="input-label">Stage Introduction</Title>
                    <InputField placeholder="Enter Challenge Description" name="multiChallengeIntroduction" type="text" value={formData.multiChallengeIntroduction ? formData.multiChallengeIntroduction : null} onChange={(e) => handleInputChange("multiChallengeIntroduction", e.target.value)} autoComplete="false" className={formErrors.multiChallengeIntroduction ? "input-field full-width nospace" : "input-field full-width"}/>
                    {formErrors.multiChallengeIntroduction ? <Title level={5} className="error-label" type="danger">{formErrors.multiChallengeIntroduction}</Title> : ''}

                    <Title level={5} className="input-label">Has Time Cycles for Stages?</Title>
                    <SelectComponent className="time-cycle-select" value={formData.isTimeCycleForStages ? 0 : 1} onChange={(e) => handleInputChange("isTimeCycleForStages", e === 0 ? true : false)} list={['Yes', 'No']} placeholder="Select" selectAll={false} isOptionGroup={false}/>
                    {formErrors.isTimeCycleForStages ? <Title level={5} className="error-label" type="danger">{formErrors.isTimeCycleForStages}</Title> : ''}

                    {
                        formData.isTimeCycleForStages &&
                        <>
                            <Title level={5} className="input-label">Time Cycles for Stages</Title>
                            
                            <Row className="time-cycles-container" gutter={[10, 0]}>
                                <Col xs={24} sm={24} md={6} lg={8} xl={24}>
                                    <Row style={{display:'flex', alignItems:'baseline', columnGap:'1em'}}>
                                        <Title level={5} className="input-label">Time cycles mode: </Title>
                                        <RadioGroup values={["Days", "Weeks"]} selectedValue={formData.timeCycleMode==="Days" ? 0 : 1} onChange={(e: any) => handleInputChange("timeCycleMode", e.target.value===0 ? "Days" : "Weeks")} direction="horizontal" hasChild={false} />
                                    </Row>
                                    <Row>
                                        {
                                            formData.timeCycleMode === "Days" &&
                                            <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                                                <Title level={5} className="input-label">Number of days</Title>
                                                <InputField placeholder="Enter No. of Days" name="DaysCycleForStages" value={formData?.DaysCycleForStages ? formData.DaysCycleForStages : null} onChange={(e) => handleInputChange("DaysCycleForStages", e.target.value)} type="number" autoComplete="false" className={formErrors.DaysCycleForStages ? "input-field full-width nospace" : "input-field full-width"}/>
                                                {/* <TimePicker onChange={(e) => handleInputChange("timeCycleForStages", e?.toISOString())} format="HH:mm" className={formErrors.timeCycleForStages ? "input-field full-width nospace" : "input-field full-width"} /> */}
                                                {formErrors.DaysCycleForStages ? <Title level={5} className="error-label" type="danger">{formErrors.DaysCycleForStages}</Title> : ''}
                                            </Col>
                                        }
                                    
                                        {
                                            formData.timeCycleMode === "Weeks" &&
                                            <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                                                <Title level={5} className="input-label">Period</Title>
                                                <SelectComponent className="weeks-period" value={formData?.weeksCount ? formData.weeksCount : null} onChange={(e) => handleInputChange("DaysCycleForStages", e)} list={periodList} placeholder="Select" hasSingleSelection={true} selectAll={false} isOptionGroup={false}/>
                                                {formErrors.DaysCycleForStages ? <Title level={5} className="error-label" type="danger">{formErrors.DaysCycleForStages}</Title> : ''}
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    }

                    {
                        formData.stagesData?.map((stage:any, index: number) => {
                            return (
                                <Row className='stage-card'>
                                    <div className="image-title">
                                        <img src={stage.MultiChallengeStageBase64Image || AlbumIcon} className={stage.MultiChallengeStageBase64Image ? "image" : "image-empty"} />
                                        <Title level={4}>{stage.category}</Title>
                                    </div>
                                    <div>
                                        <img src={EditIcon} alt="Edit Icon" onClick={() => handleEditStage(index)} className="edit-icon" />
                                    </div>
                                </Row>
                            )
                        })
                    }            
                </Col>
                <Col xs={24} sm={24} md={12} lg={14} xl={10} className="upload-card-container">
                    <UploadCard 
                        className="challenge-upload-card" 
                        errorMessage={formErrors.MultiChallengeBase64Image} 
                        onChange={handleUploadChange} 
                        onClose={onFileRemove} 
                        fileList={fileList} 
                        isQRUploaded={isImageUploaded} 
                        previewImage={formData?.MultiChallengeBase64Image}
                    />
                </Col>
            </Row>
            <Row className="buttons-container">
                <ButtonComponent title="Save & Next" className="next-btn" onClick={handleNextClick} />  
                {/* <ButtonComponent title="Save" className="save-btn" onClick={handleSavePage} />     */}
            </Row> 

            <StagesModal 
                isStageFormVisible={isStageFormVisible} 
                currentIndex={currentIndex}
                isEditMode={isEditMode}
                totalStages={formData.totalStages} 
                handleStageFormCancel={handleStageFormCancel} 
                handleSaveStage={handleSaveStage} 
                handleModalData={handleModalData} 
                stagesData={formData.stagesData} 
                formErrors={formErrors}
                stageFileList={stageFileList}
                setStageFileList={setStageFileList}
                isStageImageUploaded={isStageImageUploaded}
                setStageImageUploaded={setStageImageUploaded}
                stageImage={stageImage}
                setStageImage={setStageImage}
            />

            <Notification 
                open={open} 
                setOpen={setOpen} 
                title={uploadResponse?.status === "SUCCESS" ? "Success" : "Error"} 
                message={uploadResponse?.successMessage ? uploadResponse.successMessage : uploadResponse?.errors ? uploadResponse.errors[0] : "Something went wrong, Please try again."} 
                severity={uploadResponse?.status === "SUCCESS" ? "success" : "error"} 
            />

            {/* <ModalComponent 
              title={"Design Milestones"} 
              width={700} 
              closable={false}
              visible={isMilestoneFormVisible} 
              onOk={(e) => console.log("Form Submitted: ",e)} 
              onCancel={handleMilestoneFormCancel}
              className="milestone-modal-container"
              footer={[
                <Button key="submit" type="primary" style={{width:'18em'}} onClick={handleSaveMilestone}>
                  Save & Add For Next Milestone
                </Button>,
                <Button key="back" onClick={handleMilestoneFormCancel}>
                  Cancel
                </Button>
              ]}
            >
               <Row>
                <Col xl={24}>
                    <Steps current={formData.stagesData[currentIndex]?.currentMilestoneIndex}>
                        {
                            getSteps(formData.stagesData[currentIndex]?.milestonesCount)
                        }
                    </Steps>
                </Col>
              </Row>

              <Row>
                  <UploadCard className="milestone-upload-card" onChange={handleMilestoneUploadChange} onClose={onMilestoneImageRemove} fileList={milestoneList} isQRUploaded={isMilestoneUploaded} previewImage={milestoneImage} />
              </Row>

              <Title className='input-label' style={{marginTop:'2em'}}>Milestone {formData.stagesData[currentIndex]?.currentMilestoneIndex + 1} Details</Title>

              <Row gutter={[34,0]} style={{marginTop:'1em'}}>  
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{position: 'relative'}}>
                    <Title level={5} className="input-label">Intro Text</Title>
                    <InputField placeholder="Intro Text" value={formData.companyName} name="introText" type="text" className={'input-field'} onChange={(e) => handleMilestonesData("introText", e.target.value)} />

                    {
                        hasMilestoneQuestions &&
                        <>
                            <Title level={5} className="input-label">Question</Title>
                            <InputField placeholder="Question" value={formData.companyName} name="question" type="text" className={'input-field'} onChange={(e) => handleMilestonesData("question", e.target.value)} />
                        </>
                    }

                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Title level={5} className="input-label">Add Milestone Question</Title>
                    <SelectComponent className="input-select" selectAll={false} list={['Yes', 'No']} onChange={(e) => handleMilestonesData("addMilestoneQuestion", e)} placeholder="Select option"/>
                    
                    {
                        hasMilestoneQuestions &&
                        <>
                            <Title level={5} className="input-label">Answer Time</Title>
                            <TimePicker className="input-field" format="ss" onChange={(e) => handleMilestonesData("answerTime", e?.toISOString())} />
                        </>
                    }

                </Col>
              </Row>

              {
                hasMilestoneQuestions &&
                <Row gutter={[34, 0]} className="answers-container">  
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{position: 'relative'}}>
                        <Title level={5} className="input-label">Answer Options 1</Title>
                        <InputField placeholder="Enter" value={formData.companyName} name="Answer1" onChange={(e) => handleAnswerData("Answer1", e.target.value)} type="text" className={'input-field nospace'} />
                        <Title level={5} className="input-label"><input type="radio" name="correct-answer-radio" defaultChecked={true} value="1" onChange={(e) => handleCorrectAnswer(e.target.value)}/> Correct Answer</Title>

                        <Title level={5} className="input-label">Answer Options 3</Title>
                        <InputField placeholder="Enter" value={formData.companyName} name="Answer3" onChange={(e) => handleAnswerData("Answer3", e.target.value)} type="text" className={'input-field nospace'} />
                        <Title level={5} className="input-label"><input type="radio" name="correct-answer-radio" value="3" onChange={(e) => handleCorrectAnswer(e.target.value)}/> Correct Answer</Title>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Title level={5} className="input-label">Answer Options 2</Title>
                        <InputField placeholder="Enter" value={formData.companyName} name="Answer2" onChange={(e) => handleAnswerData("Answer2", e.target.value)} type="text" className={'input-field nospace'} />
                        <Title level={5} className="input-label"><input type="radio" name="correct-answer-radio" value="2" onChange={(e) => handleCorrectAnswer(e.target.value)}/> Correct Answer</Title>

                        <Title level={5} className="input-label">Answer Options 4</Title>
                        <InputField placeholder="Enter" value={formData.companyName} name="Answer4" onChange={(e) => handleAnswerData("Answer4", e.target.value)} type="text" className={'input-field nospace'} />
                        <Title level={5} className="input-label"><input type="radio" name="correct-answer-radio" value="4" onChange={(e) => handleCorrectAnswer(e.target.value)} /> Correct Answer</Title>
                    </Col>
                </Row>
              }
            </ModalComponent> */}
        </div>
    )
};

export default MultiDetails;