import { Col, Row, Typography, DatePicker, TimePicker } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import ButtonComponent from '../../../components/button/button';
import RadioGroup from '../../../components/radioGroup/radioGroup';
import InputField from '../../../components/inputField/inputField';
import SelectComponent from '../../../components/select/select';
import UploadCard from '../../../components/cards/uploadCard';
import { useNavigate } from "react-router-dom";
import { getAllUsers, getAllCompanies } from '../../../features/common/common';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile } from 'antd/es/upload';
import * as yup from 'yup';
import Notification from '../../../components/notification/notification';
import './notificationDetails.css';
import moment from 'moment';

const { Title } = Typography;

function NotificationDetails({flowData, setFlowData, setNotificationTemplateVisibilty, notificationTemplateFlowIndex, setTemplateIndex}: any) {
  const [valuesArray, setValues] = useState<string[]>([]);
  const [deliveryOptions, setDeliveryOptions] = useState<string[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { usersList, companiesList } = useAppSelector((state) => state.common);
  const [usersArray, setUsersArray] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [selectedSAS, setSelectedSAS] = useState<string[]>([]);
  const [selectAllSAS, setSelectAllSAS] = useState(false);
  const [DepartmentList, setDepartmentsList] = useState<any[]>([]);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const { token } = useAppSelector((state) => state.message);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [qrCode, setQRCode] = useState('');
  const [isQRUploaded, setQRUploaded] = useState(false);
  const [formData, setFormData] = useState({
    options: 0,
    deliveryType: -1,
    scheduledDate: {},
    scheduledTime: {}
  });
  const [formErrors, setFormErrors] = useState({
    options: '',
    deliveryType: ''
  });
  const [uploadResponse, setUploadResponse] = useState({status:'', successMessage:'', errors:[]});
  const [open, setOpen] = useState(false);
  const [selectedCompaniesDepartments, setSelectedCompaniesDepartments] = useState(new Map());
  const [isDepartmentSelectedAll, setIsDepartmentSelectedAll] = useState(false);
  const [isCompanySelectedAll, setIsCompanySelectedAll] = useState(false);

  useEffect(() => {
    setValues(['Send to all users', 'Send to specific users', 'Send to specific Companies', 'Send to all SAs / specific SAs', 'Send to people in OfficeFit only']);
    setDeliveryOptions(['Send Now', 'Scheduled Date & Time']);
    dispatch(getAllUsers());
    dispatch(getAllCompanies());
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    setUsersArray(usersList?.map((user: any) => {
      return {
        id: user.id,
        name: user.firstName + " " + user.lastName
      }
    }))
    // eslint-disable-next-line
  }, [usersList]);

  useEffect(() => {
    let newArray: any = [];
    companiesList?.forEach((company: any) => {
      if(selectedCompanies.includes(company.id) && company.departments.length)
      {
        newArray.push({
          companyID: company.id,
          name: company.name,
          departments: company.departments
        })
      }
    })
    setDepartmentsList(newArray);

    let revisedDepartmentsList: any = [];

    selectedDepartments.forEach((departmentID: any) => {
      let flag=false;

      selectedCompanies.forEach((selectedCompanyID: any) => {
        companiesList.forEach((companyObj: any) => {
          if(companyObj.id === selectedCompanyID && companyObj.departments.length)
          {
            companyObj.departments.forEach((departmentData: any) => {
              if(departmentData.id === departmentID)
              {
                flag=true;
              }
            })
          }
        })
      })

      if(flag)
      {
        revisedDepartmentsList.push(departmentID);
        flag=false;
      }   
    })

    setSelectedDepartments(revisedDepartmentsList);
    // eslint-disable-next-line
  }, [selectedCompanies]);

  useEffect(() => {
    if(formData.deliveryType === 1)
      validateField(deliverySchema, "deliveryType", formData.deliveryType);
  }, [formData.scheduledDate, formData.scheduledTime]);

  useEffect(() => {
    let index = notificationTemplateFlowIndex != null ? notificationTemplateFlowIndex - 1 : flowData.Templates.length-1;
    setFormData({...formData, deliveryType: flowData.Templates[index].DeliveryType});      
  }, [flowData.Templates]);

  useEffect(() => {
    if(notificationTemplateFlowIndex != null)
    {
      
      let index = notificationTemplateFlowIndex != null ? notificationTemplateFlowIndex - 1 : flowData.Templates.length-1;

      var updateFormData = {...formData};
      var templateToEdit = flowData.Templates[notificationTemplateFlowIndex - 1];
      if(templateToEdit.sendToAllUsers) {
        updateFormData.options = 0;
      }
      else if(templateToEdit.sendToSpecificUsers) {
        updateFormData.options = 1;
        setSelectedUsers(templateToEdit.specificUsers);
      }
      else if(templateToEdit.sendToSpecificCompanyPlusDepartment){
        updateFormData.options = 2;
      }
      else if (templateToEdit.sendToAllSa || templateToEdit.sendToSpecificSa) {
        updateFormData.options = 3;
        if (templateToEdit.sendToSpecificSa && templateToEdit.specificSa) {
          setSelectedSAS(templateToEdit.specificSa);
        }
      }
      else if (templateToEdit.sendToOfficefitUsers) {
        updateFormData.options = 4;
      }
      setFormData({...updateFormData});
      
      if(flowData.Templates[index].isScheduledNotification) {
        var dateTime = flowData.Templates[index].scheduleDateTime;
        if(dateTime) {
          var deliveryType = flowData.Templates[index].DeliveryType;
          setFormData({...updateFormData,deliveryType, scheduledDate: moment(dateTime).utc().format('YYYY-MM-DD'), scheduledTime: moment(dateTime).utc().format('YYYY-MM-DDTHH:mm:ss[Z]')});
        }
      }
      else {
        var deliveryType = flowData.Templates[index].DeliveryType;
        setFormData({...updateFormData, deliveryType, scheduledDate: {}, scheduledTime: {}});
      }
      
    }

  },[flowData, notificationTemplateFlowIndex]);

  const handleSelectCompany = (e: any) => {
    let difference = e.filter((data: any) => !selectedCompanies.includes(data));
    let reverseDifference = selectedCompanies.filter((data: any) => !e.includes(data));

    let totalCompanies = companiesList.length;

    if(difference.length)
    {
      setSelectedCompaniesDepartments(prev => {
        let newVal = new Map(prev);
        newVal.set(difference[0], []);
        return newVal;
      });
    }
    else if(reverseDifference.length)
    {
      setSelectedCompaniesDepartments(prev => {
        let newVal = new Map(prev);
        newVal.delete(reverseDifference[0])
        return newVal;
      });
    }
    setSelectedCompanies(e);

    let totalDepartments = 0;
    let totalSelected = e.length;

    DepartmentList.forEach((companyData: any) => {
      totalDepartments += companyData.departments.length;
    });

    if (totalSelected === totalCompanies && totalSelected > 0) {
      setIsCompanySelectedAll(true);
    } else {
      setIsCompanySelectedAll(false);
    }

    setIsDepartmentSelectedAll(false);  
  };

  const handleSelectAllCompanies = (e: any) => {
    setIsCompanySelectedAll(e.target.checked);

    if (e.target.checked === true) {
      let allCompaniesList: string[] = companiesList.map(
        (companyObj: any) => companyObj.id
      );
      var newCompanyMap = new Map();
      allCompaniesList.forEach((companyID: string) => {
        newCompanyMap.set(companyID, []);
      });
      setSelectedCompaniesDepartments(newCompanyMap);
      setSelectedCompanies(allCompaniesList);
    } else {
      setSelectedCompaniesDepartments(new Map());
      handleSelectCompany([]);
      setSelectedCompanies([]);
    }
  };

  const handleSelectAllDepartments = (e: any) => {
    setIsDepartmentSelectedAll(e.target.checked);
    if (e.target.checked === true) {

      let allDepartmentsList: string[] = [];

      selectedCompanies.forEach((selectedCompanyID: any) => {
        companiesList.forEach((companyObj: any) => {
          if (
            companyObj.id === selectedCompanyID &&
            companyObj.departments.length
          ) {
            companyObj.departments.forEach((departmentData: any) => {
              allDepartmentsList.push(departmentData.id);
            });
          }
        });
      });

      handleSelectDepartments(allDepartmentsList);
    }
    else{
      handleSelectDepartments([])
    }
  }

  const handleSelectDepartments = (e: any) => {
    let difference = e.filter((data: any) => !selectedDepartments.includes(data));
    let reverseDifference = selectedDepartments.filter((data: any) => !e.includes(data));

    let totalSelected = e.length;
    let totalDepartments = 0;

    DepartmentList.forEach((companyData: any) => {
      totalDepartments += companyData.departments.length;
    });

    selectedCompanies.forEach((selectedCompanyID: any) => {

      companiesList.forEach((companyObj: any) => {
        if(companyObj.id === selectedCompanyID && companyObj.departments.length)
        {
          companyObj.departments.forEach((departmentData: any) => {
            if(difference.length && difference.includes(departmentData.id))
            {
              let newDepartmentsList = selectedCompaniesDepartments.get(companyObj.id);
              
              difference.forEach((departmentID: string) => {
                if (
                  !companyObj.departments.some(
                    (d: any) => d.id === departmentID
                  )
                )
                  return;
                newDepartmentsList.push(departmentID);
              });
              
              selectedCompaniesDepartments.set(companyObj.id, Array.from(new Set(newDepartmentsList)));           
            }
            else if(reverseDifference.length && reverseDifference.includes(departmentData.id))
            {
              let oldDepartmentsList = selectedCompaniesDepartments.get(companyObj.id);
              let revisedDepartmentsList = oldDepartmentsList.filter((departmentID: string) => !reverseDifference.includes(departmentID));
              
              selectedCompaniesDepartments.set(companyObj.id, Array.from(new Set(revisedDepartmentsList)));           
            }
          })
        }
      })      
    });

    if (totalSelected === totalDepartments) {
      setIsDepartmentSelectedAll(true);
    }
    else {
      setIsDepartmentSelectedAll(false);
    }

    setSelectedDepartments(e);
  };

  const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

  const handleUploadChange = async (filesData: any) => {
    setFileList(filesData.fileList);

    let base64URL = await getBase64(filesData.fileList[0].originFileObj as RcFile);
    setQRCode(base64URL);
    setQRUploaded(true);
  };

  const onFileRemove = () => {
    setFileList([]);
    setQRUploaded(false);
    setQRCode('');
  };

  let schema = yup.object().shape({
    options: yup.number().test('Atleast One','Please select atleast one value from the selected option', function(value){
      let isValid = false;

      if(value === 0 || value === 4)
        return true;
      else
      {
        switch(value)
        {
          case 1:
            if(selectedUsers.length)
              isValid = true;
            break;
          case 2:
            if(selectedCompanies.length && selectedDepartments.length)
              isValid = true;
            break;
          case 3:
            if(selectedSAS.length)
              isValid = true;
            break;
        }
        return isValid;
      }
      
    }),
    deliveryType: yup.number().test('Delivery Type','Please select a valid schedule.', function(value){
      
      if(value === 0)
        return true;
      else if(value === 1 && formData.scheduledDate && formData.scheduledTime && Object.keys(formData.scheduledDate).length !== 0 && Object.keys(formData.scheduledTime).length !== 0)
        return true;
      else
        return false;

    })
  });

  let optionsSchema = yup.object().shape({
    options: yup.number().test('Atleast One','Please select atleast one value from the selected option', function(value){
      let isValid = false;

      if(value === 0 || value === 4)
        return true;
      else
      {
        switch(value)
        {
          case 1:
            if(selectedUsers.length)
              isValid = true;
            break;
          case 2:
            if(selectedCompanies.length && selectedDepartments.length)
              isValid = true;
            break;
          case 3:
            if(selectedSAS.length)
              isValid = true;
            break;
        }
        return isValid;
      }
      
    })
  });

  let deliverySchema = yup.object().shape({
    deliveryType: yup.number().test('Delivery Type','Please select a valid schedule.', function(value){

      if(value === 0)
        return true;
      else if(value === 1 && formData.scheduledDate && formData.scheduledTime && Object.keys(formData.scheduledDate).length !== 0 && Object.keys(formData.scheduledTime).length !== 0)
        return true;
      else
        return false;

    })
  });

  const handleSelectAllUsers = (e: any) => {
    setSelectAllSAS(e.target.checked);

    if(e.target.checked === true)
    {
      let allUsersList: any = usersArray.map((user: any) => {
        return user.id;
      });
      setSelectedSAS(allUsersList);
    }
    else
    {
      setSelectedSAS([]);
    }
  };

  const validateField = (schemaName: any, name: any, value: any) => {
    schemaName.validate({[name]: value}, {abortEarly: false})
    .then((response: any) => { setFormErrors({...formErrors, [name]: ""}); })
    .catch((error: any) => {
      const errors = error.inner.reduce((acc: any, error: any) => {
        return {
          ...acc,
          [error.path]: error.errors[0],
        }
      }, {})
      setFormErrors({...formErrors, ...errors});
    })
  };

  const handleClick = () => {
    schema.validate(formData, {abortEarly: false})
    .then(res => {

      let newArray: any = [];

      setFormErrors({
        options: '',
        deliveryType: ''
      });

      Array.from(selectedCompaniesDepartments).map((companyData: any) => {
        let newObj = {
          [companyData[0]]: companyData[1]
        };
        newArray.push(newObj);
      });

      var initialObj = {
        SendToAllUsers : false,
        SendToSpecificUsers: false,
        SpecificUsers: [],
        SendToSpecificCompanyPlusDepartment: false,
        SpecificCompanyPlusDepartment: [],
        SendToAllSa: false,
        SendToSpecificSa: false,
        SpecificSa: [],
        SendToOfficefitUsers: false,
      }
      
      var scheduleDateTime = moment(formData.scheduledDate).format('YYYY-MM-DD') + "T" + moment(formData.scheduledTime).utc().format('HH:mm:ss') + "Z";

      let notificationObj = {
        ...(qrCode && { Base64ImageString: qrCode }),
        ...(initialObj),
        ...(formData.options === 0 && { "SendToAllUsers": true }),
        ...(formData.options === 1 && { "SendToSpecificUsers": true, "SpecificUsers": selectedUsers }),
        ...(formData.options === 2 && { "SendToSpecificCompanyPlusDepartment": true, "SpecificCompanyPlusDepartment": newArray }),
        ...(formData.options === 3 && selectAllSAS && { "SendToAllSa": true }),
        ...(formData.options === 3 && !selectAllSAS && { "SendToSpecificSa": true, "SpecificSa": selectedSAS }),
        ...(formData.options === 4 && { "SendToOfficefitUsers": true }),
        ...(formData.deliveryType === 1 && { "IsScheduledNotification": true, "ScheduleDateTime": scheduleDateTime })
      };

      
      let index = notificationTemplateFlowIndex != null ? notificationTemplateFlowIndex - 1 : flowData.Templates.length-1;

      let templateData = flowData.Templates[index];
      templateData = {
        ...templateData,
        ...notificationObj
      }

      flowData.Templates[index] = templateData;

      setFlowData({
        ...flowData,
        Templates: [...flowData.Templates]
      });      

      setNotificationTemplateVisibilty(true);
      // setTemplateIndex(null);
    })
    .catch(error => {
      const errors = error.inner.reduce((acc: any, error: any) => {
        return {
          ...acc,
          [error.path]: error.errors[0],
        }
      }, {});
      setFormErrors(errors);
    });
  }

  const handleInputChange = (name: string, value: any) => {
    setFormData({...formData, [name]: value});

    switch(name)
    {
      case 'options':
        validateField(optionsSchema, name, value);
        setSelectedUsers([]);
        break;
      case 'deliveryType':
        setFormErrors({...formErrors, [name]: ""})
        validateField(deliverySchema, name, value);
        break;
      case 'scheduledDate':
        setFormData({...formData, [name]: value.format('YYYY-MM-DD')});
        break;
      case 'scheduledTime':
        setFormData({...formData, [name]: value.format('YYYY-MM-DDTHH:mm:ss[Z]')});
        break;
    }
  };

  // useEffect(() => {
  //   console.log("notificationDetails", flowData);
  //   if(notificationTemplateFlowIndex != null) {
      
  //   }
  // },[flowData]);

  return (
    <div>
        <Row className="notification-container">
            <Row className="header">
                <Title level={2} className="title">Notification Console</Title>
            </Row>
            
            <Row className="form">
              <Col xs={24} sm={24} md={8} lg={8} xl={10} style={{marginTop:'1em'}}>
                  <Row>			
                      <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                        <RadioGroup 
                          className="radio-group-container"
                          direction="vertical"
                          selectedValue={formData.options} 
                          values={valuesArray}
                          name="options"
                          onChange={(e: any) => handleInputChange("options", e.target.value)} 
                          hasChild={true} 
                          childIndex={2} 
                          childComponent={
                            <>
                              { selectedCompanies.length > 0 && <span style={{display:'inline-block', margin:'2.5em 1em 0em 0em'}}>Send to specific Departments</span> }
                            </>
                          } 
                        />
                      </Col>
                      <Col xs={24} sm={24} md={6} lg={8} xl={11} className="dropdown-container">
                        {formData.options === 1 && <SelectComponent className="specific-users" list={usersArray} selectedList={selectedUsers} placeholder="Select Users" onChange={(e: any) => setSelectedUsers(e)} mode="multiple" selectAll={false}/>}

                        {formData.options === 2 && 
                          <>
                            <SelectComponent className="specific-companies" list={companiesList} selectedList={selectedCompanies} placeholder="Select Companies" onChange={(e: any) => handleSelectCompany(e)} mode="multiple" selectAll={true} allChecked={isCompanySelectedAll} onSelectAll={(e: any) => handleSelectAllCompanies(e)}/>
                            {selectedCompanies.length > 0 && <SelectComponent className="specific-departments" list={DepartmentList} selectedList={selectedDepartments} placeholder="Select Departments" mode="multiple" selectAll={true} isOptionGroup={true} onChange={(e: any) => handleSelectDepartments(e)} onSelectAll={(e: any) => handleSelectAllDepartments(e)} allChecked={isDepartmentSelectedAll}/>}
                          </>
                        }
                        {formData.options === 3 && <SelectComponent className="specific-sas" list={usersArray} selectedList={selectedSAS} placeholder="Select Users" mode="multiple" onChange={(e: any) => { setSelectedSAS(e); setSelectAllSAS(false); }} onSelectAll={handleSelectAllUsers} allChecked={selectAllSAS} selectAll={true}/>}
                      </Col>
                      { formErrors.options && <Title level={5} className="error-label" type="danger">{formErrors.options}</Title>}
                  </Row>

                  <Row style={{margin:'1em 0em 0em 0em', rowGap:'1.5em'}}>			
                      <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                          <Title level={5} className="input-label">Delivery Date</Title>
                          <SelectComponent className="delivery-date" disabled={true} defaultValue={flowData.Templates.length > 0 ? flowData.Templates[(notificationTemplateFlowIndex != null ? notificationTemplateFlowIndex - 1 : flowData.Templates.length-1)].DeliveryType : undefined} list={deliveryOptions} placeholder="Select" onChange={(e) => handleInputChange("deliveryType", e)} selectAll={false} isOptionGroup={false}/>
                      </Col>
                      <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                          <Title level={5} className="input-label">Template Name</Title>
                          <InputField name="templateName" type="text" disabled={true} value={flowData.TemplateName ? flowData.TemplateName : ''} onChange={(e) => console.log(e.target.value)} autoComplete="false" className="message-input"/>
                      </Col>
                  </Row>

                  {
                    formData.deliveryType === 1 && <Row style={{margin:'1.5em 0em 0em 0em', rowGap:'1.5em'}}>			
                        <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                            <Title level={5} className="input-label">Scheduled Date (UTC)</Title>
                            <DatePicker onChange={(e) => handleInputChange("scheduledDate", e)} defaultValue={formData.scheduledDate && Object.keys(formData.scheduledDate).length !== 0 ? moment(formData.scheduledDate).utc() : undefined} className="date-picker"/>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                            <Title level={5} className="input-label">Scheduled Time (UTC)</Title>
                            <TimePicker onChange={(e) => handleInputChange("scheduledTime", e)} defaultValue={formData.scheduledTime && Object.keys(formData.scheduledTime).length !== 0 ? moment(formData.scheduledTime).utc() : undefined} className="time-picker"/>
                        </Col>
                    </Row>
                  }
                  { formErrors.deliveryType && <Title level={5} className="error-label" type="danger">{formErrors.deliveryType}</Title>}
                  <ButtonComponent title="Save Flow" onClick={() => handleClick()} className="send-message" />
                  {/* <ButtonComponent title="Save Flow" onClick={() => { navigate('/notification-template')}} className="send-message" /> */}
                  
              </Col>
            </Row>
            {/* <Col xs={24} sm={24} md={6} lg={8} xl={10} className="upload-card-container">
                <UploadCard className="notification-upload-card" onChange={handleUploadChange} onClose={onFileRemove} fileList={fileList} isQRUploaded={isQRUploaded} previewImage={qrCode}/>
            </Col> */}
        </Row>
        <Notification 
          open={open} 
          setOpen={setOpen} 
          title={uploadResponse?.status === "SUCCESS" ? "Success" : "Error"} 
          message={uploadResponse?.successMessage ? uploadResponse.successMessage : uploadResponse?.errors ? uploadResponse.errors[0] : "Something went wrong, Please try again."} 
          severity={uploadResponse?.status === "SUCCESS" ? "success" : "error"} 
        />
    </div>
  )
};

export default NotificationDetails;