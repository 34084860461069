import { Col, Row, Typography } from 'antd';
import * as Icons from "@ant-design/icons";
import { Card } from 'antd';
import './videoCard.css';
import { VideoCardProps } from '../../models/componentTypes/types';
import { FC, useState } from 'react';
import ModalComponent from '../modal/modal';

const { Title } = Typography;

const VideoCard: FC<VideoCardProps> = ({
    title,
    instructor,
    imageURL,
    videoURL
}) => {

    const [isPlayerVisible, setPlayerVisible] = useState(false);
    const [randomKey, setRandomKey] = useState(0);

    const resetPlayer = async () => {
        setRandomKey(Math.random());
    };

  return (
    <div>
        <Row className="video-card-container">
            <Col xs={24} className="video-card-col">
                <Card className="video-card-image" style={{background: `url(${imageURL})`}}>
                    <Icons.PlayCircleFilled className="video-card-play-icon" onClick={() => setPlayerVisible(true)} style={{display:'none'}} />
                </Card>
            </Col>
            <Col xs={24} className="video-card-details">
                <Title level={4} className="video-card-title">{title}</Title>
                <Title level={5} className="video-card-subtitle">{instructor}</Title>
            </Col>
        </Row>

        <ModalComponent
            visible={isPlayerVisible}
            footer={null}
            closable={true}
            onCancel={async () => {
                await resetPlayer();
                setPlayerVisible(false);
            }}
            className="video-player"
        >
            <div className="frame-container">
                <iframe key={randomKey} className="video-frame" src={videoURL} frameBorder="0" title="replayPlayer" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
            </div>
        </ModalComponent>
    </div>
  )
};

export default VideoCard;
