import { notification } from 'antd';
import { useEffect } from 'react';
import './notification.css';
import { clearMessage } from '../../features/message/message';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

const Notification = ({open, setOpen, title, message, severity}: any) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        if(open)
        {
            openNotification();
            setOpen(false);
            dispatch(clearMessage());
        }
    }, [open]);

    const openNotification = () => {
        notification.open({
          message: title,
          description: message,
          placement: "bottomLeft",      
          className: severity
        });
    };

    return null;
};
  
export default Notification;