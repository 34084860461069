import { setMessage } from '../message/message';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import dictionaryService from '../../services/dictionaryService';

const initialState: any = {
  notificationDictionary: [],
  isLoading: false  
}

export const saveNotificationDictionary = createAsyncThunk(
  'notificationDictionary/saveNotificationDictionary',
  async (params: any, thunkAPI) => {
      try {
          const response = await dictionaryService.saveDictionary(params);      
          return response.data;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
          return error.response.data;
      }
  }
)

export const getNotificationDictionary = createAsyncThunk(
  'notificationDictionary/getNotificationDictionary',
  async (params, thunkAPI) => {
      try {
          const response = await dictionaryService.getDictionary();      
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
          return error.response.data;
      }
  }
)

export const getNotificationDictionaryById = createAsyncThunk(
  'notificationDictionary/getNotificationDictionaryById',
  async (params: string, thunkAPI) => {
      try {
          const response = await dictionaryService.getDictionaryById(params);      
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
          return error.response.data;
      }
  }
)

export const getNotificationDictionaryByKey = createAsyncThunk(
  'notificationDictionary/getNotificationDictionaryByKey',
  async (params: string, thunkAPI) => {
      try {
          const response = await dictionaryService.getDictionaryByKey(params);      
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
          return error.response.data;
      }
  }
)

const notificationDictionarySlice = createSlice({
  name: 'notificationDictionary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveNotificationDictionary.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(saveNotificationDictionary.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(saveNotificationDictionary.rejected, (state, action) => {
      console.log("Error Sending Notification: ", action.payload);
      state.isLoading = false;
    })
    builder.addCase(getNotificationDictionary.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getNotificationDictionary.fulfilled, (state, action) => {
      state.notificationDictionary = action.payload;
      state.isLoading = false;
    })
    builder.addCase(getNotificationDictionary.rejected, (state, action) => {
      console.log("Error fetching Notifications: ", action.payload);
      state.isLoading = false;
    })
    builder.addCase(getNotificationDictionaryById.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getNotificationDictionaryById.fulfilled, (state, action) => {
      state.notificationDictionary = action.payload;
      state.isLoading = false;
    })
    builder.addCase(getNotificationDictionaryById.rejected, (state, action) => {
      console.log("Error fetching Notifications: ", action.payload);
      state.isLoading = false;
    })
    builder.addCase(getNotificationDictionaryByKey.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getNotificationDictionaryByKey.fulfilled, (state, action) => {
      state.notificationDictionary = action.payload;
      state.isLoading = false;
    })
    builder.addCase(getNotificationDictionaryByKey.rejected, (state, action) => {
      console.log("Error fetching Notifications: ", action.payload);
      state.isLoading = false;
    })
  },
});

export default notificationDictionarySlice.reducer;