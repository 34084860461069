import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../services/userService';
import { setMessage } from '../message/message';

const initialState: any = {
  usersList: [],
  companiesList: [],
  videos: [],
  page: 1,
  isLoading: false
}

export const getAllUsers = createAsyncThunk(
    'common/getAllUsers',
    async (params, thunkAPI) => {
        try {
            const response = await userService.getAllUsers();   
            return response.data.details;
        } catch (error: any) {
            thunkAPI.dispatch(setMessage(error.response));
        }
    }
)

export const getAllCompanies = createAsyncThunk(
    'common/getAllCompanies',
    async (params, thunkAPI) => {
        try {
            const response = await userService.getAllCompanies();     
            return response.data.details;
        } catch (error: any) {
            thunkAPI.dispatch(setMessage(error.response));
        }
    }
)

export const getAllRegularChallenges = createAsyncThunk(
  'notifications/getAllRegularChallenges',
  async (params, thunkAPI: any) => {
      try {
          const response = await userService.getAllRegularChallenges();      
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const getPopularVideos = createAsyncThunk(
  'common/getPopularVideos',
  async (params: number, thunkAPI) => {
      try {
          const response = await userService.getPopularVideos(params);          
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const setPage = createAsyncThunk(
  'common/setPage',
  async (params: number, thunkAPI) => {
    return params;
  }
)

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllUsers.fulfilled, (state, action) => {
      state.usersList = action.payload;
      state.isLoading = false;
    })
    builder.addCase(getAllUsers.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
    builder.addCase(getAllUsers.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getAllCompanies.fulfilled, (state, action) => {
      state.companiesList = action.payload;
    })
    builder.addCase(getAllCompanies.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
    })
    builder.addCase(getPopularVideos.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getPopularVideos.fulfilled, (state, action) => {
      state.videos = state.page === 1 ? [...action.payload] : [...state.videos, ...action.payload];

      if(action.payload.length > 0)
      {
        state.page += 1; 
      }
      state.isLoading = false;
    })
    builder.addCase(getPopularVideos.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
      state.isLoading = false;
    })
    builder.addCase(setPage.fulfilled, (state, action) => {
      state.page = action.payload;
    })
    builder.addCase(setPage.rejected, (state, action) => {
      console.log("Error Occurred: ",action.payload);
    })
    builder.addCase(getAllRegularChallenges.fulfilled, (state, action) => {
      state.challengesList = action.payload?.map((challenge: any) => {
        return {
          id: challenge.challengeId,
          name: challenge.challengeName
        }
      });
    })
    builder.addCase(getAllRegularChallenges.rejected, (state, action) => {
      console.log("Error fetching templates: ", action.payload);
    })
  },
})

export default commonSlice.reducer; 