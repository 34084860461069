import { useEffect, useState } from "react";
import TableComponent from "../../components/table/table";
import { Checkbox, Col, Popconfirm, Row, message } from "antd";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Title from "antd/lib/typography/Title";
import { getNotificationTemplates } from "../../features/notificationTemplate/notificationTemplate";
import { ColumnsType } from "antd/lib/table";
import { notifcationTemplateColumnTypes } from "../../models/componentTypes/types";
import "./notificationTemplateList.css";
import EditIcon from "../../assets/icons/edit.svg";
import DeleteIcon from "../../assets/icons/closeIcon.svg";
import { DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import userService from "../../services/userService";

function NotificationTemplateList() {
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [notificationTemplateList, setNotificationTemplateList] = useState<
    any[]
  >([]);
  const notificationTemplates: notifcationTemplateColumnTypes[] =
    useAppSelector((state) => state.notificationTemplate.notificationTemplates);
  const { isLoading } = useAppSelector((state) => state.notificationTemplate);
  const { token } = useAppSelector((state) => state.message);

  useEffect(() => {
    dispatch(getNotificationTemplates());
  }, [token]);

  useEffect(() => {
    if (notificationTemplates) {
      var _templates = notificationTemplates.map((template) => {
        return {
          id: template.id,
          templanteName: template.templateName,
          connectToQrCode: template.connectToQrCode,
        };
      });
      setNotificationTemplateList(_templates);
    }
  }, [notificationTemplates]);

  const handleEditTemplate = (id: string) => {};

  const handleDeleteTemplate = (id: string) => {
    userService.deleteNotificationTemplate(id).then((response) => {
      if (response.status === 200) {
        dispatch(getNotificationTemplates());
        messageApi.open({
          type: "success",
          content: "Notification Template Deleted Successfully",
          duration: 3,
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Error Deleting Notification Template",
          duration: 3,
        });
      }
    });
  };

  const columns: ColumnsType<notifcationTemplateColumnTypes> = [
    {
      title: "Template Name",
      dataIndex: "templanteName",
      key: "templanteName",
    },
    {
      title: "Connect To QrCode",
      dataIndex: "connectToQrCode",
      key: "connectToQrCode",
      render: (text, record) => (
        //render checkbox
        <Checkbox checked={record.connectToQrCode} />
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Row>
          <Col>
            <Link
              to={`/notification-template/${record.id}`}
              className="edit-button"
            >
              <img src={EditIcon} alt="Edit" />
            </Link>
          </Col>
          <Col>
            <Popconfirm
              title="Are you sure to delete this template?"
              okText="Delete"
              cancelText="Cancel"
              onConfirm={() => handleDeleteTemplate(record.id)}
            >
              <div className="delete-button">
                <DeleteOutlined />
              </div>
            </Popconfirm>
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div className="notification-container">
      {contextHolder}
      {isLoading && <LoadingSpinner />}
      <Row>
        <Col span={24}>
          <Title>Notification Templates</Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <TableComponent
            className="notification-table"
            Data={notificationTemplateList}
            columns={columns}
          />
        </Col>
      </Row>
    </div>
  );
}

export default NotificationTemplateList;
