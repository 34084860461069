import { Link } from 'react-router-dom';
import { Col, Row, Typography, RadioChangeEvent } from 'antd';
import ReportCard from '../../components/cards/card';
import VideoCard from '../../components/cards/videoCard';
import './dashboard.css';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getActivatedQRCount, getPremiumUsersCount, getViewsCount } from '../../features/dashboard/dashboard';
import { getPopularVideos, setPage } from '../../features/common/common';
import moment from 'moment';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
const { Title } = Typography;

function Dashboard() {
  const dispatch = useAppDispatch();
  const { activatedQRCount, premiumUsersCount, viewsCount } = useAppSelector((state) => state.dashboard);
  const { videos, page, isLoading } = useAppSelector((state) => state.common);
  const { token } = useAppSelector((state) => state.message);
  const [value, setValue] = useState(0);
  const [startDate, setStartDate] = useState(moment().toISOString());
  const [endDate, setEndDate] = useState(moment().toISOString());
  const [viewOption, setViewOption] = useState(0);
  const [viewsStartDate, setViewsStartDate] = useState(moment().toISOString());
  const [viewsEndDate, setViewsEndDate] = useState(moment().toISOString());

  useEffect(() => {
    dispatch(getPremiumUsersCount({}));
  }, []);

  useEffect(() => {
    resetData().then(resp => {
      dispatch(getPopularVideos(1));
    })
    dispatch(getActivatedQRCount());
    // eslint-disable-next-line
  }, [token]);

  const resetData = async () => {
    await dispatch(setPage(1));
  };

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);

    switch(e.target.value)
    {
      case 0: {
        setStartDate(moment().startOf('day').toISOString());
        setEndDate(moment().endOf("day").toISOString());
        break;
      }
      case 1: {
        setStartDate(moment().subtract(1,'days').startOf('day').toISOString());
        setEndDate(moment().subtract(1,'days').endOf("day").toISOString());
        break;
      }
      case 2: {
        setStartDate(moment().endOf("day").subtract(7,'days').toISOString());
        setEndDate(moment().toISOString());
        break;
      }
      case 3: {
        setStartDate(moment().endOf("day").subtract(30,'days').toISOString());
        setEndDate(moment().toISOString());
        break;
      }
      case 4: {
        setStartDate(moment().endOf("day").subtract(365,'days').toISOString());
        setEndDate(moment().toISOString());
        break;
      }
    }
  };

  // on start date change
  const handleStartDateChange = (e: any) => {
    setStartDate(moment(e).toISOString());
  };

  // on end date change
  const handleEndDateChange = (e: any) => {
    setEndDate(moment(e).toISOString());
  };

  // on apply
  const handlePremiumFilter = (e: any) => {
    dispatch(getPremiumUsersCount({startDate: startDate, endDate: endDate}));
  };

  // on reset
  const handleResetPremiumFilter = (e: any) => {
    setStartDate(moment().toISOString());
    setEndDate(moment().toISOString());
    dispatch(getPremiumUsersCount({}));
  };

  const onViewOptionChange = (e: RadioChangeEvent) => {
    setViewOption(e.target.value);

    switch(e.target.value)
    {
      case 0: {
        setViewsStartDate(moment().startOf('day').toISOString());
        setViewsEndDate(moment().endOf("day").toISOString());
        break;
      }
      case 1: {
        setViewsStartDate(moment().subtract(1,'days').startOf('day').toISOString());
        setViewsEndDate(moment().subtract(1,'days').endOf("day").toISOString());
        break;
      }
      case 2: {
        setViewsStartDate(moment().endOf("day").subtract(7,'days').toISOString());
        setViewsEndDate(moment().toISOString());
        break;
      }
      case 3: {
        setViewsStartDate(moment().endOf("day").subtract(30,'days').toISOString());
        setViewsEndDate(moment().toISOString());
        break;
      }
      case 4: {
        setViewsStartDate(moment().endOf("day").subtract(365,'days').toISOString());
        setViewsEndDate(moment().toISOString());
        break;
      }
    }
  };

  const viewsStartDateChange = (e: any) => {
    setViewsStartDate(moment(e).toISOString());
  };

  const viewsEndDateChange = (e: any) => {
    setViewsEndDate(moment(e).toISOString());
  };

  const handleViewsFilter = (e: any) => {
    dispatch(getViewsCount({startDate: viewsStartDate, endDate: viewsEndDate}));
  };

  const handleResetViewsFilter = (e: any) => {
    setViewsStartDate(moment().toISOString());
    setViewsEndDate(moment().toISOString());
    dispatch(getViewsCount({startDate: viewsStartDate, endDate: viewsEndDate}));
  };

  return (
    <div>
      { isLoading && <LoadingSpinner /> }
      <Row gutter={[22, 24]} className="reports-container">
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="col-1">
          <ReportCard icon="qrcode" title="Activated QR Code" subtitle="Total activated QR Codes" count={activatedQRCount} dateRange="false" filter="false"/>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="col-2">
          <ReportCard icon="users" title="Premium Users" subtitle="No. of Premium Users of App" radioValue={value} startDate={startDate} endDate={endDate} count={premiumUsersCount} onStartDateChange={handleStartDateChange} onEndDateChange={handleEndDateChange} onRadioChange={onChange} onApply={handlePremiumFilter} onReset={handleResetPremiumFilter} dateRange="true" filter="true"/>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} className="col-3">
          <ReportCard icon="view" title="GetUpp Play Views" subtitle="No. of Views of GetUpp Play" radioValue={viewOption} startDate={viewsStartDate} endDate={viewsEndDate} count={viewsCount} onStartDateChange={viewsStartDateChange} onEndDateChange={viewsEndDateChange} onRadioChange={onViewOptionChange} onApply={handleViewsFilter} onReset={handleResetViewsFilter} dateRange="false" filter="true"/>
        </Col>
      </Row>

      <div className="videos-header">
        <Title level={3} className="no-spacing" style={{color:'#363636'}}>Popular Videos</Title>
        <Title level={5} className="no-spacing"><Link to="/popular-videos" className="view-all">View All</Link></Title>
      </div>
      
      <Row gutter={[10, 24]} className="videos-container">
        {
          videos && videos.map((item: any) => {
            return (
              <Col xs={24} sm={12} md={10} lg={8} xl={5}>
                <VideoCard title={item.videoType} instructor={item.firstName + " " + item.lastName} videoURL={item.iframeUrl} imageURL={item.thumbnailUrl}/>
              </Col> 
            )
          })
        } 
               
      </Row>
    </div>
  )
};

export default Dashboard;