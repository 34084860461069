import { useEffect, useRef, useState, useContext, useCallback, memo, FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { Col, Row, Typography, DatePicker, TimePicker, InputNumber, Select, Button, Steps, Radio, Upload } from 'antd';
import InputField from '../../../../components/inputField/inputField';
import ButtonComponent from '../../../../components/button/button';
import './milestoneDetails.css';
import userService from '../../../../services/userService';
import { setMessage } from '../../../../features/message/message';
import GoogleMapsComponent from '../../../../components/googleMaps/googleMaps';
import SelectComponent from '../../../../components/select/select';
import EditIcon from '../../../../assets/icons/edit.svg';
import { kml } from '@tmcw/togeojson';
import xmldom from '@xmldom/xmldom';
import { challengeContext } from '../../../../App';
import * as yup from 'yup';
import MilestoneModal from './milestoneModal/milestoneModal';
import { createNewArchiveChallenge, updateArchiveChallenge } from '../../../../features/challenges/challenges';
import { useNavigate } from 'react-router-dom';
import Notification from '../../../../components/notification/notification';
import ChallengeHeader from '../challengeHeader/challengeHeader';
import LoadingSpinner from '../../../../components/loadingSpinner/loadingSpinner';
// import tokml from 'tokml';

const { Title } = Typography;
const { Option } = Select;

let drawingData: any = [];
let GEOData: any = [];
 
function MilestoneDetails({moveToNextScreen}: any) { 
    const unitList = ['KM', 'Miles'];
    const [formData, setFormData] = useState<any>({
        totalMilestone: 1,
        hasMilestone: true,
        distanceUnit: ''
    });
    const [formErrors, setFormErrors] = useState({
        hasMilestone: '',
        totalMilestone: '',
        milestones: '',
        KMLUrl: '', 
        distance: '',
        distanceUnit: ''
    });
    
    const [milestones, setMilestones] = useState<any>([]);
    const [isMilestoneFormVisible, setMilestoneFormVisible] = useState(false);
    const [isEditMode, setEditMode] = useState(false);
    const dispatch = useAppDispatch();
    const [mapCenter, setMapCenter] = useState<any>({lat: 0, lng: 0});
    const [KMLUrl, setKMLUrl] = useState('');
    const [distance, setDistance] = useState(0);
    const { challengeData, setChallengeData } = useContext(challengeContext);
    const [editIndex, setEditIndex] = useState(0);
    const GoogleMapsAPIKey = process.env.REACT_APP_MAPS_KEY;    
    const [mapDetails, setMapDetails] = useState({
        category: "",
        difficulty: "",
        design: "",
    });
    const navigate = useNavigate();
    const [uploadResponse, setUploadResponse] = useState({status:'', successMessage:'', errors:[]});
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(challengeData.isSeriesChallenge)
        {
            let currentChallengeIndex = challengeData.currentChallengeCount-1;
            let currentChallengeData = challengeData.challengeItemList[currentChallengeIndex];

            setMapDetails({
                ...mapDetails, 
                category: (currentChallengeData as any).category, 
                difficulty: (currentChallengeData as any).difficulty, 
                design: (currentChallengeData as any).design
            });
        }
        else
        {
            setMapDetails({
                ...mapDetails, 
                category: (challengeData.challengeItemDto as any).category, 
                difficulty: (challengeData.challengeItemDto as any).difficulty, 
                design: (challengeData.challengeItemDto as any).design
            });
        }
    }, []);

    useEffect(() => {
        if((challengeData.isSeriesChallenge))
        {
            let currentChallengeData: any = challengeData.challengeItemList[challengeData.currentChallengeCount-1];
            setFormData({...formData, ...currentChallengeData});
            let distanceValue = currentChallengeData.distance;
            let mapURL = currentChallengeData.mapKmlUrl;
            
            if(distanceValue)
                setDistance(distanceValue);
            
            if(mapURL)
                setKMLUrl(mapURL);

            let hasMilestone = currentChallengeData.hasMilestone;

            if(hasMilestone)
                setMilestones(currentChallengeData.milestones);

            var challengeId = currentChallengeData.challengeId;
            if(challengeId)
                setMilestoneData(challengeId);
        }
        else
        {
            setFormData({...formData, ...challengeData.challengeItemDto});
            let distanceValue = (challengeData.challengeItemDto as any).distance;
            let mapURL = (challengeData.challengeItemDto as any).mapKmlUrl;
            
            if(distanceValue)
                setDistance(distanceValue);
            
            if(mapURL)
                setKMLUrl(mapURL);

            let hasMilestone = (challengeData.challengeItemDto as any).hasMilestone;

            if(hasMilestone)
                setMilestones((challengeData.challengeItemDto as any).milestones);

            var challengeId = (challengeData.challengeItemDto as any).challengeId;
            if(challengeId)
                setMilestoneData(challengeId);
        }
        
    }, [challengeData]);

    const setMilestoneData = async (challengeId:string) => {

        if(challengeId) {
            setIsLoading(true);
            var responseMap = await userService.getMapUrlByChallengeId(challengeId);
            var resultMilestone = await userService.getMilestoneByChallengeId(challengeId);
    
            
            const { mapKmlUrl, distance, distanceUnit } = responseMap.data.details;
            var newFormData = {...formData, milestones: resultMilestone.data.details, totalMilestone: resultMilestone.data.details.length , distanceUnit: distanceUnit};
    
            setKMLUrl(mapKmlUrl);
            setDistance(distance);
            setMilestones(resultMilestone.data.details);
            setFormData(newFormData);
            setIsLoading(false);
        }
    }

    const getDistance = (lat1: any, lng1: any, lat2: any, lng2: any) => {
        var radlat1 = Math.PI * lat1 / 180;
        var radlat2 = Math.PI * lat2 / 180;
        var theta = lng1 - lng2;
        var radtheta = Math.PI * theta / 180;
        var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        dist = Math.acos(dist);
        dist = dist * 180 / Math.PI;
        dist = dist * 60 * 1.1515;

        //Get in in kilometers
        dist = dist * 1.609344;

        return dist;
    };

    const handlePolyData = useCallback((polyline: any) => {
        setKMLUrl('');
        let lineStringData: any = [];
        polyline.getPath().getArray().map((cord: any, index: number) => {
            lineStringData.push([cord.lng(), cord.lat()]);
        });

        let featureObj:any = {};
        let distance: number = 0;

        featureObj.type = "Feature";
        featureObj.properties = {
            "name": "Line String",
            "styleUrl": "#__managed_style_0428423FF12664390489",
            "fill-opacity": 0.25098039215686274,
            "fill": "#4287f5",
            "stroke-opacity": 5,
            "stroke": "#fbc02d",
            "stroke-width": 4,
            "icon-offset": [
              64,
              128
            ],
            "icon-offset-units": [
              "pixels",
              "insetPixels"
            ],
            "icon": "https://earth.google.com/earth/rpc/cc/icon?color=1976d2&id=2000&scale=4"
        };
        featureObj.geometry = {type: 'LineString', coordinates: lineStringData};

        GEOData = [...GEOData, featureObj];

        for(let i=0; i<lineStringData.length-1; i++)
                distance += getDistance(lineStringData[i][1], lineStringData[i][0], lineStringData[i+1][1], lineStringData[i+1][0]);

        setDistance(Math.round(distance));
        //setChallengeData({...challengeData, milestoneDistance: Math.round(distance)});

        let finalData = {
            type: "FeatureCollection",
            features: GEOData
        };
        // console.log("Final KML Data: ", tokml(finalData));
    }, []);

    const handleMarkerData = useCallback((markerData: any) => {
        //console.log("Marker Data: lat: ", markerData.position.lat(), " and long: ",markerData.position.lng());
        setKMLUrl('');
        let featureObj:any = {};

        featureObj.type = "Feature";
        featureObj.properties = {
            "name": "Placemark",
            "styleUrl": "#__managed_style_0428423FF12664390489",
            "fill-opacity": 0.25098039215686274,
            "fill": "#ffffff",
            "stroke-opacity": 1,
            "stroke": "#fbc02d",
            "stroke-width": 4,
            "icon-offset": [
                64,
                128
            ],
            "icon-offset-units": [
                "pixels",
                "insetPixels"
            ],
            "icon": "https://earth.google.com/earth/rpc/cc/icon?color=1976d2&id=2000&scale=4"
        };
        featureObj.geometry = {type: 'Point', coordinates: [markerData.position.lng(), markerData.position.lat()]}

        console.log("Current GeoData: ", GEOData);
        GEOData = [...GEOData, featureObj];

        let finalData = {
            type: "FeatureCollection",
            features: GEOData
        };
        // console.log("Final KML Data: ", tokml(finalData));
        
    }, []);

    const uploadFile = async (file: any) => {
        let obj = {
            file: file
        };

        return userService.uploadKMLFile(obj);
    };

    const handleOverlayComplete = useCallback((e: any) => {
        drawingData = [...drawingData, e.overlay];
    }, []);

    const clearMapDrawings = () => {
        console.log("Clear Map Executed");
        drawingData.forEach((shape: any) => {
            shape.setMap(null);
        });
        GEOData = [];
    };

    const handleMapUpload = useCallback((file:any, fileList:any) => {
        const reader = new FileReader();
        setKMLUrl('');

        reader.readAsText(file);

        reader.onload = (res: any) => {
            uploadFile(file).then(res => {
                setKMLUrl(res.data.details);
                validateField('KMLUrl', res.data.details);
            }).catch(error => {
                dispatch(setMessage(error.response)).then(res => {
                    uploadFile(file).then(res => {
                        setKMLUrl(res.data.details);
                        validateField('KMLUrl', res.data.details);
                    });
                });
            });

            let cords:any = [];
            let totalDistance:number = 0;
            const dom = new xmldom.DOMParser().parseFromString(res.target.result, "text/xml");
            let geoJSON = kml(dom);

            geoJSON.features.map((feature: any, index: number) => {
                if(feature.geometry.type === "LineString")
                    cords = feature.geometry.coordinates;
            });

            setMapCenter({lat: cords[0][1], lng: cords[0][0]});
    
            for(let i=0; i<cords.length-1; i++)
                totalDistance += getDistance(cords[i][1], cords[i][0], cords[i+1][1], cords[i+1][0]);

            setDistance(Math.round(totalDistance));
            validateField('distance', Math.round(totalDistance));
        };
    
        // Prevent upload
        return false;
    }, []);

    const handleRemoveMap = useCallback(() => {
        setKMLUrl('');
        setDistance(0);
        validateField('KMLUrl', '');
        validateField('distance', 0);
    }, []);

    const handleOptionChange = (e: any) => {
        setFormData({...formData, hasMilestone: e === 'Yes' ? true : false, milestones: []});
        validateField('milestones', []);
    };

    const selectBefore = (
        <Select value={formData.hasMilestone === false ? "No" : "Yes"} onChange={handleOptionChange} style={{ width: 90 }}>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
        </Select>
    );

    const handleInputChange = (name: string, value: any) => {
        setFormData({...formData, [name]: value});
        validateField(name, value);
    };

    const handleAddMilestones = () => {
        const hasMilestones = formData.milestones?.length > 0 ? true : false;
        let totalMilestone = formData.totalMilestone ? formData.totalMilestone : 1;

        if(hasMilestones)
        {
            //insert empty object to milestones array
            let emptyMilestone = {
                "totalMilestone": 0,
                "milestoneBase64Image": null,
                "milestoneName": '',
                "milestoneDescription": '',
                "progressPercentage": 0,
                "hasQuestions": false,
                "questionTitle": null,
                "answerTime": null,
                "answersList": {},
                "milestoneByLangList": []
            }
            totalMilestone++;
            setMilestones([...milestones, emptyMilestone]);
            setFormData({...formData, totalMilestone, milestones: [...milestones, emptyMilestone]});
            handleEditMilestone(totalMilestone - 1);
        }
        else
        {
            setFormData({...formData, milestones: []});
            setMilestones([]);
            setMilestoneFormVisible(true);
        }
    };

    const handleSaveMilestones = (milestonesData: any) => {
        setFormData({...formData, milestones: milestonesData});
    };

    const handleEditMilestone = (index: number) => {
        setEditMode(true);
        setEditIndex(index);
        setMilestoneFormVisible(true);
    };

    const getFinalData = () => {
        if(challengeData.isSeriesChallenge)
        {
            let currentChallengeIndex = challengeData.currentChallengeCount-1;
            let currentChallengeList: any = challengeData.challengeItemList;
            currentChallengeList[currentChallengeIndex] = {...currentChallengeList[currentChallengeIndex], ...formData, mapKmlUrl: KMLUrl, distance: distance, milestones: formData.milestones};

            return {...challengeData, challengeItemList: currentChallengeList};
        }
        else
        {
            return {...challengeData, challengeItemDto: {...challengeData.challengeItemDto, ...formData, mapKmlUrl: KMLUrl, distance: distance, milestones: formData.milestones}};
        }
    };

    const handleNextScreen = () => {
        schema.validate({
            ...formData,
            KMLUrl: KMLUrl, 
            distance: distance,
            milestones: milestones
        }, {abortEarly: false}).then(res => {
                setFormErrors({
                    hasMilestone: '',
                    totalMilestone: '',
                    milestones: '',
                    KMLUrl: '', 
                    distance: '',
                    distanceUnit: ''
                });

                let finalData = getFinalData();    
            
                let {ArchiveChallengeId} = (challengeData as any);

                if(ArchiveChallengeId)
                {
                    dispatch(updateArchiveChallenge(finalData)).then(response => {
                        setUploadResponse(response.payload);
                        setOpen(true);
                        setChallengeData(finalData);
                        moveToNextScreen("releaseDetails");
                    });
                }
                else
                {
                    dispatch(createNewArchiveChallenge(finalData)).then(response => {
                        setUploadResponse(response.payload);
                        setOpen(true);
                        setChallengeData({...finalData, ArchiveChallengeId: response.payload.details.archiveChallengeId});
                        moveToNextScreen("releaseDetails");
                    });
                }   
            }).catch(error => {
                const errors = error.inner.reduce((acc: any, error: any) => {
                    return {
                    ...acc,
                    [error.path]: error.errors[0],
                    }
                }, {});
                setFormErrors({...errors});
        });
    };

    const handleSavePage = () => {
        schema.validate({
            ...formData,
            KMLUrl: KMLUrl, 
            distance: distance, 
            milestones: formData.milestones
        }, {abortEarly: false}).then(res => {
                setFormErrors({
                    hasMilestone: '',
                    totalMilestone: '',
                    milestones: '',
                    KMLUrl: '', 
                    distance: '',
                    distanceUnit: ''
                });

                let finalData = getFinalData();

                let {ArchiveChallengeId} = (challengeData as any);
                if(ArchiveChallengeId)
                {
                    dispatch(updateArchiveChallenge(finalData)).then(response => {
                        setUploadResponse(response.payload);
                        setOpen(true);
                        setTimeout(() => {
                            navigate('/archived-challenges');
                        }, 500);
                    });
                }
                else
                {
                    dispatch(createNewArchiveChallenge(finalData)).then(response => {
                        setUploadResponse(response.payload);
                        setOpen(true);
                        setTimeout(() => {
                            navigate('/archived-challenges');
                        }, 500);
                    });
                }
            }).catch(error => {
                const errors = error.inner.reduce((acc: any, error: any) => {
                    return {
                    ...acc,
                    [error.path]: error.errors[0],
                    }
                }, {});
                console.log("Error: ",errors);
                setFormErrors({...errors});
        });
    };

    let schema = yup.object().shape({
        hasMilestone: yup.boolean(),
        totalMilestone: yup.number().when('hasMilestone', {
            is: true,
            then: (schema) => schema.required("Please enter the number of milestones").min(1, "Atleast 1 milestone is required"),
            otherwise: (schema) => schema.notRequired(),
        }),
        milestones: yup.array().when('hasMilestone', {
            is: true,
            then : (schema) => schema.required("Please enter milestones data").min(1, "Atleast one milestone must be added"),
            otherwise: (schema) => schema.notRequired(),
        }),
        KMLUrl: yup.string().required("Please upload the map"), 
        distance: yup.number().required().moreThan(0, "Please upload map to calculate distance"),
        distanceUnit: yup.string().required("Please select distance unit")
    });

    let validateField = (fieldName: string, value: any) => {
        schema.validateAt(fieldName, {[fieldName]: value}, {abortEarly: false})
        .then(res => setFormErrors({...formErrors, [fieldName]: ''}))
        .catch((error: any) => {
            const errors = error.inner.reduce((acc: any, error: any) => {
                return {
                ...acc,
                [error.path]: error.errors[0],
                }
            }, {});
            setFormErrors({...formErrors, ...errors});
        });
    };

    const handleSkipChallenge = () => {
        schema.validate({
            ...formData,
            KMLUrl: KMLUrl, 
            distance: distance,
            milestones: milestones
        }, {abortEarly: false}).then(res => {
                setFormErrors({
                    hasMilestone: '',
                    totalMilestone: '',
                    milestones: '',
                    KMLUrl: '', 
                    distance: '',
                    distanceUnit: ''
                });

                let finalData = getFinalData();    
            
                let {ArchiveChallengeId} = (challengeData as any);

                if(ArchiveChallengeId && challengeData.isArchiveEditMode)
                {
                    dispatch(updateArchiveChallenge(finalData)).then(response => {
                        setUploadResponse(response.payload);
                        setOpen(true);
                        if(challengeData.currentChallengeCount < challengeData.totalChallengeCount)
                        {
                            moveToNextScreen('challengeDetails');
                            setChallengeData({...finalData, currentChallengeCount: challengeData.currentChallengeCount + 1});
                        }
                        else
                        {
                            setTimeout(() => {
                                navigate('/archived-challenges');
                            }, 500);
                        }
                    });
                }
            }).catch(error => {
                const errors = error.inner.reduce((acc: any, error: any) => {
                    return {
                    ...acc,
                    [error.path]: error.errors[0],
                    }
                }, {});
                setFormErrors({...errors});
            });
    };

    return (
        <>
            <div className="milestone-details-container">
                <ChallengeHeader handleSkipChallenge={handleSkipChallenge} />
                <Row>
                    <Col xs={24} sm={24} md={24} lg={12} xl={10} className="map-details-container">
                        <Row>			
                            <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                <Row style={{marginBottom:'1.5em'}}>
                                    <Title className="map-details-label">Category</Title>
                                    <Title className="map-details-label-bold">{mapDetails.category}</Title>
                                </Row>
                                <Row style={{marginBottom:'1.5em'}}>
                                    <Title className="map-details-label">Difficulty</Title>
                                    <Title className="map-details-label-bold">{mapDetails.difficulty}</Title>
                                </Row>
                                <Row style={{marginBottom:'1.5em'}}>
                                    <Title className="map-details-label">Milestone</Title>
                                    <Title className="map-details-label-bold">{formData.totalMilestone}Pc</Title>
                                </Row>
                                <Row style={{marginBottom:'1.5em'}}>
                                    <Title className="map-details-label">Design</Title>
                                    <Title className="map-details-label-bold">{mapDetails.design}</Title>
                                </Row>
                                <Row style={{marginBottom:'1.5em'}}>
                                    <Title className="map-details-label">Distance</Title>
                                    <Title className="map-details-label-bold">{formData.distanceUnit}</Title>
                                </Row>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={16} xl={16}>
                                <GoogleMapsComponent className="map-container" KMLUrl={KMLUrl} APIKey={GoogleMapsAPIKey} mapCenter={mapCenter} handleMapUpload={handleMapUpload} handleRemoveMap={handleRemoveMap} handleMarkerData={handleMarkerData} handlePolyData={handlePolyData} handleOverlayComplete={handleOverlayComplete} />
                                {formErrors.KMLUrl ? <Title level={5} className="error-label" type="danger">{formErrors.KMLUrl}</Title> : ''}
                                <a href="https://www.google.dk/maps/d/u/0/" target="_blank" className="create-kml-url">Create KML File</a>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={12} xl={14} className="milestones-form">
                        <div className={formErrors.milestones ? 'mb-0' : 'mb-1'}>
                            <Row>			
                                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                    <Title level={5} className="input-label">Milestone</Title>
                                    <InputNumber addonBefore={selectBefore} type="number" className="input-field milestone" value={formData.totalMilestone ? formData.totalMilestone : 1} min={1} onChange={(e: any) => handleInputChange("totalMilestone", e)} disabled={formData.hasMilestone === false ? true : false }/>
                                </Col>

                                {
                                    formData.hasMilestone !== false &&
                                    <Col xs={24} sm={24} md={12} lg={12} xl={8} className="add-btn-container">
                                        <ButtonComponent title="Add Now" className="add-btn" onClick={handleAddMilestones} />
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col xs={24} sm={24} md={6} lg={8} xl={8}>
                                    {formErrors.milestones ? <Title level={5} className="error-label" type="danger">{formErrors.milestones}</Title> : ''}        
                                </Col>                    
                            </Row>
                        </div>

                        <Row gutter={[20, 0]} className={formErrors.distance || formErrors.distanceUnit ? 'mb-1' : 'mb-2'}>			
                            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <Title level={5} className="input-label">Distance</Title>
                                <InputField placeholder="Enter Distance" name="finishHeader" value={distance ? distance : null} type="number" autoComplete="false" className="input-field finish-header" disabled={true} />
                                {formErrors.distance ? <Title level={5} className="error-label" type="danger">{formErrors.distance}</Title> : ''}
                                
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                                <Title level={5} className="input-label">Distance Unit</Title>
                                <SelectComponent className="input-select" value={formData.distanceUnit ? formData.distanceUnit : null} onChange={(e) => handleInputChange("distanceUnit", unitList[e])} list={unitList} placeholder="Select" selectAll={false} isOptionGroup={false}/>
                                {formErrors.distanceUnit ? <Title level={5} className="error-label" type="danger">{formErrors.distanceUnit}</Title> : ''}
                            </Col>
                        </Row>

                        {
                            formData.milestones?.map((milestone:any, index: number) => {
                                return (
                                    <Row className='milestone-card'>			
                                        <Col xs={10} sm={10} md={4} lg={4} xl={4} className="image-container">
                                            <img src={milestone.milestoneBase64Image} className="image" height="80" width="80" />
                                        </Col>
                                        <Col xs={10} sm={10} md={18} lg={18} xl={18}>
                                            <Title level={4}>{milestone.milestoneByLangList[0]?.milestoneName}</Title>
                                        </Col>
                                        <Col xs={4} sm={4} md={2} lg={2} xl={2}>
                                            <img src={EditIcon} alt="Edit Icon" onClick={() => handleEditMilestone(index)} className="edit-icon" />
                                        </Col>
                                    </Row>
                                )
                            })
                        }

                        <Row className="buttons-container">			
                            <ButtonComponent title="Save & Next" className="next-btn" onClick={handleNextScreen} />  
                            {/* <ButtonComponent title="Save" className="save-btn" onClick={handleSavePage} />                        */}
                        </Row>
                    </Col>
                </Row>

                <MilestoneModal 
                    isMilestoneFormVisible={isMilestoneFormVisible} 
                    setMilestoneFormVisible={setMilestoneFormVisible} 
                    milestoneData={milestones}
                    setMilestoneData={setMilestones}
                    isEditMode={isEditMode}
                    editIndex={editIndex}
                    setEditMode={setEditMode} 
                    totalMilestone={formData.totalMilestone ? formData.totalMilestone : 1}
                    handleSaveMilestones={handleSaveMilestones} 
                />
                <Notification 
                open={open} 
                setOpen={setOpen} 
                title={uploadResponse?.status === "SUCCESS" ? "Success" : "Error"} 
                message={uploadResponse?.successMessage ? uploadResponse.successMessage : uploadResponse?.errors ? uploadResponse.errors[0] : "Something went wrong, Please try again."} 
                severity={uploadResponse?.status === "SUCCESS" ? "success" : "error"} 
                />
                
            </div>
            {isLoading && <LoadingSpinner />}
        </>
        
    )
};

export default MilestoneDetails;