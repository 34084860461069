import { Col, Row, Typography, message, Upload, InputNumber, Select, Input, Button, Modal } from 'antd';
import { useEffect, useState, useContext } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ButtonComponent from '../../components/button/button';
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import './challenges.css';
import TableComponent from "../../components/table/table";
import { challengeColumnTypes } from "../../models/componentTypes/types";
import { ColumnsType } from "antd/lib/table";
import ModalComponent from "../../components/modal/modal";
import { challengeContext } from "../../App";
import { getAllChallenges, unpublishChallenge } from "../../features/challenges/challenges";
import EditIcon from '../../assets/icons/edit.svg';
import { getChallengeDetailsById } from '../../features/challenges/challenges';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import DeleteIcon from "../../assets/icons/closeIcon.svg";

const { Title } = Typography;
const { Option } = Select;
const Search = Input.Search;

function Challenges() {
  const dispatch = useAppDispatch();
  const {token} = useAppSelector((state) => state.message);
  const [options, setOptions] = useState<string[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [difficultyList, setDifficultyList] = useState<string[]>([]);
  const [designList, setDesignList] = useState<string[]>([]);
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [designType, setDesignType] = useState(0);
  const [isSeriesChallenge, setSeriesChallenge] = useState(false);
  const [challengeCount, setChallengeCount] = useState(1);
  const [isSeriesModalVisible, setSeriesModalVisible] = useState(false);
  const { challengeData, setChallengeData } = useContext(challengeContext);
  const challenges: challengeColumnTypes[] = useAppSelector((state) => state.challenges.challenges);
  const { isLoading } = useAppSelector((state) => state.challenges);
  const [formErrors, setFormErrors] = useState({
    isSeriesChallenge: '',
    challengeCount: ''
  });
  const [challengeList, setChallengeList] = useState<any>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteChallengeId, setDeleteChallengeId] = useState('');

  useEffect(() => {
    setOptions(["Test Option 1", "Test Option 2"]);
    setCategoryList(["Standing", "Combi", "Walking", "Multi"]);
    setDifficultyList(["Beginner", "Medium", "Experienced"]);
    setDesignList(["Map", "Template"]);
  }, []);

  useEffect(() => {
    dispatch(getAllChallenges());
  }, [token]);

  let handleRedirect = () => {
    navigate("/archived-challenges");
  };

  let handleCreateChallenge = () => {
    setSeriesModalVisible(true);
  };

  const handleSeriesFormCancel = () => {
    setSeriesModalVisible(false);
  };

  useEffect(() => {
    setChallengeList(challenges);
  }, [challenges]);
  
  const handleSaveSeriesForm = () => {

    schema.validate({isSeriesChallenge: isSeriesChallenge, challengeCount: challengeCount}, {abortEarly: false})
    .then((response: any) => { 
      setChallengeData({
        isSeriesChallenge: isSeriesChallenge,
        currentChallengeCount: 1,
        totalChallengeCount: challengeCount,
        challengeItemDto: {},
        challengeItemList: [],
        hasMultipleReleaseDates: false
      });
      navigate("/create-challenge");
      setFormErrors({
        isSeriesChallenge: '',
        challengeCount: ''
      });
    })
    .catch((error: any) => {
      const errors = error.inner.reduce((acc: any, error: any) => {
        return {
          ...acc,
          [error.path]: error.errors[0],
        }
      }, {});
      console.log("Error: ", errors);
      setFormErrors({...formErrors, ...errors});
    });
  };

  const handleOptionChange = (e: any) => {
    if(e === 'Yes')
    {
      setSeriesChallenge(true);
      setChallengeCount(2);
    }
    else
    {
      setSeriesChallenge(false);
      setChallengeCount(1);
    }
    validateField(schema, "challengeCount", challengeCount);
  };

  const selectBefore = (
    <Select defaultValue="No" onChange={handleOptionChange} style={{ width: 90 }}>
        <Option value="Yes">Yes</Option>
        <Option value="No">No</Option>
    </Select>
  );

  const handleEditChallenge = (record: any) => {
    let params = {};

    if(record.isSeriesChallenge)
    {
      params = {
        SeriesChallengeId: record.seriesChallengeId
      };
    }
    else
    {
      params = {
        ChallengeId: record.challengeId
      };
    }
    
    dispatch(getChallengeDetailsById(params)).then(res => {
      setChallengeData({...challengeData, ...res.payload, isEditMode: true, isArchiveEditMode: false, challengeItemDto: res.payload.challengeResponse});
      navigate('/create-challenge');
    });
  };

  const columns: ColumnsType<challengeColumnTypes> = [
    {
      title: 'Challenge Title',
      dataIndex: 'challengeName',
      key: 'challengeName'
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      filters: [
        {
          text: 'Biking',
          value: 'Biking',
        },
        {
          text: 'Combi',
          value: 'Combi',
        },
        {
          text: 'Multi',
          value: 'Multi',
        },
        {
          text: 'Standing',
          value: 'Standing',
        },
        {
          text: 'Stepping',
          value: 'Stepping',
        },
        {
          text: 'Walking',
          value: 'Walking',
        }
      ],
      filterMode: 'tree',
      onFilter: (value: any, record: any) => record.category.toLowerCase().startsWith(value.toLowerCase())
    },
    {
      title: 'Design',
      dataIndex: 'challengeDesign',
      key: 'challengeDesign'
    },
    {
      title: 'Difficulty',
      dataIndex: 'difficulty',
      key: 'difficulty',
      filters: [
        {
          text: 'Beginner',
          value: 'Beginner',
        },
        {
          text: 'Medium',
          value: 'Medium',
        },
        {
          text: 'Expert',
          value: 'Expert',
        }
      ],
      filterMode: 'tree',
      onFilter: (value: any, record: any) => record.difficulty.toLowerCase().startsWith(value.toLowerCase())
    },
    {
      title: '',
      key: 'edit',
      dataIndex: 'edit',
      render: (text, record: any, index) => {
        return (
          <>
            <img src={EditIcon} alt="Edit Icon" onClick={() => handleEditChallenge(record)} className="edit-icon" />
            <img src={DeleteIcon} alt='Delete' style={{marginLeft:`0.5rem`}} onClick={() => handleConfirmUnpublishedChallenge(record.challengeId)} className="edit-icon" />
          </>
          
        )
      },
    },
  ];

  let schema = yup.object().shape({
    isSeriesChallenge: yup.boolean(),
    challengeCount: yup.number().when('isSeriesChallenge', {
      is: true,
      then: (schema) => schema.min(2, "Challenge count must be atleast 2 for series challenge"),
      otherwise: (schema) => schema.min(1, "Challenge count cannot be less than 1"),
    })
  });

  const validateField = (schemaName: any, name: any, value: any) => {
    schemaName.validate({[name]: value}, {abortEarly: false})
    .then((response: any) => { setFormErrors({...formErrors, [name]: ""});})
    .catch((error: any) => {
      const errors = error.inner.reduce((acc: any, error: any) => {
        return {
          ...acc,
          [error.path]: error.errors[0],
        }
      }, {})
      setFormErrors({...formErrors, ...errors});
    })
  };

  let onSearch = (searchText: any) => {
    const filteredChallenges = challengeList.filter(({ challengeName }) => {
      challengeName = challengeName.toLowerCase();
      return challengeName.includes(searchText.toLowerCase());
    });

    if(searchText)
      setChallengeList(filteredChallenges);
    else
      setChallengeList(challenges);
  };

  const handleConfirmUnpublishedChallenge = (challengeId: string) => {
    setDeleteChallengeId(challengeId);
    setShowDeleteModal(true);
  }

  const handleUnpublishedChallenge = () => {
    setShowDeleteModal(false);
    setDeleteChallengeId('');
    dispatch(unpublishChallenge(deleteChallengeId)).then(res => {
      if(res.payload)
      {
        //message.success("Challenge unpublished successfully");
        dispatch(getAllChallenges());
      }
      else
      {
        message.error("Error unpublishing challenge");
      }
    });
  }

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
    setDeleteChallengeId('');
  }

  return (
    <>
      { isLoading && <LoadingSpinner /> }
      <div className="challenges-container">
          <Row className="challenges-header">
            <Title level={2} className="title">Challenges</Title>
            <div className="search-container">
              <Search
                placeholder="Enter Challenge Title"
                className="search-box"
                onSearch={onSearch}
                style={{ width: 200 }}
              />
              <ButtonComponent title="Archived Challenges" className="archived-challenge-btn" onClick={handleRedirect} />
              <ButtonComponent title="Create New Challenge" className="create-btn" onClick={handleCreateChallenge} />
            </div>
          </Row>  

          <ModalComponent 
            title={"Series Challenge"} 
            width={700} 
            closable={false}
            visible={isSeriesModalVisible} 
            onOk={(e) => console.log("Form Submitted: ",e)} 
            onCancel={handleSeriesFormCancel}
            className="series-details-container"
            footer={[
                <ButtonComponent type="primary" className="submit-btn" title="Submit" onClick={handleSaveSeriesForm} />,
                <Button onClick={handleSeriesFormCancel}>
                  Cancel
                </Button>
            ]}
          >
            <Row>
              <Col xs={24} sm={24} md={6} lg={8} xl={15}>
                <Title level={5} className="input-label">Is Series Challenge?</Title>
                <InputNumber addonBefore={selectBefore} type="number" className="series-challenge-input" value={challengeCount ? challengeCount : challengeData?.isSeriesChallenge ? 2 : 1} defaultValue={challengeData?.isSeriesChallenge ? 2 : 1} min={challengeData?.isSeriesChallenge ? 2 : 1} onChange={(e: any) => setChallengeCount(e)} disabled={!isSeriesChallenge} />
                {formErrors.challengeCount ? <Title level={5} className="error-label" type="danger">{formErrors.challengeCount}</Title> : ''}
              </Col>
            </Row>
          </ModalComponent>

          <ModalComponent
            title = "Delete Challenge"
            visible = {showDeleteModal}
            closable = {false}
            onOk = {handleUnpublishedChallenge}
            onCancel={handleCancelDelete}
            footer={[
              <Button onClick={handleCancelDelete}>
                Cancel
              </Button>,
              <ButtonComponent type="primary" className="submit-btn" title="Delete" onClick={handleUnpublishedChallenge} />
            ]}
          >
            <Row>
              <Col xs={24} sm={24} md={6} lg={8} xl={15}>
                <Title level={5} className="input-label">Are you sure you want to delete the selected challenge?</Title>
              </Col>
            </Row>
          </ModalComponent>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:'right'}}>
              <TableComponent Data={challengeList} columns={columns} className="challenges-table"/>
            </Col>
          </Row>
      </div>
    </>
  )
};

export default Challenges;