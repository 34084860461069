import { useContext, useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { challengeContext } from '../../../../App';
import ButtonComponent from '../../../../components/button/button';

const { Title } = Typography;

const ChallengeHeader: any = ({
    handleSkipChallenge
}) => {   
    const { challengeData, setChallengeData } = useContext(challengeContext);

    return (
        <div className="challenge-header">
            <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={24}>
                    <Title level={2} className="title challenge-header" style={{display:'inline'}}>Create New Challenge</Title>
                    <Title level={4} className="title challenge-count" style={{display:'inline'}}> (Challenge: {challengeData?.currentChallengeCount}/{challengeData?.totalChallengeCount})</Title>
                    {(challengeData.isSeriesChallenge && (challengeData.isEditMode || challengeData.isArchiveEditMode)) && <ButtonComponent title="Next" className="next-btn" onClick={handleSkipChallenge} />}
                </Col>
            </Row>
        </div>
    )
};

export default ChallengeHeader;