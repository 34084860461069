import { useEffect, useState } from 'react';
import NotificationTemplate from './notificationTemplate/notificationTemplate';
import NotificationDetails from './notificationDetails/notificationDetails';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { saveNotificationTemplate } from '../../features/notificationTemplate/notificationTemplate';
import Notification from '../../components/notification/notification';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import { useParams } from 'react-router-dom';

function CreateNotificationTemplate() {
    const { id } = useParams();
    const [notificationTemplateFlowIndex, setNotificationTemplateFlowIndex] = useState<null | number>(null);
    const [isNotificationTemplateVisible, setNotificationTemplateVisible] = useState(true);
    const [flowData, setFlowData] = useState<any>({
        TemplateName: '',
        ConnectToQrCode: false,
        Templates: [],
        Id: null
    });
    let dispatch = useAppDispatch();
    const [uploadResponse, setUploadResponse] = useState({status:'', successMessage:'', errors:[]});
    const [open, setOpen] = useState(false);
    const { isLoading } = useAppSelector((state) => state.notificationTemplate);

    let changeVisibility = (visibility: boolean) => {
        setNotificationTemplateVisible(visibility);
    }
    const notificationTempaltes = useAppSelector((state) => state.notificationTemplate.notificationTemplates);

    const selectedTemplate = notificationTempaltes && notificationTempaltes.find((template) => template.id === id);
    
    useEffect(() => {
        if(selectedTemplate) {
            
            setFlowData({
                TemplateName: selectedTemplate.templateName,
                ConnectToQrCode: selectedTemplate.connectToQrCode,
                Templates: selectedTemplate.templates,
                Id: selectedTemplate.id
            });
        }
    },[selectedTemplate])

    let saveQRConnectedTemplate = () => {
        dispatch(saveNotificationTemplate(flowData)).then(response => {
            setUploadResponse(response.payload);
            setOpen(true);
            setFlowData({
                TemplateName: '',
                ConnectToQrCode: false,
                Templates: [],
                Id: null
            });
        })
    };

    useEffect(() => {
        console.log("id",id);
    },[id])

    const setTemplateIndex = (index) => {
        console.log("setTemplateIndex",index);
        setNotificationTemplateFlowIndex(index);
    }

    return (
        <div>
            {
                isNotificationTemplateVisible ? 
                    <NotificationTemplate 
                        setTemplateIndex={setTemplateIndex} 
                        setNotificationTemplateVisibilty={changeVisibility} 
                        flowData={flowData} setFlowData={setFlowData} 
                        saveTemplate={saveQRConnectedTemplate} /> : 
                    <NotificationDetails 
                        notificationTemplateFlowIndex={notificationTemplateFlowIndex} 
                        flowData={flowData} 
                        setFlowData={setFlowData} 
                        setNotificationTemplateVisibilty={changeVisibility}/>
            }

            { isLoading && <LoadingSpinner /> }
            
            <Notification 
                open={open} 
                setOpen={setOpen} 
                title={uploadResponse?.status === "SUCCESS" ? "Success" : "Error"} 
                message={uploadResponse?.successMessage ? uploadResponse.successMessage : uploadResponse?.errors ? uploadResponse.errors[0] : "Something went wrong, Please try again."} 
                severity={uploadResponse?.status === "SUCCESS" ? "success" : "error"} 
            />
        </div>
    )
};

export default CreateNotificationTemplate;