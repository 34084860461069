import { Col, Input, Popconfirm, Row } from "antd";
import Title from "antd/lib/typography/Title";
import "./UserList.css";
import { ColumnsType } from "antd/lib/table";
import { UserTableColumns } from "../../models/componentTypes/types";
import TableComponent from "../../components/table/table";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { getAllUsers } from "../../features/common/common";
import { deleteUser } from "../../features/QRCodes/QRCodes";
import { DeleteOutlined } from "@ant-design/icons";
import LoadingSpinner from "../../components/loadingSpinner/loadingSpinner";
import Notification from "../../components/notification/notification";

const Search = Input.Search;

const UserList = () => {
  const { token } = useAppSelector((state) => state.message);
  const { usersList } = useAppSelector((state) => state.common);
  const [users, setUsers] = useState([]);
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.common);
  const [deleteResponse, setDeleteResponse] = useState({
    status: "",
    successMessage: "",
    errors: [],
  });
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  const columns: ColumnsType<UserTableColumns> = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      sorter: (a, b) => (a.firstName > b.firstName ? 1 : -1),
      width: `10%`,
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      sorter: (a, b) => (a.lastName > b.lastName ? 1 : -1),
      width: `10%`,
    },
    {
      title: "Nickname",
      dataIndex: "nickname",
      key: "nickname",
      sorter: (a, b) => (a.nickname > b.nickname ? 1 : -1),
      width: `10%`,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      defaultSortOrder: "ascend",
      sorter: (a, b) => (a.email > b.email ? 1 : -1),
      width: `20%`,
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
    },
    {
      title: "Access Level",
      dataIndex: "accessLevel",
      key: "accessLevel",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
      sorter: (a, b) => (a.company > b.company ? 1 : -1),
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      sorter: (a, b) => (a.department > b.department ? 1 : -1),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <div>
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => handleDeleteUser(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <div className="delete-button">
              <DeleteOutlined />
            </div>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleDeleteUser = (id: string) => {
    //dispatch delete user and then get all users
    dispatch(deleteUser(id))
      .then(() => {
        dispatch(getAllUsers()).then(() => {
          setDeleteResponse({
            status: "SUCCESS",
            successMessage: "User deleted successfully.",
            errors: [],
          });
          setOpen(true);
        });
      })
      .catch((response) => {
        setDeleteResponse({
          status: "ERROR",
          successMessage: "",
          errors: response.errors,
        });
        setOpen(true);
      });
  };

  const onSearch = (value: string) => {
    setSearchText(value);
  };

  useEffect(() => {
    dispatch(getAllUsers());
  }, [token]);

  useEffect(() => {
    const filterUsers = usersList.filter((user) => {
      return (
        user.nickname.toLowerCase().includes(searchText.toLowerCase()) ||
        user.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        user.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email.toLowerCase().includes(searchText.toLowerCase()) ||
        user.userType.toLowerCase().includes(searchText.toLowerCase()) ||
        user.accessLevel.toLowerCase().includes(searchText.toLowerCase()) ||
        user.company.toLowerCase().includes(searchText.toLowerCase()) ||
        user.department.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    if (searchText === "") {
      setUsers(usersList);
    } else {
      setUsers(filterUsers);
    }
  }, [usersList, searchText]);

  return (
    <>
      <div className="user-list-container">
        <Row style={{ justifyContent: `space-between` }}>
          <div>
            <Title level={2} style={{ fontWeight: `700` }}>
              Users
            </Title>
          </div>
          <div>
            <Search
              placeholder="Search"
              onSearch={onSearch}
              style={{ width: 200 }}
            />
          </div>
        </Row>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            style={{ textAlign: "right" }}
          >
            <TableComponent Data={users} columns={columns} className="" />
          </Col>
        </Row>
      </div>
      <Notification
        open={open}
        setOpen={setOpen}
        title={deleteResponse?.status === "SUCCESS" ? "Success" : "Error"}
        message={
          deleteResponse?.successMessage
            ? deleteResponse.successMessage
            : deleteResponse?.errors
            ? deleteResponse.errors[0]
            : "Something went wrong, Please try again."
        }
        severity={deleteResponse?.status === "SUCCESS" ? "success" : "error"}
      />
      {isLoading && <LoadingSpinner />}
    </>
  );
};

export default UserList;
