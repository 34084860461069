import { useEffect, useRef, useState, useContext, useCallback, memo, FC } from 'react';
import ModalComponent from '../../../../../components/modal/modal';
import UploadCard from '../../../../../components/cards/uploadCard';
import { Col, Row, Typography, DatePicker, TimePicker, InputNumber, Select, Button, Steps, Radio, Upload } from 'antd';
import InputField from '../../../../../components/inputField/inputField';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile } from 'antd/es/upload';
import SelectComponent from '../../../../../components/select/select';
import moment from 'moment';
import * as yup from 'yup';
import './stagesModal.css';

const { Step } = Steps;
const { Title } = Typography;
const { Option } = Select;

const StagesModal: FC<any> = ({
    isStageFormVisible,
    currentIndex,
    totalStages,
    editIndex,
    isEditMode,
    stageFileList,
    setStageFileList,
    isStageImageUploaded, 
    setStageImageUploaded,
    stageImage,
    setStageImage,
    handleStageFormCancel,
    handleSaveStage,
    handleModalData,
    stagesData,
    formErrors
  }) => {
    const categoryList = ['Distance', 'Calorie', 'Time', 'Repetition'];
    const unitList = ['KM', 'Miles'];
    const challengeForms = ['Walk', 'Bike', 'Step', 'Stand', 'Combi'];
    
    const getSteps = (totalStages: number) => {
        const steps:any = [];
        for(let i=0; i<totalStages; i++)
            steps.push(<Step key={i} className="step" />);
        return steps;
    };

    const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });

    const handleStageImageUpload = async (filesData: any) => {
        setStageFileList(filesData);

        let base64URL = await getBase64(filesData as RcFile);
        setStageImage(base64URL);
        handleModalData("MultiChallengeStageBase64Image", base64URL);
        setStageImageUploaded(true);
    };

    const onStageFileRemove = () => {
        setStageFileList([]);
        setStageImageUploaded(false);
        setStageImage('');
        handleModalData("MultiChallengeStageBase64Image", '');
    };

    return (
        <ModalComponent 
            title={isEditMode ? "Edit Stage" : "Add Stages"} 
            width={800} 
            closable={false}
            visible={isStageFormVisible} 
            onOk={(e) => console.log("Form Submitted: ",e)} 
            onCancel={handleStageFormCancel}
            className="stages-modal-container"
            footer={[
            <Button key="submit" type="primary" style={{width:'12em'}} onClick={handleSaveStage}>
                {isEditMode ? "Save Stage" : "Next"}
            </Button>,
            !isEditMode &&
            <Button key="back" onClick={handleStageFormCancel}>
                Cancel
            </Button>
            ]}
        >
            <Row>
            <Col xl={24}>
                <Steps current={currentIndex} className="steps-container">
                    {
                        getSteps(totalStages)
                    }
                </Steps>
            </Col>
            </Row>

            <Title className='input-label' style={{marginTop:'2em'}}>Stage {currentIndex + 1} Details</Title>

            <Row gutter={[34,0]} style={{marginTop:'1em'}}>  
            <Col xs={24} sm={24} md={6} lg={8} xl={8}>
                <Title level={5} className="input-label">Select Category</Title>
                <SelectComponent className={formErrors?.category ? "input-select nospace" : "input-select"} value={stagesData ? stagesData[currentIndex]?.category ? stagesData[currentIndex]?.category : null : null} onChange={(e) => handleModalData("category", categoryList[e])} list={categoryList} placeholder="Select" selectAll={false} isOptionGroup={false}/>
                {formErrors?.category ? <Title level={5} className="error-label" type="danger">{formErrors?.category}</Title> : ''}

                {
                    stagesData && stagesData.length > 0 && stagesData[currentIndex]?.category === "Distance" && 
                    <>
                        <Title level={5} className="input-label">Distance Unit</Title>
                        <SelectComponent className={formErrors.distanceUnit ? "input-select nospace" : "input-select"} value={stagesData[currentIndex]?.distanceUnit ? stagesData[currentIndex].distanceUnit : null} onChange={(e) => handleModalData("distanceUnit", unitList[e])} list={unitList} placeholder="Select" selectAll={false} isOptionGroup={false}/>
                        {formErrors.distanceUnit ? <Title level={5} className="error-label" type="danger">{formErrors.distanceUnit}</Title> : ''}

                        <Title level={5} className="input-label">Distance Value</Title>
                        <InputField placeholder="Enter Distance Value" value={stagesData[currentIndex]?.distance ? stagesData[currentIndex].distance : null} onChange={(e) => handleModalData("distance", e.target.value)} name="repetitionCount" type="number" autoComplete="false" className={formErrors.distance ? "input-field full-width nospace" : "input-field full-width"} />
                        {formErrors.distance ? <Title level={5} className="error-label" type="danger">{formErrors.distance}</Title> : ''}
                        {/* <Title level={5} className="input-label">Milestone</Title>
                        <InputNumber addonBefore={selectBefore} type="number" className="milestone-input" defaultValue={1} min={1} value={formData.stagesData[currentIndex].milestonesCount ? formData.stagesData[currentIndex].milestonesCount : 0} onChange={(e: any) => handleModalData("milestonesCount", e)} disabled={formData.stagesData[currentIndex].milestonesEnabled ? false : true}/>
                        {
                            formData.stagesData[currentIndex]?.milestonesEnabled &&
                            <ButtonComponent title="Add Milestones" className="add-milestones-btn" onClick={handleAddMilestones} />
                        } */}
                    </>
                }

                {
                    stagesData && stagesData.length > 0 && stagesData[currentIndex]?.category === "Time" && 
                    <>
                        <Title level={5} className="input-label">Hours, Minutes & Seconds</Title>
                        <TimePicker value={stagesData[currentIndex]?.TimeToCount ? moment(stagesData[currentIndex]?.TimeToCount) : null} onChange={(e) => handleModalData("TimeToCount", e?.toISOString())} format="HH:mm:ss" className={formErrors.TimeToCount ? "input-field nospace" : "input-field"} />
                        {formErrors.TimeToCount ? <Title level={5} className="error-label" type="danger">{formErrors.TimeToCount}</Title> : ''}
                    </>
                }

                {
                    stagesData && stagesData.length > 0 && stagesData[currentIndex]?.category === "Repetition" &&
                    <>
                        <Title level={5} className="input-label">No. of Repetitions</Title>
                        <InputField value={stagesData[currentIndex]?.RepetitionsToCount ? stagesData[currentIndex].RepetitionsToCount : null} placeholder="Enter No. of Repetitions" name="RepetitionsToCount" onChange={(e) => handleModalData("RepetitionsToCount", e.target.value)} type="number" autoComplete="false" className={formErrors.RepetitionsToCount ? "input-field full-width nospace" : "input-field full-width"}/>
                        {formErrors.RepetitionsToCount ? <Title level={5} className="error-label" type="danger">{formErrors.RepetitionsToCount}</Title> : ''}
                    </>
                }

                {
                    stagesData && stagesData.length > 0 && stagesData[currentIndex]?.category === "Calorie" &&
                    <>
                        <Title level={5} className="input-label">Enter Calories</Title>
                        <InputField placeholder="Enter Calories Count" name="CaloriesToBurn" value={stagesData[currentIndex]?.CaloriesToBurn ? stagesData[currentIndex].CaloriesToBurn : null} onChange={(e) => handleModalData("CaloriesToBurn", e.target.value)} type="number" autoComplete="false" className={formErrors.CaloriesToBurn ? "input-field full-width nospace" : "input-field full-width"} />
                        {formErrors.CaloriesToBurn ? <Title level={5} className="error-label" type="danger">{formErrors.CaloriesToBurn}</Title> : ''}                            
                    </>
                }

                {
                    stagesData && stagesData.length > 0 && stagesData[currentIndex]?.category !== "Repetition" &&
                    <>
                        <Title level={5} className="input-label">Challenge Form</Title>
                        <SelectComponent className={formErrors.challengeForm ? "input-select nospace" : "input-select"} value={stagesData[currentIndex]?.challengeForm ? stagesData[currentIndex].challengeForm : null} onChange={(e) => handleModalData("challengeForm", challengeForms[e])} list={challengeForms} placeholder="Select" selectAll={false} isOptionGroup={false}/>
                        {formErrors.challengeForm ? <Title level={5} className="error-label" type="danger">{formErrors.challengeForm}</Title> : ''}
                    </>
                } 
                                
            </Col>

            {/* {
                (formData.stagesData[currentIndex]?.category === "Distance") &&
                <Col xs={24} sm={24} md={6} lg={8} xl={16} className="maps-container">
                    <GoogleMapsComponent />
                </Col>
            } */}
            
            {
                stagesData && stagesData.length > 0 && (stagesData[currentIndex]?.category === "Time" || stagesData[currentIndex]?.category === "Repetition" || stagesData[currentIndex]?.category === "Calorie") &&
                <Col xs={24} sm={24} md={6} lg={8} xl={16} className="upload-card-container">
                    <UploadCard className="stages-upload-card" errorMessage={formErrors.MultiChallengeStageBase64Image} onChange={handleStageImageUpload} onClose={onStageFileRemove} fileList={stageFileList} isQRUploaded={isStageImageUploaded} previewImage={stageImage}/>
                </Col>
            }
            
            </Row>

        </ModalComponent>
    )
};

export default StagesModal;