import { Checkbox } from 'antd';
import { FC } from 'react';
import { CheckboxProps } from '../../models/componentTypes/types';

const CheckboxComponent: FC<CheckboxProps> = ({
  title,
  onChange,
  className,
  disabled,
  isCheckboxGroup,
  checkboxList,
  defaultValue,
  defaultChecked
}) => {
  return (
    isCheckboxGroup ? 
    <Checkbox.Group options={checkboxList} defaultValue={defaultValue} onChange={onChange} /> :
    <Checkbox onChange={onChange} defaultChecked={defaultChecked} disabled={disabled} className={className}>{title}</Checkbox>
  )
};

export default CheckboxComponent;