import { useState, useContext, useEffect } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { Col, Row, Typography, DatePicker } from 'antd';
import ButtonComponent from '../../../../components/button/button';
import SelectComponent from '../../../../components/select/select';
import './releaseDetails.css';
import RadioGroup from '../../../../components/radioGroup/radioGroup';
import { challengeContext } from '../../../../App';
import { useNavigate } from 'react-router-dom';
import { createNewChallenge, createNewArchiveChallenge, updateArchiveChallenge, updateChallenge } from '../../../../features/challenges/challenges';
import * as yup from  'yup';
import moment from 'moment';
import Notification from '../../../../components/notification/notification';
import ChallengeHeader from '../challengeHeader/challengeHeader';

const { Title } = Typography;

function ReleaseDetails({moveToNextScreen}: any) {   
    const { challengeData, setChallengeData } = useContext(challengeContext);
    const navigate = useNavigate();
    const [formData, setFormData] = useState<any>({
        IsCopyToLanguageEnabled: false,
        challengeAccessibleFor: 'Basic',
        hasMultipleReleaseDates: false,
        ChallengeReleaseDate: null
    });
    const [formErrors, setFormErrors] = useState<any>({});
    const dispatch = useAppDispatch();
    const [uploadResponse, setUploadResponse] = useState({status:'', successMessage:'', errors:[]});
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(challengeData.isSeriesChallenge)
        {
            let currentChallengeData = {...challengeData};
            let currentChallengeIndex = challengeData.currentChallengeCount-1;

            if(currentChallengeData.challengeItemList.length > 0 && Object.keys(currentChallengeData.challengeItemList[currentChallengeIndex]).length > 0 && (currentChallengeData.challengeItemList[currentChallengeIndex] as any).challengeRelease)
            {
                setFormData((currentChallengeData.challengeItemList[currentChallengeIndex] as any).challengeRelease);                
            }
        }
        else
        {
            setFormData({...formData, ...(challengeData.challengeItemDto as any).challengeRelease});
        }
    }, []);

    const handleReleaseChallenge = () => {
        schema.validate({
            isSeriesChallenge: challengeData.isSeriesChallenge,
            SeriesReleaseDate: challengeData.SeriesReleaseDate,
            ...formData
        }, {abortEarly: false})
        .then(res => {
            setFormErrors({});
            debugger;
            

            if(challengeData.isSeriesChallenge)
            {
                let isEditMode = false;

                for(let i=0; i<challengeData.challengeItemList.length; i++)
                {
                    if((challengeData.challengeItemList[i] as any).challengeId)
                    {
                        isEditMode = true;
                        break;
                    }
                }

                let currentChallengeIndex = challengeData.currentChallengeCount-1;
                let currentChallengeList: any = challengeData.challengeItemList;
                currentChallengeList[currentChallengeIndex] = {
                    ...currentChallengeList[currentChallengeIndex], 
                    challengeRelease: challengeData.hasMultipleReleaseDates ? formData : {...formData, ChallengeReleaseDate: null}
                };

                

                if (isEditMode) {
                    const payload = {
                        challengeResponse : {
                            challengeId: challengeData.challengeId,
                            ...challengeData.challengeItemDto
                        },
                        archiveChallengeId : (challengeData as any).ArchiveChallengeId,
                    }
                    dispatch(updateChallenge(payload)).then((response:any) => {
                        setUploadResponse(response.payload);
                        setOpen(true);
                        setTimeout(() => {
                            navigate("/challenges");
                        }, 500);
                    });
                }
                else {
                    dispatch(createNewChallenge({...challengeData, challengeItemList: currentChallengeList})).then((response:any) => {
                        setUploadResponse(response.payload);
                        setOpen(true);
                        setTimeout(() => {
                            navigate("/challenges");
                        }, 500);
                    });
                }

                
            }
            else
            {
                var isEditMode = challengeData.challengeId || (challengeData.challengeItemDto as any).challengeId  ? true : false;
                if(isEditMode) {
                    const payload = {
                        challengeResponse : {
                            challengeId: challengeData.challengeId,
                            ...challengeData.challengeItemDto,
                            challengeRelease: formData

                        },
                        archiveChallengeId : (challengeData as any).ArchiveChallengeId,
                    }
                    setChallengeData({...challengeData, challengeItemDto: {...challengeData.challengeItemDto, challengeRelease: formData}});
                    dispatch(updateChallenge(payload))
                        .then((response:any) => {
                        setUploadResponse(response.payload);
                        setOpen(true);
                        setTimeout(() => {
                            navigate("/challenges");
                        }, 500);
                    });
                }
                else {
                    setChallengeData({...challengeData, challengeItemDto: {...challengeData.challengeItemDto, challengeRelease: formData}});
                    dispatch(createNewChallenge({
                        ...challengeData, 
                        challengeItemDto: {...challengeData.challengeItemDto, challengeRelease: formData}
                    })).then((response:any) => {
                        setUploadResponse(response.payload);
                        setOpen(true);
                        setTimeout(() => {
                            navigate("/challenges");
                        }, 500);
                    });
                }                
                
            }
            
            // if(challengeData.isSeriesChallenge)
            // {
            //     let currentChallengeIndex = challengeData.currentChallengeCount-1;
            //     let currentChallengeList: any = challengeData.challengeItemList;
            //     currentChallengeList[currentChallengeIndex] = {
            //         ...currentChallengeList[currentChallengeIndex], 
            //         challengeRelease: challengeData.hasMultipleReleaseDates ? formData : {...formData, ChallengeReleaseDate: null}
            //     };

            //     if(challengeData.currentChallengeCount < challengeData.totalChallengeCount)
            //     {
            //         let {ArchiveChallengeId} = (challengeData as any);

            //         if(ArchiveChallengeId)
            //         {
            //             dispatch(updateArchiveChallenge({...challengeData, challengeItemList: currentChallengeList, currentChallengeCount: challengeData.currentChallengeCount+1})).then((response:any) => {
            //                 setChallengeData({...challengeData, challengeItemList: currentChallengeList, currentChallengeCount: challengeData.currentChallengeCount+1});
            //                 moveToNextScreen("challengeDetails");        
            //             }).catch(error => {
            //                 setChallengeData({...challengeData, challengeItemList: currentChallengeList, currentChallengeCount: challengeData.currentChallengeCount+1});
            //                 moveToNextScreen("challengeDetails"); 
            //             })
            //         }
            //         else
            //         {
            //             dispatch(createNewArchiveChallenge({...challengeData, challengeItemList: currentChallengeList, currentChallengeCount: challengeData.currentChallengeCount+1})).then((response:any) => {
            //                 setChallengeData({...challengeData, ArchiveChallengeId: response.payload.details.archiveChallengeId, challengeItemList: currentChallengeList, currentChallengeCount: challengeData.currentChallengeCount+1});
            //                 moveToNextScreen("challengeDetails");
            //             }).catch(error => {
            //                 setChallengeData({...challengeData, challengeItemList: currentChallengeList, currentChallengeCount: challengeData.currentChallengeCount+1});
            //                 moveToNextScreen("challengeDetails");
            //             })
            //         }
            //     }
            //     else
            //     {
            //         setChallengeData({...challengeData, challengeItemList: currentChallengeList});
            //         let finalData:any = {...challengeData};
            //         delete finalData.challengeItemDto;
            //         dispatch(createNewChallenge({...finalData, challengeItemList: currentChallengeList})).then((response:any) => {
            //             setUploadResponse(response.payload);
            //             setOpen(true);
            //             setTimeout(() => {
            //                 navigate("/challenges");
            //             }, 500);
            //         });
            //     }
            // }
            // else
            // {
                
            // }
        })
        .catch(error => {
            const errors = error.inner.reduce((acc: any, error: any) => {
                return {
                ...acc,
                [error.path]: error.errors[0],
                }
            }, {});
            console.log("Errors: ",errors);
            setFormErrors(errors);
        });
    };

    const getFinalData = () => {
        if(challengeData.isSeriesChallenge)
        {
            let currentChallengeIndex = challengeData.currentChallengeCount-1;
            let currentChallengeList: any = challengeData.challengeItemList;
            currentChallengeList[currentChallengeIndex] = {
                ...currentChallengeList[currentChallengeIndex], 
                challengeRelease: challengeData.hasMultipleReleaseDates ? formData : {...formData, ChallengeReleaseDate: null}
            };
            
            let finalData:any = {...challengeData, currentChallengeCount: challengeData.currentChallengeCount+1};
            delete finalData.challengeItemDto;

            return finalData;
        }
        else
        {
            return {...challengeData, challengeItemDto: {...challengeData.challengeItemDto, challengeRelease: formData}};
        }
    };

    const moveForward = () => {
        if(challengeData.isSeriesChallenge)
        {
            if(challengeData.currentChallengeCount < challengeData.totalChallengeCount)
            {
                moveToNextScreen("challengeDetails");
            }
            else
            {
                setTimeout(() => {
                    navigate('/archived-challenges');
                }, 500);
            }
        }
        else
        {
            setTimeout(() => {
                navigate('/archived-challenges');
            }, 500);
        }
    };

    const handleSaveChallenge = () => {
        schema.validate({
            isSeriesChallenge: challengeData.isSeriesChallenge,
            SeriesReleaseDate: challengeData.SeriesReleaseDate,
            ...formData
        }, {abortEarly: false})
        .then(res => {
            setFormErrors({});
            
            let finalData = getFinalData();

            let {ArchiveChallengeId} = (challengeData as any);

            if(ArchiveChallengeId)
            {
                dispatch(updateArchiveChallenge(finalData)).then((response:any) => {
                    setUploadResponse(response.payload);
                    setOpen(true);
                    setChallengeData(finalData);
                    moveForward();
                });
            }
            else
            {
                dispatch(createNewArchiveChallenge(finalData)).then((response:any) => {
                    setUploadResponse(response.payload);
                    setOpen(true);
                    setChallengeData(finalData);
                    moveForward();
                });
            }
        })
        .catch(error => {
            const errors = error.inner.reduce((acc: any, error: any) => {
                return {
                ...acc,
                [error.path]: error.errors[0],
                }
            }, {});
            console.log("Errors: ",errors);
            setFormErrors(errors);
        });
    };

    const handleSkipChallenge = () => {
        schema.validate({
            isSeriesChallenge: challengeData.isSeriesChallenge,
            SeriesReleaseDate: challengeData.SeriesReleaseDate,
            ...formData
        }, {abortEarly: false})
        .then(res => {
            setFormErrors({});
            
            let currentChallengeIndex = challengeData.currentChallengeCount-1;
            let currentChallengeList: any = challengeData.challengeItemList;
            currentChallengeList[currentChallengeIndex] = {
                ...currentChallengeList[currentChallengeIndex], 
                challengeRelease: challengeData.hasMultipleReleaseDates ? formData : {...formData, ChallengeReleaseDate: null}
            };
            
            let finalData:any = {...challengeData};
            delete finalData.challengeItemDto;

            let {ArchiveChallengeId} = (challengeData as any);

            if(ArchiveChallengeId && challengeData.isArchiveEditMode)
            {
                dispatch(updateArchiveChallenge(finalData)).then((response:any) => {
                    setUploadResponse(response.payload);
                    setOpen(true);

                    if(challengeData.currentChallengeCount < challengeData.totalChallengeCount)
                    {
                        moveToNextScreen('challengeDetails');
                        setChallengeData({...finalData, currentChallengeCount: challengeData.currentChallengeCount+1});
                    }
                    else
                    {
                        setTimeout(() => {
                            navigate('/archived-challenges');
                        }, 500);
                    }
                });
            }
        })
        .catch(error => {
            const errors = error.inner.reduce((acc: any, error: any) => {
                return {
                ...acc,
                [error.path]: error.errors[0],
                }
            }, {});
            setFormErrors(errors);
        });
    };

    const getTimePickerValue = (index: number) => {
        if(formData.hasMultipleReleaseDates || challengeData.hasMultipleReleaseDates)
        {
            if(index < challengeData.currentChallengeCount-1)
            {
                let {challengeRelease} = challengeData.challengeItemList[challengeData.currentChallengeCount-2];
                if(challengeRelease && Object.keys(challengeRelease).length > 0)
                {
                    let {ChallengeReleaseDate} = (challengeRelease as any);
                    return ChallengeReleaseDate ? moment(ChallengeReleaseDate) : undefined;
                }
            }
            else if(index === challengeData.currentChallengeCount-1)
                return formData.ChallengeReleaseDate ? moment(formData.ChallengeReleaseDate) : undefined;
            else
                return undefined;
        }
        else if(!formData.hasMultipleReleaseDates && challengeData.SeriesReleaseDate)
            return challengeData.SeriesReleaseDate ? moment(challengeData.SeriesReleaseDate) : undefined;
        else
            return undefined;
    };

    const getTimePickers = (timePickersCount: number) => {
        const timePickerElements:any = [];
        for(let i=0; i<timePickersCount; i++)
            timePickerElements.push(
                <div>
                    <Title level={5} className="input-label">Release Date {i+1}</Title>
                    <DatePicker showTime value={getTimePickerValue(i)} className={formErrors.ChallengeReleaseDate || formErrors.SeriesReleaseDate ? "release-date-picker nospace" : "release-date-picker"} onChange={(e) => handleInputChange("ChallengeReleaseDate", e?.toISOString())} disabled={challengeData.currentChallengeCount-1 === i ? false : true}/>
                    {formErrors.ChallengeReleaseDate && i+1===challengeData.currentChallengeCount && <Title level={5} className="error-label" type="danger">{formErrors.ChallengeReleaseDate}</Title>}
                    {formErrors.SeriesReleaseDate && <Title level={5} className="error-label" type="danger">{formErrors.SeriesReleaseDate}</Title>}
                </div>
            );
        return timePickerElements;
    };

    const handleInputChange = (name: string, value: any) => {        
        switch(name)
        {
            case "hasMultipleReleaseDates":
                setChallengeData({...challengeData, [name]: value, SeriesReleaseDate: null});
                setFormData({...formData, [name]: value, ChallengeReleaseDate: null});
                break;
            case "ChallengeReleaseDate":
                if(challengeData.hasMultipleReleaseDates)
                    setChallengeData({...challengeData, SeriesReleaseDate: null});  
                else if(!challengeData.hasMultipleReleaseDates)
                    setChallengeData({...challengeData, SeriesReleaseDate: value});

                setFormData({...formData, [name]: value});
                break;
            default:
                setFormData({...formData, [name]: value});
                break;
        }

        yup.reach(schema, name).validate(value)
        .then((res: any) => setFormErrors({...formErrors, [name]: ''}))
        .catch((error: any) => setFormErrors({...formErrors, [name]: error.message}));
    };

    let schema = yup.object().shape({
        isSeriesChallenge: yup.boolean(),
        hasMultipleReleaseDates: yup.boolean().when('isSeriesChallenge', {
            is: true,
            then : (schema) => schema.required("Please select if challenge has multiple release dates"),
            otherwise: (schema) => schema.notRequired(),
        }),
        ChallengeReleaseDate: yup.string().nullable().when(['isSeriesChallenge', 'hasMultipleReleaseDates'], {
            is: (isSeriesChallenge: any, hasMultipleReleaseDates: any) => {
                if(!isSeriesChallenge)
                    return true;
                else if(isSeriesChallenge && (hasMultipleReleaseDates || challengeData.hasMultipleReleaseDates))
                    return true;
                else
                    return false;
            },
            then : (schema) => schema.required("Challenge release date is required"),
            otherwise: (schema) => schema.notRequired(),
        }),
        SeriesReleaseDate: yup.string().nullable().when(['isSeriesChallenge', 'hasMultipleReleaseDates'], {
            is: (isSeriesChallenge: any, hasMultipleReleaseDates: any) => {
                if(isSeriesChallenge && !(hasMultipleReleaseDates || challengeData.hasMultipleReleaseDates))
                    return true;
                else
                    return false;
            },
            then : (schema) => schema.required("Series release date is required"),
            otherwise: (schema) => schema.notRequired(),
        }),
        IsCopyToLanguageEnabled: yup.boolean().required("Copy to lanuage is required"),
        challengeAccessibleFor: yup.string().required("Please select membership value")
    });

    return (
        <div>
            <ChallengeHeader handleSkipChallenge={handleSkipChallenge} />
            <Row className="release-details-container">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{marginTop:'1em'}}>
                    {
                        !challengeData.isSeriesChallenge &&
                        <>
                            <Title level={5} className="input-label">Release Date</Title>
                            <DatePicker showTime className={formErrors.ChallengeReleaseDate ? "release-date-picker nospace" : "release-date-picker"} value={formData.ChallengeReleaseDate ? moment(formData.ChallengeReleaseDate) : undefined} onChange={(e) => handleInputChange("ChallengeReleaseDate", e?.toISOString())} />
                            {formErrors.ChallengeReleaseDate && <Title level={5} className="error-label" type="danger">{formErrors.ChallengeReleaseDate}</Title>}
                        </>
                    }

                    {
                        challengeData.isSeriesChallenge &&
                        <>
                            <Title level={5} className="input-label">Release Date for Series Challenge</Title>
                            <SelectComponent className="release-challenge-dropdown" value={formData.hasMultipleReleaseDates?0:challengeData.hasMultipleReleaseDates? 0 : 1} list={['Yes', 'No']} onChange={(e) => handleInputChange("hasMultipleReleaseDates", e===0 ? true : false)} placeholder="Select" selectAll={false} isOptionGroup={false} disabled={challengeData.currentChallengeCount===1 ? false:true}/>

                            <div className={(formErrors.ChallengeReleaseDate || formErrors.SeriesReleaseDate) ? "release-date-container nospace" : "release-date-container"} >
                                {
                                    getTimePickers(formData.hasMultipleReleaseDates || challengeData.hasMultipleReleaseDates ? challengeData.totalChallengeCount : 1)
                                }                
                            </div>
                        </>
                    }

                    <div className="release-options-container">
                        <div style={{display:`none`}}>
                            <Title level={5} className="input-label">Copy to Language</Title>
                            <SelectComponent className="release-challenge-dropdown" list={['Yes', 'No']} placeholder="Select" value={formData.IsCopyToLanguageEnabled ? 0 : 1} onChange={(e) => handleInputChange("IsCopyToLanguageEnabled", e===0 ? true : false)} selectAll={false} isOptionGroup={false}/>
                        </div>      

                        <div style={{marginTop:'1.2em'}}>
                            <Title level={5} className="input-label">Challenge is Accessible For</Title>
                            <RadioGroup values={["Basic", "Premium"]} selectedValue={formData.challengeAccessibleFor ? formData.challengeAccessibleFor==="Basic" ? 0 : 1 : 0} onChange={(e: any) => handleInputChange("challengeAccessibleFor", e.target.value===0 ? "Basic" : "Premium")} direction="horizontal" hasChild={false} />
                        </div>         
                    </div>                     
                    
                    <Row className="buttons-container">
                        <ButtonComponent title="Save" className="next-btn" onClick={handleSaveChallenge} />  
                        {
                            (!challengeData.isSeriesChallenge || 
                            (challengeData.isSeriesChallenge && challengeData.currentChallengeCount === challengeData.totalChallengeCount)) && 
                            <ButtonComponent title="Confirm & Release" className="save-btn" onClick={handleReleaseChallenge} />
                        }      
                    </Row>                 
                </Col>
            </Row>
            <Notification 
              open={open} 
              setOpen={setOpen} 
              title={uploadResponse?.status === "SUCCESS" ? "Success" : "Error"} 
              message={uploadResponse?.successMessage ? uploadResponse.successMessage : uploadResponse?.errors ? uploadResponse.errors[0] : "Something went wrong, Please try again."} 
              severity={uploadResponse?.status === "SUCCESS" ? "success" : "error"} 
            />
        </div>
    )
};

export default ReleaseDetails;