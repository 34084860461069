import { AUTH_URL, CLIENT_ID, CLIENT_SECRET, SCOPE, GRANT_TYPE, CONTENT_TYPE } from "../configs/config";
const qs = require('qs');
const axios = require('axios');

const login = async (user: any) => {
  var data = qs.stringify({
    username: user.email, 
    password: user.password,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
    scope: SCOPE,
    grant_type: GRANT_TYPE
});
   var config = {
     method: 'post',
     url: AUTH_URL,
     headers: { 
       'Content-Type': CONTENT_TYPE
     },
     data : data
   };

   return axios(config);
};

const refreshToken = (token: any) => {
  var data = qs.stringify({
    client_id:"NativeAppClient",
    client_secret:"2bbc6933-8ed6-4e15-b06d-5c679ad07bd8",
    scope:"AccountsApi RegistrationApi ChallengesApi PaymentsApi offline_access VideoApi",
    grant_type:"refresh_token",
    refresh_token: token
});
   var config = {
     method: 'post',
     url: AUTH_URL,
     headers: { 
       'Content-Type': 'application/x-www-form-urlencoded'
     },
     data : data
   };
   return axios(config)
   .then(function (response: any) {
     return response;
   })
   .catch(function (error: any) {
     console.log(error);
     return error;
   });
}

const authService = {
    login,
    refreshToken
};
  
export default authService;