import { setMessage } from '../message/message';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../services/userService';

const initialState: any = {
  notificationTemplates: [],
  isLoading: false  
}

export const saveNotificationTemplate = createAsyncThunk(
  'notificationTemplate/saveNotificationTemplate',
  async (params: any, thunkAPI) => {
      try {
          const response = await userService.saveNotificationTemplate(params);      
          return response.data;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          await thunkAPI.dispatch(setMessage(error.response));
          const response = await userService.saveNotificationTemplate(params);
          return response.data;
        }
        else {
          thunkAPI.dispatch(setMessage(error.response));
          return error.response.data;
        }
      }
  }
)

export const getNotificationTemplates = createAsyncThunk(
  'notificationTemplate/getNotificationTemplates',
  async (params, thunkAPI) => {
      try {
          const response = await userService.getNotificationTemplates();      
          return response.data.details;
      } catch (error: any) {
        if (error.response && error.response.status === 401) {
          await thunkAPI.dispatch(setMessage(error.response));
          const response = await userService.getNotificationTemplates();
          return response.data.details;
        }
        else {
          thunkAPI.dispatch(setMessage(error.response));
          return error.response.data;
        }
      }
  }
)

const notificationTemplateSlice = createSlice({
  name: 'notificationTemplate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveNotificationTemplate.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(saveNotificationTemplate.fulfilled, (state, action) => {
      state.isLoading = false;
    })
    builder.addCase(saveNotificationTemplate.rejected, (state, action) => {
      console.log("Error Sending Notification: ", action.payload);
      state.isLoading = false;
    })
    builder.addCase(getNotificationTemplates.pending, (state, action) => {
      state.isLoading = true;
    })
    builder.addCase(getNotificationTemplates.fulfilled, (state, action) => {
      state.notificationTemplates = action.payload;
      state.isLoading = false;
    })
    builder.addCase(getNotificationTemplates.rejected, (state, action) => {
      console.log("Error fetching Notifications: ", action.payload);
      state.isLoading = false;
    })
  },
})

export default notificationTemplateSlice.reducer; 
