import { useEffect, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { FC } from 'react';
import './userDetailsForm.css';
import * as yup from 'yup';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import ButtonComponent from '../../../../components/button/button';
import InputField from '../../../../components/inputField/inputField';
import SelectComponent from '../../../../components/select/select';
import AutoCompleteComponent from '../../../../components/autoComplete/autoComplete';
import { createNewUser, updateUserData, getAllNicknames } from '../../../../features/QRCodes/QRCodes';
import Notification from '../../../../components/notification/notification';
import LoadingSpinner from '../../../../components/loadingSpinner/loadingSpinner';

const { Title } = Typography;

const UserDetailsForm: FC<any> = ({
  
}) => {
    const location = useLocation();
    const {token} = useAppSelector((state) => state.message);
    const {id} = useParams();
    const [isEditMode, setEditMode] = useState(false);
    const [formData, setFormData] = useState<any>({
        firstName: '',
        lastName: '',
        nickName: '',
        userType: '',
        companyId: null,
        companyName: '',
        departmentId: null,
        departmentName: ''
    });
    const [formErrors, setFormErrors] = useState<any>({});
    const departmentsList: any[] = useAppSelector((state) => state.QRCodes.departmentsList);
    const nickNamesList: any[] = useAppSelector((state) => state.QRCodes.nickNamesList);
    const { isLoading } = useAppSelector((state) => state.QRCodes);
    const [uploadResponse, setUploadResponse] = useState({status:'', successMessage:'', errors:[]});
    const [open, setOpen] = useState(false);
    const userTypeList = ["GetUpp", "OfficeFit"];
    const membershipList = ["Regular", "Premium"];
    const genderList = ["Male", "Female"];
    const languageList = ["English", "Danish"];
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    useEffect(() => {
        if(location.state)
        {    
            const {userDetails} = (location.state as any);
            
            if(id!=="new")
                setEditMode(true);

            setFormData({...formData, ...userDetails});
        }
        dispatch(getAllNicknames());
    }, [token]);

    let schema = yup.object().shape({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        nickName: yup.string().test('Already-Exists','User with this nickname already exists', function(nickName){
            let flag=true;
      
            if(nickNamesList.includes(nickName) && !isEditMode)
                flag=false;
      
            return flag;
        }).required("Nickname is required"),
        email: yup.string().required("Email is required"),
        password: yup.string().required("Password is required").min(6, "Password length must be atleast 6 characters"),
        gender: yup.string().required("Please select Gender"),
        language: yup.string().required("Please select Language"),
        userType: yup.string().required("Please select User Type"),
    });

    let editSchema = yup.object().shape({
        firstName: yup.string().required("First Name is required"),
        lastName: yup.string().required("Last Name is required"),
        departmentName: yup.string().required("Department name is required"),
        nickName: yup.string().test('Already-Exists','User with this nickname already exists', function(nickName){
            let flag=true;
      
            if(nickNamesList.includes(nickName) && !isEditMode)
                flag=false;
      
            return flag;
        }).required("Nickname is required")
    });

    let validateField = (schemaName: any, fieldName: string, value: any) => {
        schemaName.validateAt(fieldName, {[fieldName]: value}, {abortEarly: false})
        .then(res => setFormErrors({...formErrors, [fieldName]: ''}))
        .catch((error: any) => {
            const errors = error.inner.reduce((acc: any, error: any) => {
                return {
                ...acc,
                [error.path]: error.errors[0],
                }
            }, {});
            setFormErrors({...formErrors, ...errors});
        });
    };

    const handleInputChange = (name: string, value: any, record: any) => {
        let FD = {...formData, [name]: value};

        switch(name)
        {
            case 'departmentId':
                let deptName = departmentsList.filter(department => {
                    return department.id===value
                });
                FD = {...FD, [name]: value, departmentName: deptName[0].name};
                break;
        }

        if(isEditMode && name!=="departmentId")
            validateField(editSchema, name, value);
        else if(name!=="departmentId")
            validateField(schema, name, value);

        setFormData(FD);
    };

    const handleDepartmentSelect = (val, record) => {
        handleInputChange("departmentId", null, record);
    };

    const handleFormSubmit = () => {
        if(isEditMode)
        {
            editSchema.validate(formData, {abortEarly: false}).then(res => {
                dispatch(updateUserData({...formData, Id: formData.userId})).then(response => {
                    setUploadResponse(response.payload);
                    setOpen(true);
                    setTimeout(() => {
                        navigate('/qr-codes');
                    }, 500);
                })
            }).catch((error: any) => {
                const errors = error.inner.reduce((acc: any, error: any) => {
                    return {
                    ...acc,
                    [error.path]: error.errors[0],
                    }
                }, {});
                setFormErrors(errors);
            });
        }
        else
        {
            schema.validate(formData, {abortEarly: false}).then(res => {
                dispatch(createNewUser({...formData, Id: formData.userId, accessLevel: formData.membership})).then(response => {
                    setUploadResponse(response.payload);
                    setOpen(true);
                    setTimeout(() => {
                        navigate('/qr-codes');
                    }, 500);
                });
            }).catch((error: any) => {
                const errors = error.inner.reduce((acc: any, error: any) => {
                    return {
                    ...acc,
                    [error.path]: error.errors[0],
                    }
                }, {});
                setFormErrors(errors);
            });
        }
    };

    return (
        <>
            { isLoading && <LoadingSpinner /> }
            <div className="company-details-container">
                <Row className="header">
                    <Title level={2} className="title">User Details Form</Title>
                </Row>

                <Row>
                    <Col xs={24} sm={24} md={12} lg={10} xl={8}>
                        <Title level={5} className="input-label">First Name</Title>
                        <InputField placeholder="Enter First Name" name="firstName" type="text" value={formData?.firstName ? formData.firstName : null } autoComplete="false" onChange={(e) => handleInputChange("firstName", e.target.value, '')} className={formErrors.firstName ? "input-field nospace full-width" : "input-field full-width"}/>
                        {formErrors.firstName ? <Title level={5} className="error-label" type="danger">{formErrors.firstName}</Title> : ''}

                        <Title level={5} className="input-label">Last Name</Title>
                        <InputField placeholder="Enter Last Name" name="lastName" type="text" value={formData?.lastName ? formData.lastName : null} autoComplete="false" onChange={(e) => handleInputChange("lastName", e.target.value, '')} className={formErrors.lastName ? "input-field nospace full-width" : "input-field full-width"}/>
                        {formErrors.lastName ? <Title level={5} className="error-label" type="danger">{formErrors.lastName}</Title> : ''}

                        <Title level={5} className="input-label">Nickname</Title>
                        <InputField placeholder="Enter Nickname" name="nickName" type="text" value={formData?.nickName ? formData.nickName : null} autoComplete="false" onChange={(e) => handleInputChange("nickName", e.target.value, '')} className={formErrors.nickName ? "input-field nospace full-width" : "input-field full-width"} />
                        {formErrors.nickName ? <Title level={5} className="error-label" type="danger">{formErrors.nickName}</Title> : ''}

                        <Title level={5} className="input-label">E-Mail</Title>
                        <InputField placeholder="Enter E-Mail Address" name="email" type="text" value={formData?.email ? formData.email : null} autoComplete="false" onChange={(e) => handleInputChange("email", e.target.value, '')} className={formErrors.email ? "input-field nospace full-width" : "input-field full-width"} disabled={isEditMode ? true : false}/>
                        {formErrors.email ? <Title level={5} className="error-label" type="danger">{formErrors.email}</Title> : ''}

                        {
                            !isEditMode &&
                            <>
                                <Title level={5} className="input-label">Password</Title>
                                <InputField placeholder="Enter Password" name="password" type="password" value={formData?.password ? formData.password : null} autoComplete="false" onChange={(e) => handleInputChange("password", e.target.value, '')} className={formErrors.password ? "input-field nospace full-width" : "input-field full-width"} disabled={isEditMode ? true : false}/>
                                {formErrors.password ? <Title level={5} className="error-label" type="danger">{formErrors.password}</Title> : ''}
                            </>
                        }

                        <Title level={5} className="input-label">Company Name</Title>
                        <AutoCompleteComponent value={formData.companyName} placeholder="Enter Company Name" className={formErrors.companyName ? 'input-field full-width nospace' : 'input-field full-width'} onChange={(e) => handleInputChange("companyName", e, null)} disabled={true} />
                        {formErrors.companyName ? <Title level={5} className="error-label" type="danger">{formErrors.companyName}</Title> : ''}

                        <div style={{marginBottom:'1.2em'}}>
                            <Title level={5} className="input-label">Department Name</Title>
                            {/* <AutoCompleteComponent value={{key: formData.departmentId, value: formData.departmentName}} options={departmentsList} placeholder="Enter Department Name" className={formErrors.departmentName ? 'input-field full-width nospace' : 'input-field full-width'} onSelect={handleDepartmentSelect} onChange={(e) => handleInputChange("departmentName", e, null)} /> */}
                            <SelectComponent className="membership" list={departmentsList} value={formData?.departmentName ? formData.departmentName : null} onChange={(e) => handleInputChange("departmentId", e, null)} placeholder="Select Department" selectAll={false} isOptionGroup={false} hasSingleSelection={true} disabled={isEditMode ? false : true} />
                            {formErrors.departmentName ? <Title level={5} className="error-label" type="danger">{formErrors.departmentName}</Title> : ''}
                        </div>

                        {
                            !isEditMode &&
                            <Row gutter={[20, 0]} style={{marginBottom:'1.2em'}}>			
                                <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                                    <Title level={5} className="input-label">Gender</Title>
                                    <SelectComponent className="user-type" list={genderList} value={formData?.gender ? formData.gender : null} onChange={(e) => handleInputChange("gender", genderList[e], '')} placeholder="Select Gender" selectAll={false} isOptionGroup={false} />
                                    {formErrors.gender ? <Title level={5} className="error-label" type="danger">{formErrors.gender}</Title> : ''}
                                </Col>

                                <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                                    <Title level={5} className="input-label">Language</Title>
                                    <SelectComponent className="membership" list={languageList} value={formData?.language ? formData.language : null} onChange={(e) => handleInputChange("language", languageList[e], '')} placeholder="Select Language" selectAll={false} isOptionGroup={false} />
                                    {formErrors.language ? <Title level={5} className="error-label" type="danger">{formErrors.language}</Title> : ''}
                                </Col>
                            </Row>
                        }

                        <Row gutter={[20, 0]}>			
                            <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                                <Title level={5} className="input-label">User Type</Title>
                                <SelectComponent className="user-type" list={userTypeList} value={formData?.userType ? formData.userType : null} onChange={(e) => handleInputChange("userType", userTypeList[e], '')} placeholder="Select User Type" selectAll={false} isOptionGroup={false} disabled={isEditMode ? true : false} />
                                {formErrors.userType ? <Title level={5} className="error-label" type="danger">{formErrors.userType}</Title> : ''}
                            </Col>

                            <Col xs={24} sm={24} md={6} lg={8} xl={12}>
                                <Title level={5} className="input-label">Membership</Title>
                                <SelectComponent className="membership" list={membershipList} value={formData?.membership ? formData.membership : null} onChange={(e) => handleInputChange("membership", membershipList[e], '')} placeholder="Select Membership" selectAll={false} isOptionGroup={false} disabled={true} />
                                {formErrors.membership ? <Title level={5} className="error-label" type="danger">{formErrors.membership}</Title> : ''}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="buttons-container">
                    <ButtonComponent title={isEditMode ? "Save Changes" : "Add New User"} className="save-btn" onClick={handleFormSubmit} />
                </Row>
                <Notification 
                open={open} 
                setOpen={setOpen} 
                title={uploadResponse?.status === "SUCCESS" ? "Success" : "Error"} 
                message={uploadResponse?.successMessage ? uploadResponse.successMessage : uploadResponse?.errors ? uploadResponse.errors[0] : "Something went wrong, Please try again."} 
                severity={uploadResponse?.status === "SUCCESS" ? "success" : "error"} 
                />
            </div>
        </>
    );
};

export default UserDetailsForm;