import { Input } from 'antd';
import { FC } from 'react';
import { InputProps } from '../../models/componentTypes/types';

const InputField: FC<InputProps> = ({
    placeholder,
    name,
    type,
    onBlur,
    onKeyUp,
    autoComplete,
    className,
    value,
    minValue,
    maxValue,
    defaultValue,
    onChange,
    disabled
}) => {
    return (
        <Input className={className} defaultValue={defaultValue} min={minValue} max={maxValue} value={value} autoComplete={autoComplete} placeholder={placeholder} name={name} type={type} onBlur={onBlur} onChange={onChange} onKeyUp={onKeyUp} disabled={disabled} />
    );
}

export default InputField;