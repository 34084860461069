import { Row, Col, Typography } from 'antd';
import './footer.css';
import facebook from '../../assets/icons/facebook.png';
import linkedin from '../../assets/icons/linkedin.png';

const { Title } = Typography;

function Footer() {
  return (
    <div>
        <Row className='footer-container'>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} className="footer-col-1">
            <Title level={3} className="footer-title">GetUpp</Title>
            <Title level={4} className="footer-subtitle">GetUpp A/S</Title>
            <Title level={5} className="footer-address-1">Nupark 51</Title>
            <Title level={5} className="footer-address-2">7500 Holstebro</Title>
            <Title level={5} className="footer-address-3">Cvr. nr. 35828702</Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={4} xl={4} className="footer-col-2">
            <Title level={4} style={{marginTop:'2.5em'}}>Contact & Support</Title>
            <Title level={5} className="footer-text">+45 31 16 01 01</Title>
            <Title level={5} className="footer-text">support@getupp.io</Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={4} xl={4} className="footer-col-3">
            <Title level={5} className="footer-text">Privacy Policy</Title>
            <Title level={5} className="footer-text">Terms and Conditions</Title>
            <Title level={5} className="footer-text">Cookie Policy</Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} className="footer-col-4">
            <Title level={4} className="title">Find Us</Title>
            <a href="https://www.google.com"><img src={linkedin} alt="linkedin icon not found" className="social-icons"/></a>
            <a href="https://www.google.com"><img src={facebook} alt="facebook icon not found" className="social-icons"/></a>
          </Col>
        </Row>
    </div>
  )
};

export default Footer;