import { Link } from "react-router-dom";
import common from '../../../common/common';
import { Col, Row, Typography, DatePicker, TimePicker, Button, message, Upload } from 'antd';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import ButtonComponent from '../../../components/button/button';
import RadioGroup from '../../../components/radioGroup/radioGroup';
import InputField from '../../../components/inputField/inputField';
import SelectComponent from '../../../components/select/select';
import UploadCard from '../../../components/cards/uploadCard';
import { useNavigate } from "react-router-dom";
import { getAllUsers, getAllCompanies } from '../../../features/common/common';
import * as yup from 'yup';
import { sendNotification } from '../../../features/notificationConsole/notificationConsole';
import Notification from '../../../components/notification/notification';
import './archivedChallenges.css';
import { UploadOutlined } from '@ant-design/icons';
import TextAreaComponent from "../../../components/textArea/textArea";
import TableComponent from "../../../components/table/table";
import moment from "moment";
import { challengeColumnTypes } from "../../../models/componentTypes/types";
import { ColumnsType } from "antd/lib/table";
import EditIcon from '../../../assets/icons/edit.svg';
import { getAllArchivedChallenges, getArchivedChallengeData } from "../../../features/challenges/challenges";
import LoadingSpinner from "../../../components/loadingSpinner/loadingSpinner";

const { Title } = Typography;

function ArchivedChallenges() {
  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<string[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>([]);
  const [difficultyList, setDifficultyList] = useState<string[]>([]);
  const [designList, setDesignList] = useState<string[]>([]);
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [designType, setDesignType] = useState(0);
  const archivedChallenges: challengeColumnTypes[] = useAppSelector((state) => state.challenges.archivedChallenges);
  const { token } = useAppSelector((state) => state.message);
  const { isLoading } = useAppSelector((state) => state.challenges);

  useEffect(() => {
    setOptions(["Test Option 1", "Test Option 2"]);
    setCategoryList(["Standing", "Combi", "Walking", "Multi"]);
    setDifficultyList(["Beginner", "Medium", "Experienced"]);
    setDesignList(["Map", "Template"]);
  }, []);

  useEffect(() => {
    dispatch(getAllArchivedChallenges());
  }, [token]);

  let handleRedirect = () => {
    navigate("/challenges");
  };

  let handleCreateChallenge = () => {
    navigate("/create-challenge");
  };

  let handleContinueChallenge = (record: any) => {
    dispatch(getArchivedChallengeData(record.archiveChallengeId)).then(response => {
      navigate('/create-challenge', { state: {archivedData: {...response.payload, currentChallengeCount: 1}} });
    })
  };

  const columns: ColumnsType<challengeColumnTypes> = [
    {
      title: 'Challenge Title',
      dataIndex: 'challengeName',
      key: 'challengeName'
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Design',
      dataIndex: 'challengeDesign',
      key: 'challengeDesign'
    },
    {
      title: 'Difficulty',
      dataIndex: 'difficulty',
      key: 'difficulty'
    },
    {
      title: '',
      key: 'edit',
      dataIndex: 'edit',
      render: (text, record, index) => {
        return (
          <img src={EditIcon} alt="Edit Icon" onClick={() => handleContinueChallenge(record)} className="edit-icon" />
        )
      },
    },
  ];

  return (
    <>
      {
        isLoading && <LoadingSpinner />
      }
      <div className="archived-challenges-container">
        <Row className="header">
          <Title level={2} className="title">Archived Challenges</Title>
          <ButtonComponent title="Back to All Challenges" className="challenge-btn" onClick={handleRedirect} />
        </Row>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} style={{textAlign:'right'}}>
            <TableComponent Data={archivedChallenges} columns={columns} className="challenges-table"/>
          </Col>
        </Row>
      </div>
    </>
  )
};

export default ArchivedChallenges;