import { useState, useContext, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  Col,
  Row,
  Typography,
  DatePicker,
  TimePicker,
  TabsProps,
  Tabs,
} from "antd";
import InputField from "../../../../components/inputField/inputField";
import ButtonComponent from "../../../../components/button/button";
import SelectComponent from "../../../../components/select/select";
import UploadCard from "../../../../components/cards/uploadCard";
import TextAreaComponent from "../../../../components/textArea/textArea";
import type { UploadFile } from "antd/es/upload/interface";
import type { RcFile } from "antd/es/upload";
import "./challengeDetails.css";
import { challengeContext } from "../../../../App";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  createNewArchiveChallenge,
  updateArchiveChallenge,
  updateChallenge,
} from "../../../../features/challenges/challenges";
import Notification from "../../../../components/notification/notification";
import ChallengeHeader from "../challengeHeader/challengeHeader";
import { inflate } from "zlib";
import { debug } from "console";

const { Title } = Typography;

function ChallengeDetails({ moveToNextScreen }: any) {
  const categoryList = [
    "Combi",
    "Biking",
    "Walking",
    "Stepping",
    "Standing",
    "Multi",
  ];
  const difficultyList = ["Beginner", "Medium", "Expert"];
  const designList = ["Map"];
  const distanceTypeList = ["KM", "Miles"];
  const languages = ["en", "da", ];
  const { challengeData, setChallengeData } = useContext(challengeContext);

  const [fileList, setFileList] = useState<any>([]);
  const [isImageUploaded, setImageUploaded] = useState(false);
  const [formData, setFormData] = useState({
    challengeTitle: "",
    challengeDescription: "",
    challengeBase64Image: "",
    category: "",
    difficulty: "",
    design: "",
    finishLineHeader: "",
    finishLineDescription: "",
    challengeByLangList: languages.map((lang: string) => {
      return {
        challengeTitle: "",
        challengeDescription: "",
        finishLineHeader: "",
        finishLineDescription: "",
        language: lang,
      };
    }),
  });
  const [formErrors, setFormErrors] = useState({
    challengeTitle: "",
    challengeDescription: "",
    challengeBase64Image: "",
    category: "",
    difficulty: "",
    design: "",
    finishLineHeader: "",
    finishLineDescription: "",
    challengeByLangList: [] as any,
  });
  const [uploadResponse, setUploadResponse] = useState({
    status: "",
    successMessage: "",
    errors: [],
  });
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [defaultTabOpen, setDefaultTabOpen] = useState("en");

  useEffect(() => {
    if (challengeData && Object.keys(challengeData).length > 0) {
      if (challengeData.isSeriesChallenge) {
        let fd = challengeData.challengeItemList[
          challengeData.currentChallengeCount - 1
        ] as any;

        if (fd === undefined || fd === null) {
          fd = {
            challengeTitle: "",
            challengeDescription: "",
            challengeBase64Image: "",
            category: "",
            difficulty: "",
            design: "",
            finishLineHeader: "",
            finishLineDescription: "",
            challengeByLangList: languages.map((lang: string) => {
              return {
                challengeTitle: "",
                challengeDescription: "",
                finishLineHeader: "",
                finishLineDescription: "",
                language: lang,
              };
            }),
          };
        }

        setFormData(fd);

        if (fd && fd.challengeBase64Image) {
          fetch(fd.challengeBase64Image, { mode: "no-cors" })
            .then((res) => res.blob())
            .then((blob) => {
              const type = fd.challengeBase64Image.split(";")[0].split("/")[1];
              const file = new File([blob], "File name", { type: type });
              setFileList([file]);
              setImageUploaded(true);
            });
        } else {
          setFileList([]);
          setImageUploaded(false);
        }
      } else {
        if (challengeData.challengeItemDto) {
          var newFormData = {
            ...challengeData.challengeItemDto,
          } as any;

          if (!newFormData.challengeByLangList) {
            newFormData.challengeByLangList = languages.map((lang: string) => {
              return {
                challengeTitle: "",
                challengeDescription: "",
                finishLineHeader: "",
                finishLineDescription: "",
                language: lang,
              };
            });
          }

          setFormData(newFormData as any);

          if ((challengeData.challengeItemDto as any).challengeBase64Image) {
            fetch(
              (challengeData.challengeItemDto as any).challengeBase64Image,
              { mode: "no-cors" }
            )
              .then((res) => res.blob())
              .then((blob) => {
                const type = (
                  challengeData.challengeItemDto as any
                ).challengeBase64Image
                  .split(";")[0]
                  .split("/")[1];
                const file = new File([blob], "File name", { type: type });
                setFileList([file]);
                setImageUploaded(true);
              });
          }
        }
      }
    }
  }, [challengeData]);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleUploadChange = async (filesData: any) => {
    setFileList(filesData);
    
    let base64URL = await getBase64(filesData as RcFile);
    setFormData({ ...formData, challengeBase64Image: base64URL });
    validateField("challengeBase64Image", base64URL);
    setImageUploaded(true);
  };

  const onFileRemove = () => {
    setFileList([]);
    setImageUploaded(false);
    setFormData({ ...formData, challengeBase64Image: "" });
    validateField("challengeBase64Image", "");
  };

  const handleInputChange = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value });
    validateField(name, value);
  };

  const handleInputChangeByLang = (name: string, value: any, lang: string) => {
    const challengeByLangList = [...formData.challengeByLangList];
    let currentLangIndex = challengeByLangList.findIndex(
      (item: any) => item.language === lang
    );
    challengeByLangList[currentLangIndex][name] = value;
    setFormData({ ...formData, challengeByLangList: challengeByLangList });
  };

  const moveForward = () => {
    
    if (formData.category === "Multi") moveToNextScreen("multiDetails");
    else moveToNextScreen("milestoneDetails");
  };

  const handleNextPage = () => {
    
    schema
      .validate(formData, { abortEarly: false })
      .then((res) => {
        setFormErrors({
          challengeTitle: "",
          challengeDescription: "",
          challengeBase64Image: "",
          category: "",
          difficulty: "",
          design: "",
          finishLineHeader: "",
          finishLineDescription: "",
          challengeByLangList: languages.map((lang: string) => {
            return {
              challengeTitle: "",
              challengeDescription: "",
              finishLineHeader: "",
              finishLineDescription: "",
              language: lang,
            };
          }),
        });

        if (challengeData.isSeriesChallenge) {
          let currentChallengeIndex = challengeData.currentChallengeCount - 1;
          let currentChallengeList: any = challengeData.challengeItemList;
          currentChallengeList[currentChallengeIndex] = {
            ...currentChallengeList[currentChallengeIndex],
            ...formData,
          };

          if (challengeData.isEditMode) {
            if (
              challengeData.currentChallengeCount <
              challengeData.totalChallengeCount
            ) {
              dispatch(
                updateChallenge({
                  ...challengeData,
                  challengeItemList: currentChallengeList,
                })
              );
              setChallengeData({
                ...challengeData,
                challengeItemList: currentChallengeList,
                currentChallengeCount: challengeData.currentChallengeCount + 1,
              });
            } else {
              dispatch(
                updateChallenge({
                  ...challengeData,
                  challengeItemList: currentChallengeList,
                })
              ).then((res) => {
                setUploadResponse(res.payload);
                setOpen(true);
                moveForward();
                // setTimeout(() => {
                //   setChallengeData({
                //     ...challengeData,
                //     challengeItemList: currentChallengeList,
                //   });
                //   navigate("/challenges");
                // }, 500);
              });
            }
          } else {
            let { ArchiveChallengeId } = challengeData as any;

            if (ArchiveChallengeId) {
              dispatch(
                updateArchiveChallenge({
                  ...challengeData,
                  challengeItemList: currentChallengeList,
                })
              ).then((response) => {
                setChallengeData({
                  ...challengeData,
                  challengeItemList: currentChallengeList,
                });
                setUploadResponse(response.payload);
                setOpen(true);
                moveForward();
              });
            } else {
              dispatch(
                createNewArchiveChallenge({
                  ...challengeData,
                  challengeItemList: currentChallengeList,
                })
              ).then((response) => {
                setChallengeData({
                  ...challengeData,
                  ArchiveChallengeId:
                    response.payload.details.archiveChallengeId,
                  challengeItemList: currentChallengeList,
                });
                setUploadResponse(response.payload);
                setOpen(true);
                moveForward();
              });
            }
          }
        } else {
          if (challengeData.isEditMode) {
            setChallengeData({
              ...challengeData,
              challengeResponse: {
                ...challengeData.challengeItemDto,
                ...formData,
              },
            });

            let finalData: any = {
              ...challengeData,
              challengeResponse: {
                ...challengeData.challengeItemDto,
                ...formData,
              },
            };

            dispatch(updateChallenge(finalData)).then((res) => {
              setUploadResponse(res.payload);
              setOpen(true);
              moveForward();
              // setTimeout(() => {
              //   navigate("/challenges");
              // }, 500);
            });
          } else {
            let { ArchiveChallengeId } = challengeData as any;

            if (ArchiveChallengeId) {
              dispatch(
                updateArchiveChallenge({
                  ...challengeData,
                  challengeItemDto: {
                    ...challengeData.challengeItemDto,
                    ...formData,
                  },
                })
              ).then((response) => {
                setChallengeData({
                  ...challengeData,
                  challengeItemDto: {
                    ...challengeData.challengeItemDto,
                    ...formData,
                  },
                });
                setUploadResponse(response.payload);
                setOpen(true);
                moveForward();
              });
            } else {
              dispatch(
                createNewArchiveChallenge({
                  ...challengeData,
                  challengeItemDto: {
                    ...challengeData.challengeItemDto,
                    ...formData,
                  },
                })
              ).then((response) => {
                setChallengeData({
                  ...challengeData,
                  challengeItemDto: {
                    ...challengeData.challengeItemDto,
                    ...formData,
                  },
                  ArchiveChallengeId:
                    response.payload.details.archiveChallengeId,
                });
                setUploadResponse(response.payload);
                setOpen(true);
                moveForward();
              });
            }
          }
        }
      })
      .catch((error: any) => {
        
        const errors = error.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          };
        }, {});
        setFormErrors({ ...formErrors, ...errors });
      });
  };

  useEffect(() => {
    for (let i = 0; i < languages.length; i++) {
      if (formErrors[`challengeByLangList[${i}].challengeTitle`] || 
          formErrors[`challengeByLangList[${i}].challengeDescription`] ||
          formErrors[`challengeByLangList[${i}].finishLineHeader`] ||
          formErrors[`challengeByLangList[${i}].finishLineDescription`]) {
        setDefaultTabOpen(languages[i]);
        break;
      }
    }

  }, [formErrors]);

  const handleSavePage = () => {
    schema
      .validate(formData, { abortEarly: false })
      .then((res) => {
        setFormErrors({
          challengeTitle: "",
          challengeDescription: "",
          challengeBase64Image: "",
          category: "",
          difficulty: "",
          design: "",
          finishLineHeader: "",
          finishLineDescription: "",
          challengeByLangList: languages.map((lang: string) => {
            return {
              challengeTitle: "",
              challengeDescription: "",
              finishLineHeader: "",
              finishLineDescription: "",
              language: lang,
            };
          }),
        });

        if (challengeData.isSeriesChallenge) {
          let currentChallengeIndex = challengeData.currentChallengeCount - 1;
          let currentChallengeList: any = challengeData.challengeItemList;
          currentChallengeList[currentChallengeIndex] = {
            ...currentChallengeList[currentChallengeIndex],
            ...formData,
          };

          if (challengeData.isEditMode) {
            if (
              challengeData.currentChallengeCount <
              challengeData.totalChallengeCount
            ) {
              dispatch(
                updateChallenge({
                  ...challengeData,
                  challengeItemList: currentChallengeList,
                })
              );
              setChallengeData({
                ...challengeData,
                challengeItemList: currentChallengeList,
                currentChallengeCount: challengeData.currentChallengeCount + 1,
              });
            } else {
              dispatch(
                updateChallenge({
                  ...challengeData,
                  challengeItemList: currentChallengeList,
                })
              ).then((res) => {
                setUploadResponse(res.payload);
                setOpen(true);
                setTimeout(() => {
                  setChallengeData({
                    ...challengeData,
                    challengeItemList: currentChallengeList,
                  });
                  navigate("/challenges");
                }, 500);
              });
            }
          } else {
            let { ArchiveChallengeId } = challengeData as any;
            if (ArchiveChallengeId) {
              dispatch(
                updateArchiveChallenge({
                  ...challengeData,
                  challengeItemList: currentChallengeList,
                })
              ).then((response) => {
                setUploadResponse(response.payload);
                setOpen(true);
                setTimeout(() => {
                  navigate("/archived-challenges");
                }, 500);
              });
            } else {
              dispatch(
                createNewArchiveChallenge({
                  ...challengeData,
                  challengeItemList: currentChallengeList,
                })
              ).then((response) => {
                setUploadResponse(response.payload);
                setOpen(true);
                setTimeout(() => {
                  navigate("/archived-challenges");
                }, 500);
              });
            }
          }
        } else {
          if (challengeData.isEditMode) {
            setChallengeData({
              ...challengeData,
              challengeResponse: {
                ...challengeData.challengeItemDto,
                ...formData,
              },
            });

            let finalData: any = {
              ...challengeData,
              challengeResponse: {
                ...challengeData.challengeItemDto,
                ...formData,
              },
            };

            dispatch(updateChallenge(finalData)).then((res) => {
              setUploadResponse(res.payload);
              setOpen(true);
              setTimeout(() => {
                navigate("/challenges");
              }, 500);
            });
          } else {
            setChallengeData({
              ...challengeData,
              challengeItemDto: {
                ...challengeData.challengeItemDto,
                ...formData,
              },
            });
            let { ArchiveChallengeId } = challengeData as any;

            if (ArchiveChallengeId) {
              dispatch(
                updateArchiveChallenge({
                  ...challengeData,
                  challengeItemDto: {
                    ...challengeData.challengeItemDto,
                    ...formData,
                  },
                })
              ).then((response) => {
                setUploadResponse(response.payload);
                setOpen(true);
                setTimeout(() => {
                  navigate("/archived-challenges");
                }, 500);
              });
            } else {
              dispatch(
                createNewArchiveChallenge({
                  ...challengeData,
                  challengeItemDto: {
                    ...challengeData.challengeItemDto,
                    ...formData,
                  },
                })
              ).then((response) => {
                setUploadResponse(response.payload);
                setOpen(true);
                setTimeout(() => {
                  navigate("/archived-challenges");
                }, 500);
              });
            }
          }
        }
      })
      .catch((error: any) => {
        const errors = error.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          };
        }, {});
        setFormErrors({ ...formErrors, ...errors });
      });
  };

  let schema = yup.object().shape({
    challengeTitle: yup.string(),
    challengeDescription: yup.string(),
    challengeBase64Image: yup.string().required("Challenge image is required"),
    category: yup.string().required("Category is required"),
    difficulty: yup.string().required("Difficulty is required"),
    design: yup.string().required("Design is required"),
    finishLineHeader: yup.string(),
    finishLineDescription: yup.string(),
    challengeByLangList: yup
      .array()
      .of(
        yup.object().shape({
          challengeTitle: yup
            .string()
            .required("Challenge Title is required")
            .max(50, "Challenge title cannot be greater than 50 characters"),
          challengeDescription: yup
            .string()
            .required("Challenge description is required")
            .max(
              300,
              "Challenge description cannot be greater than 300 characters"
            ),
          finishLineHeader: yup
            .string()
            .required("Finish line header is required")
            .max(50, "Finish line header cannot be greater than 50 characters"),
          finishLineDescription: yup
            .string()
            .required("Finish line description is required")
            .max(
              300,
              "Finish line description cannot be greater than 300 characters"
            ),
          language: yup.string().required("Language is required"),
        })
      )
      .min(1, "Atleast one language is required"),
  });

  let validateField = (fieldName: string, value: any) => {
    schema
      .validateAt(fieldName, { [fieldName]: value }, { abortEarly: false })
      .then((res) => setFormErrors({ ...formErrors, [fieldName]: "" }))
      .catch((error: any) => {
        const errors = error.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          };
        }, {});
        setFormErrors({ ...formErrors, ...errors });
      });
  };

  const handleSkipChallenge = () => {
    
    schema
      .validate(formData, { abortEarly: false })
      .then((res) => {
        setFormErrors({
          challengeTitle: "",
          challengeDescription: "",
          challengeBase64Image: "",
          category: "",
          difficulty: "",
          design: "",
          finishLineHeader: "",
          finishLineDescription: "",
          challengeByLangList: [],
        });

        let currentChallengeIndex = challengeData.currentChallengeCount - 1;
        let currentChallengeList: any = challengeData.challengeItemList;
        currentChallengeList[currentChallengeIndex] = {
          ...currentChallengeList[currentChallengeIndex],
          ...formData,
        };

        if (challengeData.isSeriesChallenge && challengeData.isEditMode) {
          if (
            challengeData.currentChallengeCount <
            challengeData.totalChallengeCount
          ) {
            dispatch(
              updateChallenge({
                ...challengeData,
                challengeItemList: currentChallengeList,
              })
            );
            setChallengeData({
              ...challengeData,
              challengeItemList: currentChallengeList,
              currentChallengeCount: challengeData.currentChallengeCount + 1,
            });
          } else {
            dispatch(
              updateChallenge({
                ...challengeData,
                challengeItemList: currentChallengeList,
              })
            ).then((res) => {
              setUploadResponse(res.payload);
              setOpen(true);
              setTimeout(() => {
                setChallengeData({
                  ...challengeData,
                  challengeItemList: currentChallengeList,
                });
                navigate("/challenges");
              }, 500);
            });
          }
        } else if (
          challengeData.isSeriesChallenge &&
          challengeData.isArchiveEditMode
        ) {
          let { ArchiveChallengeId } = challengeData as any;
          if (ArchiveChallengeId) {
            dispatch(
              updateArchiveChallenge({
                ...challengeData,
                challengeItemList: currentChallengeList,
              })
            ).then((response) => {
              setUploadResponse(response.payload);
              setOpen(true);

              if (
                challengeData.currentChallengeCount <
                challengeData.totalChallengeCount
              ) {
                moveToNextScreen("challengeDetails");
                setChallengeData({
                  ...challengeData,
                  challengeItemList: currentChallengeList,
                  currentChallengeCount:
                    challengeData.currentChallengeCount + 1,
                });
              } else {
                setTimeout(() => {
                  navigate("/archived-challenges");
                }, 500);
              }
            });
          }
        }
      })
      .catch((error: any) => {
        const errors = error.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]: error.errors[0],
          };
        }, {});
        setFormErrors({ ...formErrors, ...errors });
      });
  };

  const tabs: TabsProps["items"] = languages.map(
    (lang: string, index: number) => {

      const hasErrorOnLang = formErrors[`challengeByLangList[${index}].challengeTitle`] ||
                            formErrors[`challengeByLangList[${index}].challengeDescription`] ||
                            formErrors[`challengeByLangList[${index}].finishLineHeader`] ||
                            formErrors[`challengeByLangList[${index}].finishLineDescription`];
      return {
        label: <span className={(hasErrorOnLang ? `ant-typography ant-typography-danger error-label`: ``)}>{lang.toUpperCase()}</span>,
        key: lang,
        title: lang.toUpperCase(),
        children: (
          <>
            <Title level={5} className="input-label">
              Challenge Title
            </Title>
            <InputField
              placeholder="Enter Challenge Name"
              name="challengeName"
              type="text"
              value={
                formData?.challengeByLangList[index]?.challengeTitle
                  ? formData.challengeByLangList[index].challengeTitle
                  : null
              }
              autoComplete="false"
              onChange={(e) =>
                handleInputChangeByLang(
                  "challengeTitle",
                  e.target.value,
                  lang
                )
              }
              className={
                formErrors.challengeTitle
                  ? "input-field nospace challenge-name"
                  : "input-field challenge-name"
              }
            />
            {formErrors[`challengeByLangList[${index}].challengeTitle`] ? (
              <Title level={5} className="error-label" type="danger">
                {formErrors[`challengeByLangList[${index}].challengeTitle`]}
              </Title>
            ) : (
              ""
            )}

            <Title level={5} className="input-label">
              Challenge Description
            </Title>
            <InputField
              placeholder="Enter Challenge Description"
              name="challengeDescription"
              type="text"
              value={
                formData?.challengeByLangList[index]?.challengeDescription
                  ? formData.challengeByLangList[index].challengeDescription
                  : null
              }
              autoComplete="false"
              onChange={(e) =>
                handleInputChangeByLang(
                  "challengeDescription",
                  e.target.value,
                  lang
                )
              }
              className={
                formErrors.challengeDescription
                  ? "input-field nospace challenge-name"
                  : "input-field challenge-name"
              }
            />
            {formErrors[
              `challengeByLangList[${index}].challengeDescription`
            ] ? (
              <Title level={5} className="error-label" type="danger">
                {
                  formErrors[
                    `challengeByLangList[${index}].challengeDescription`
                  ]
                }
              </Title>
            ) : (
              ""
            )}

            <Title level={5} className="input-label">
              Finish Line Header
            </Title>
            <InputField
              placeholder="Enter Header"
              name="finishHeader"
              value={
                formData?.challengeByLangList[index]?.finishLineHeader
                  ? formData.challengeByLangList[index].finishLineHeader
                  : null
              }
              onChange={(e) =>
                handleInputChangeByLang(
                  "finishLineHeader",
                  e.target.value,
                  lang
                )
              }
              type="text"
              autoComplete="false"
              className={
                formErrors.finishLineHeader
                  ? "input-field nospace finish-header"
                  : "input-field finish-header"
              }
            />
            {formErrors[`challengeByLangList[${index}].finishLineHeader`] ? (
              <Title level={5} className="error-label" type="danger">
                {formErrors[`challengeByLangList[${index}].finishLineHeader`]}
              </Title>
            ) : (
              ""
            )}

            <Title level={5} className="input-label">
              Finish Line Description
            </Title>
            <InputField
              placeholder="Enter Description"
              name="finishDescription"
              value={
                formData?.challengeByLangList[index]?.finishLineDescription
                  ? formData.challengeByLangList[index].finishLineDescription
                  : null
              }
              onChange={(e) =>
                handleInputChangeByLang(
                  "finishLineDescription",
                  e.target.value,
                  lang
                )
              }
              type="text"
              autoComplete="false"
              className={
                formErrors.finishLineDescription
                  ? "input-field nospace"
                  : "input-field"
              }
            />
            {formErrors[`challengeByLangList[${index}].finishDescription`] ? (
              <Title level={5} className="error-label" type="danger">
                {formErrors[`challengeByLangList[${index}].finishDescription`]}
              </Title>
            ) : (
              ""
            )}
          </>
        ),
      };
    }
  );

  return (
    <div className="challenge-details-container">
      <ChallengeHeader handleSkipChallenge={handleSkipChallenge} />
      <Row>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={10}
          xl={8}
          style={{ marginTop: "1em" }}
        >
          <Tabs items={tabs}  defaultActiveKey={defaultTabOpen} />

          <Row style={{ padding: "0px", margin: "0px" }}>
            <Col xs={24} sm={24} md={6} lg={8} xl={12}>
              <Title level={5} className="input-label">
                Category
              </Title>
              <SelectComponent
                className="category"
                list={categoryList}
                value={formData?.category ? formData.category : null}
                onChange={(e) => handleInputChange("category", categoryList[e])}
                placeholder="Select"
                selectAll={false}
                isOptionGroup={false}
                disabled={challengeData.isEditMode}
              />
              {formErrors.category ? (
                <Title level={5} className="error-label" type="danger">
                  {formErrors.category}
                </Title>
              ) : (
                ""
              )}
            </Col>

            <Col xs={24} sm={24} md={6} lg={8} xl={12}>
              <Title level={5} className="input-label">
                Difficulty
              </Title>
              <SelectComponent
                className="difficulty"
                value={formData?.difficulty ? formData.difficulty : null}
                onChange={(e) =>
                  handleInputChange("difficulty", difficultyList[e])
                }
                list={difficultyList}
                placeholder="Select"
                selectAll={false}
                isOptionGroup={false}
              />
              {formErrors.difficulty ? (
                <Title level={5} className="error-label" type="danger">
                  {formErrors.difficulty}
                </Title>
              ) : (
                ""
              )}
            </Col>
          </Row>

          <Row style={{ margin: "1em 0em 0em 0em" }}>
            <Col xs={24} sm={24} md={6} lg={8} xl={12}>
              <Title level={5} className="input-label">
                Design
              </Title>
              <SelectComponent
                className="design"
                value={formData?.design ? formData.design : null}
                onChange={(e) => handleInputChange("design", designList[e])}
                list={designList}
                placeholder="Select"
                selectAll={false}
                isOptionGroup={false}
              />
              {formErrors.design ? (
                <Title level={5} className="error-label" type="danger">
                  {formErrors.design}
                </Title>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={14}
          xl={10}
          className="upload-card-container"
        >
          <UploadCard
            className="challenge-upload-card"
            errorMessage={
              formErrors.challengeBase64Image
                ? formErrors.challengeBase64Image
                : ""
            }
            onChange={handleUploadChange}
            onClose={onFileRemove}
            fileList={fileList}
            isQRUploaded={isImageUploaded}
            previewImage={formData?.challengeBase64Image}
          />
        </Col>
      </Row>
      <Row className="buttons-container">
        <ButtonComponent
          title="Save & Next"
          className="next-btn"
          onClick={handleNextPage}
        />
        {/* <ButtonComponent title="Save" className="save-btn" onClick={handleSavePage} />     */}
      </Row>
      <Notification
        open={open}
        setOpen={setOpen}
        title={uploadResponse?.status === "SUCCESS" ? "Success" : "Error"}
        message={
          uploadResponse?.successMessage
            ? uploadResponse.successMessage
            : uploadResponse?.errors
            ? uploadResponse.errors[0]
            : "Something went wrong, Please try again."
        }
        severity={uploadResponse?.status === "SUCCESS" ? "success" : "error"}
      />
    </div>
  );
}

export default ChallengeDetails;

interface ChallengeByLang {
  challengeTitle: string;
  challengeDescription: string;
  finishLineHeader: string;
  finishLineDescription: string;
  language: string;
}
