import { setMessage } from '../message/message';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import userService from '../../services/userService';

const initialState: any = {
  activatedQRCount: 0,
  premiumUsersCount: 0,
  viewsCount: 0
}

export const getActivatedQRCount = createAsyncThunk(
  'dashboard/getActivatedQRCodes',
  async (params, thunkAPI) => {
      try {
          const response = await userService.getActivatedQRCount();      
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const getPremiumUsersCount = createAsyncThunk(
  'dashboard/getPremiumUsers',
  async (params: any, thunkAPI) => {
      try {
          const response = await userService.getPremiumUsersCount(params);    
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

export const getViewsCount = createAsyncThunk(
  'dashboard/getViewsCount',
  async (params: any, thunkAPI) => {
      try {
          const response = await userService.getViewsCount(params);    
          return response.data.details;
      } catch (error: any) {
          thunkAPI.dispatch(setMessage(error.response));
      }
  }
)

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivatedQRCount.fulfilled, (state, action) => {
      state.activatedQRCount = action.payload;
    })
    builder.addCase(getActivatedQRCount.rejected, (state, action) => {
      console.log("Error Fetching QR Codes: ",action.payload);
    })
    builder.addCase(getPremiumUsersCount.fulfilled, (state, action) => {
      state.premiumUsersCount = action.payload;
    })
    builder.addCase(getPremiumUsersCount.rejected, (state, action) => {
      console.log("Error Fetching Premium Users: ",action.payload);
    })
    builder.addCase(getViewsCount.fulfilled, (state, action) => {
      state.viewsCount = action.payload;
    })
    builder.addCase(getViewsCount.rejected, (state, action) => {
      console.log("Error Fetching Views Count: ",action.payload);
    })
    
  },
})

export default dashboardSlice.reducer; 
