import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import PrivateRoutes from '../../routes/privateRoutes';

function AdminScreen() {
  return (
    <div>
        <Header />
        <PrivateRoutes/>
        <Footer />
    </div>
  )
};

export default AdminScreen;