import { VIDEOS_URL, DASHBOARD_URL } from "../configs/config";
import authHeader from "./authHeader";
const qs = require('qs');
const axios = require('axios');


const getDictionary = () => {
  return axios.get(DASHBOARD_URL + "dictionary/fetch/all", { headers: authHeader() });
}

const getDictionaryById = (id: string) => {
  return axios.get(DASHBOARD_URL + "dictionary/fetch/" + id, { headers: authHeader() });
}

const getDictionaryByKey = (key: string) => {
  return axios.get(DASHBOARD_URL + "dictionary/fetch/key/" + key, { headers: authHeader() });
}

const saveDictionary = (data: any) => {
  return axios.post(DASHBOARD_URL + "dictionary/", data, { headers: authHeader() });
}

const userService = {
  getDictionary,
  getDictionaryById,
  getDictionaryByKey,
  saveDictionary
};

export default userService;