import { Col, Row, Avatar, Typography, Popover, Divider, DatePicker } from 'antd';
import './card.css';
import filterIcon from '../../assets/icons/filter.png';
import qrcode from '../../assets/icons/qrcode.png';
import users from '../../assets/icons/users.png';
import view from '../../assets/icons/view.png';
import { CardProps } from '../../models/componentTypes/types';
import { useEffect, useState } from 'react';
import { FC } from 'react';
import RadioGroup from '../radioGroup/radioGroup';
import ButtonComponent from '../button/button';
import moment from 'moment';

const { Title } = Typography;

const ReportCard: FC<CardProps> = ({
  icon, 
  title,
  subtitle, 
  count,
  startDate,
  endDate,
  radioValue,
  onStartDateChange,
  onEndDateChange, 
  onRadioChange,
  onApply,
  onReset,
  dateRange,
  filter
}) => {
  const [visible, setVisible] = useState(false);
  const [rangeList, setRangeList] = useState<string[]>([]);  

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  }; 

  useEffect(() => {
    setRangeList(['Today', 'Yesterday', 'Last 7 Days', 'Last 30 Days', 'Last 365 Days'])
  }, []);

  return (
    <div className="report-card">
      <Row className="card-container">
        <Col xs={23} sm={23} md={23} lg={23} xl={23} className="card-column">
          <Row className="card-row">
            <Col xs={24} sm={24} md={8} lg={8} xl={7} className="avatar-col">
              <Avatar size={80} src={icon==='qrcode' ? qrcode : icon==='users' ? users : view} className="card-avatar"/>
            </Col>
            <Col xs={24} sm={24} md={16} lg={16} xl={17}>
              <div style={{display:'flex', justifyContent:'space-between'}}>
                <Title level={3} className="no-spacing card-title">{title}</Title>
                { filter === "true" && 
                  <Popover
                    content={
                      <>
                        <Row gutter={[12,0]}>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Title level={5} className="start-date-label">Start Date</Title>
                            <DatePicker className="start-date-picker" value={startDate ? moment(startDate) : moment()} format={"DD-MM-YYYY"} onChange={onStartDateChange}/>
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Title level={5} className="end-date-label">End Date</Title>
                            <DatePicker className="end-date-picker" value={endDate ? moment(endDate) : moment()} format={"DD-MM-YYYY"} onChange={onEndDateChange} />
                          </Col>
                        </Row>
                        <Divider style={{margin:'10px 0px 10px 0px'}}/>
                        <RadioGroup values={rangeList} selectedValue={radioValue ? radioValue : 0} onChange={onRadioChange} direction="vertical" hasChild={false}/>
                        <div className="filter-buttons">
                          <ButtonComponent title="Apply Now" className="card-apply-filter" onClick={onApply}/>
                          <ButtonComponent title="Reset" className="card-reset-filter" onClick={onReset}/>
                        </div>
                      </>
                    }
                    trigger="click"
                    visible={visible}
                    placement="bottomRight"
                    onVisibleChange={handleVisibleChange}
                  >
                    <img src={filterIcon} alt="filter icon not found" className="card-icon" /> 
                  </Popover>
                }
              </div>
              <Title level={4} className="no-spacing card-subtitle">{subtitle}</Title>
              <div className="premium-count">
                <Title level={2} className="no-spacing card-count">{count}</Title>
                { dateRange === "true" && <Title level={5} className="no-spacing card-date-range">({moment(startDate).format('DD/MM/YYYY') + ' - ' + moment(endDate).format('DD/MM/YYYY')})</Title> }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
};

export default ReportCard;